import '../facilitate/AssessmentDetails.scss';

import { AuthContextInterface, FlashContextInterface, FlashType, withAuthContext, withFlashContext } from '@amzn/awscat-react-components';
import {
    Box,
    Button,
    ColumnLayout,
    Container,
    DatePicker,
    Form,
    FormField,
    Grid,
    Header,
    Input,
    Link,
    SpaceBetween,
    TextContent,
} from '@amzn/awsui-components-react';
import { ValidationError, isEmail, validate } from 'class-validator';
import { DateTime } from 'luxon';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import ManageOptions from './ManageOptions';
import ManageSponsorHelpPanel from './ManageSponsorHelpPanel';
import { ManageSponsorUserInput } from './ManageSponsorUserInput';
import { AssessmentQuestionnaireEmailTemplate, getOwnersList, getParticipantEmailTemplate, getQuestionnaireFormatedQuestions } from './util';
import DataCollectionClient, {
    EmailTemplate,
    ExistingQuestionnaire,
    NewQuestionnaire,
    Question,
} from '../../../api/data-collection/DataCollectionClient';
import { AppLabels } from '../../../common/AppLabels';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import { extractAmazonEmailFromUserId } from '../../../common/Utils';
import { errorLookup } from '../../../common/ValidatorUtils';
import rumClient from '../../../common/monitoring/RumClient';
import CopyLineToClipboard from '../../common/CopyLineToClipboard';
import RequestStatusFlashbar, { RequestStatus, defaultRequestStatus } from '../../common/RequestStatusFlashbar';
import { clearAppHelpPanel, openAppHelpPanel, updateAppHelpPanel } from '../../common/help-panel/AppHelpPanelSlice';
import { LocalizationContextInterface } from '../../localization/LocalizationContext';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { AssessmentViewModel } from '../AssessmentViewModel';
import { AssessmentPermissionViewModel } from '../PermissionViewModel';
import AssessmentDetailsHeader from '../facilitate/header/AssessmentDetailsHeader';

interface SponsorState {
    sponsorEmail: string;
    dueDate: string;
}

const createQuestionnaire = async (
    appLabels: AppLabels,
    assessmentId: string,
    assessment: AssessmentViewModel,
    dueDate: string,
    sponsorEmail: string,
    userEmail: string,
    sharedEmails: string[],
    sendAllPromptsToPreEvent: boolean,
    excludeSections: string[]
): Promise<ExistingQuestionnaire> => {
    const assessmentDescription = assessment.description;
    const customerAccount = { accountName: assessment.accountCustomerName };

    const assessmentTemplate = assessment.template;
    if (!assessmentTemplate) {
        throw new Error('No assessment template');
    }
    const questions: Question[] | null = getQuestionnaireFormatedQuestions(appLabels, assessment, sendAllPromptsToPreEvent, excludeSections);
    if (!questions) {
        throw new Error('No pre-event questions');
    }
    const title: string | null = assessment.description;
    if (!title) {
        throw new Error('No assessment title');
    }
    const assigneeEmailTemplate: EmailTemplate | null = getParticipantEmailTemplate(assessmentTemplate);
    if (!assigneeEmailTemplate) {
        throw new Error('No assignee email template');
    }
    const newQuestionnaire: NewQuestionnaire = {
        description: `${customerAccount.accountName} - ${assessmentDescription}`,
        status: 'sent',
        dueDate,
        owners: getOwnersList(sponsorEmail, userEmail, sharedEmails),
        questions,
        sourceResourceId: `awscat:a2s:${assessmentId}`,
        title,
        assigneeEmailTemplate,
    };
    const result = await DataCollectionClient.createQuestionnaire(newQuestionnaire);
    const questionnaireId = result.id;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const assignmentResult = await DataCollectionClient.createAssignment(questionnaireId, sponsorEmail);
    return result;
};

const updateQuestionnaire = async (
    questionnaireId: string,
    dueDate: string,
    sponsorEmail: string,
    userEmail: string,
    sharedEmails: string[]
): Promise<ExistingQuestionnaire> => {
    const updatedQuestionnaire: Partial<ExistingQuestionnaire> = {
        dueDate,
        owners: getOwnersList(sponsorEmail, userEmail, sharedEmails),
    };
    const result = await DataCollectionClient.patchQuestionnaire(questionnaireId, updatedQuestionnaire);
    return result;
};

const getQuestionnaire = async (sourceResourceId: string): Promise<ExistingQuestionnaire | undefined> => {
    const questionnaires = await DataCollectionClient.getQuestionnaires(sourceResourceId);
    const result = questionnaires.length ? questionnaires[0] : undefined;
    return result;
};

type RouteParams = {
    assessmentId: string;
};

type ManageSponsorProps = FlashContextInterface & AppLabelsContextInterface & LocalizationContextInterface & AuthContextInterface;

const ManageSponsor: FunctionComponent<ManageSponsorProps> = ({ flash, appLabels, locale, auth }): JSX.Element => {
    const assessment = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const preEvent = assessment?.template?.defaults?.userQuestionnaires?.preEvent;
    const optionToSendAll = preEvent.questions.optionToSendAll;
    const excludeSections = preEvent.questions.excludeSections;

    const permissions = assessment.assessmentPermissions;
    const sendAllPromptsToPreEvent = assessment.sendAllPromptsToPreEvent;

    const dispatch = useAppDispatch();
    const [state, setState] = useState<SponsorState>({ dueDate: '', sponsorEmail: '' });

    const onChange = useCallback(
        (newSubState: Partial<SponsorState>) => {
            setState({
                ...state,
                ...newSubState,
            });
        },
        [state]
    );
    const { assessmentId } = useParams<RouteParams>();
    const [questionnaire, setQuestionnaire] = useState<ExistingQuestionnaire | undefined>(undefined);
    const [reloadRequestStatus, setReloadRequestStatus] = useState<RequestStatus>(defaultRequestStatus);
    const [updateRequestStatus, setUpdateRequestStatus] = useState<RequestStatus>(defaultRequestStatus);

    const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
    const getValidationErrorText = errorLookup<ManageSponsorUserInput>(validationErrors);
    const getErrorText = (attribute: keyof ManageSponsorUserInput): string | undefined => {
        const validationErrorText = getValidationErrorText(attribute);
        return validationErrorText ? appLabels.intlGet(validationErrorText) : '';
    };

    const participantUrl = DataCollectionClient.getParticipantsUrl(questionnaire?.id);
    const sponsorUrl = DataCollectionClient.getSponsorUrl(questionnaire?.id);
    const preEventSponsorEmail = questionnaire?.owners[0]; // owner[0] is sponsor and owner[1] is facilitator
    const preEventDueDate = questionnaire?.dueDate ? DateTime.fromISO(questionnaire.dueDate).toISODate() : '';

    const sponsorInfoChanged = useCallback((): boolean => {
        if (preEventSponsorEmail) {
            if (preEventSponsorEmail !== state.sponsorEmail || preEventDueDate !== state.dueDate) {
                // sponsor info changed from existing pre-event sponsor info
                return true;
            }
        } else {
            if (state.sponsorEmail && state.dueDate) {
                // New sponsor info entered
                return true;
            }
        }
        return false;
    }, [state, preEventSponsorEmail, preEventDueDate]);

    const sendEmailDisabled = useCallback((): boolean => {
        if (!preEventSponsorEmail || sponsorInfoChanged()) {
            // If no sponsor email established or
            // pre-event sponsor email is being changed not but yet saved to the backend
            return true;
        }
        return false;
    }, [preEventSponsorEmail, sponsorInfoChanged]);

    const sendEmail = useCallback(async (): Promise<any> => {
        const emailTemplate: AssessmentQuestionnaireEmailTemplate | null | undefined = preEvent?.sponsorEmailTemplate;
        const emailTemplateBody = emailTemplate.body.replace('${participantsUrl}', sponsorUrl);
        const sponsorEmail = questionnaire?.owners?.length ? questionnaire?.owners.at(0) : undefined;
        if (!sponsorEmail) {
            flash.alert(FlashType.warning, appLabels.assessment.pre_event.sponsor_not_save, appLabels.assessment.pre_event.cannot_send_email);
        } else if (!(emailTemplate?.body && emailTemplate?.subject)) {
            flash.alert(
                FlashType.warning,
                appLabels.assessment.pre_event.email_template_not_available,
                appLabels.assessment.pre_event.cannot_send_email
            );
        } else {
            const mailto = `mailto:?to=${sponsorEmail}&subject=${emailTemplate.subject}&body=${emailTemplateBody}`;
            window.open(encodeURI(mailto));
        }
    }, [
        preEvent?.sponsorEmailTemplate,
        sponsorUrl,
        questionnaire?.owners,
        flash,
        appLabels.assessment.pre_event.sponsor_not_save,
        appLabels.assessment.pre_event.cannot_send_email,
        appLabels.assessment.pre_event.email_template_not_available,
    ]);

    const updateQuestionnaireSuccessful = useCallback(
        (questionnaire) => {
            if (questionnaire) {
                setQuestionnaire(questionnaire);
                setState({
                    dueDate: questionnaire.dueDate ? DateTime.fromISO(questionnaire.dueDate).toISODate() : '',
                    sponsorEmail: (questionnaire.owners?.length ? questionnaire.owners.at(0) : '') || '',
                });
            }
        },
        [setQuestionnaire, setState]
    );

    const getPermissionsViewModel = useCallback(async () => {
        const userIdsWithPermission: string[] = [];
        permissions.forEach((p) => {
            if (p?.userId) {
                userIdsWithPermission.push(p.userId);
            }
        });
        const permissionViewModel: AssessmentPermissionViewModel[] = [];
        permissions.forEach((p) => {
            if (p) {
                // User invitee email if permission is still pending (e.g. user have not accepted invitation)
                // Otherwise, extract from profile amazon userId
                // Note that due to https://t.corp.amazon.com/V1514102370, the email is no longer returned in the userProfileList,
                // hence partners will not be able to send questionnaires to participants via email for now.
                // TODO: update UI so that the requestor will manually enter each participant email.
                const userEmail = p.isPending ? p.inviteeEmail : extractAmazonEmailFromUserId(p.userId) ?? p.userId;
                permissionViewModel.push({
                    userId: p.userId ?? '',
                    userEmail,
                    action: p.action,
                    invitationId: p.invitationId,
                });
            }
        });
        return permissionViewModel;
    }, [permissions]);

    const save = useCallback(async (): Promise<void> => {
        const manageSponsorInput: ManageSponsorUserInput = Object.assign(new ManageSponsorUserInput(), state);
        const errors = await validate(manageSponsorInput);
        setValidationErrors(errors);
        if (errors.length > 0) {
            return;
        }
        const userEmail: string = auth.getUserInfo().email;
        const permissionsViewModel = await getPermissionsViewModel();
        const sharedEmails = permissionsViewModel.map((p) => p.userEmail).filter((email) => isEmail(email));
        const notEmails = permissionsViewModel.map((p) => p.userEmail).filter((email) => !isEmail(email));
        const dueDate = DateTime.fromISO(state.dueDate).toISO(); // Convert to date to ISO time with TZ e.g. 2022-05-30 => 2022-05-30T00:00:00.000-04:00
        try {
            setUpdateRequestStatus({ ...defaultRequestStatus, loading: true });
            if (!questionnaire) {
                // If the questionnaire does not exist, then create
                const newQuestionnaire = await createQuestionnaire(
                    appLabels,
                    assessmentId,
                    assessment,
                    dueDate,
                    state.sponsorEmail,
                    userEmail,
                    sharedEmails,
                    sendAllPromptsToPreEvent,
                    excludeSections
                );
                setUpdateRequestStatus({ ...defaultRequestStatus, loading: false });
                updateQuestionnaireSuccessful(newQuestionnaire);
            } else {
                // if it does, then update it
                if (!questionnaire.id) {
                    flash.alert(FlashType.error, appLabels.assessment.pre_event.cannot_update_unsaved, appLabels.assessment.pre_event.error_saving);
                    return;
                }
                const updatedQuestionnaire = await updateQuestionnaire(questionnaire.id, dueDate, state.sponsorEmail, userEmail, sharedEmails);
                setUpdateRequestStatus({ ...defaultRequestStatus, loading: false });
                updateQuestionnaireSuccessful(updatedQuestionnaire);
            }
            // Check if we tried to share the questionnaire with someone, but the email was invalid. Show a warning if so
            if (notEmails.length > 0)
                setUpdateRequestStatus({
                    loading: false,
                    messageType: FlashType.warning,
                    messageHeader: appLabels.assessment.pre_event.couldnt_share,
                    messageContent: notEmails.toString(),
                });
            return;
        } catch (err: any) {
            rumClient.recordError(err);
            setUpdateRequestStatus({
                loading: false,
                messageType: FlashType.error,
                messageHeader: appLabels.assessment.pre_event.error_saving,
                messageContent: err.message,
            });
        }
    }, [
        state,
        auth,
        getPermissionsViewModel,
        questionnaire,
        appLabels,
        assessmentId,
        assessment,
        sendAllPromptsToPreEvent,
        excludeSections,
        updateQuestionnaireSuccessful,
        flash,
    ]);

    const loadQuestionnaire = useCallback(async (): Promise<any> => {
        try {
            setReloadRequestStatus({ ...defaultRequestStatus, loading: true });
            const sourceResourceId = `awscat:a2s:${assessmentId}`;
            const existingQuestionnaire = await getQuestionnaire(sourceResourceId);
            setReloadRequestStatus({ ...defaultRequestStatus, loading: false });
            updateQuestionnaireSuccessful(existingQuestionnaire);
        } catch (err: any) {
            rumClient.recordError(err);
            setReloadRequestStatus({
                loading: false,
                messageType: FlashType.error,
                messageHeader: appLabels.assessment.pre_event.error_loading,
                messageContent: err.message,
            });
        }
        // Disable exhaustive-deps only to prevent adding flash, so that error flashes do not infinitely render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assessmentId]);

    const resourcesContainer = useCallback((): JSX.Element | null => {
        if (!questionnaire) {
            return null;
        }
        return (
            <Container header={<Header variant='h2'>{appLabels.header.resources.title}</Header>}>
                <TextContent>{appLabels.assessment.pre_event.questionnaire_sponsor_view}</TextContent>
                <CopyLineToClipboard title={sponsorUrl} content={sponsorUrl} />
                <TextContent>{appLabels.assessment.pre_event.questionnaire_participant_view}</TextContent>
                <CopyLineToClipboard title={participantUrl} content={participantUrl} />
            </Container>
        );
    }, [
        questionnaire,
        sponsorUrl,
        participantUrl,
        appLabels.header.resources.title,
        appLabels.assessment.pre_event.questionnaire_sponsor_view,
        appLabels.assessment.pre_event.questionnaire_participant_view,
    ]);

    useEffect(() => {
        const cleanup = () => {
            // Component unmounted, restore help panel to default
            dispatch(clearAppHelpPanel());
        };

        loadQuestionnaire();

        dispatch(
            updateAppHelpPanel({
                header: appLabels.assessment.pre_event.manage_sponsor,
                content: <ManageSponsorHelpPanel />,
                footer: '',
            })
        );
        dispatch(openAppHelpPanel());

        return cleanup;
    }, [appLabels.assessment.pre_event.manage_sponsor, dispatch, loadQuestionnaire]);

    return (
        <Box padding='l' className='awscat-assessment-manage-sponsor awscat-assessment-wrapper'>
            <AssessmentDetailsHeader showLiveSession={false} readOnly={true} showSnapshots={false} />
            <RequestStatusFlashbar requestStatus={reloadRequestStatus} setRequestStatus={setReloadRequestStatus} />
            <RequestStatusFlashbar requestStatus={updateRequestStatus} setRequestStatus={setUpdateRequestStatus} />
            <Box variant='h2' margin={{ top: 'm', bottom: 's' }}>
                {appLabels.assessment.pre_event.manage_sponsor}
                <Box display='inline-block' margin={{ left: 'xs' }}>
                    <Link variant='info' id='manage-sponsor-info-link' onFollow={() => dispatch(openAppHelpPanel())}>
                        {appLabels.common.info}
                    </Link>
                </Box>
            </Box>
            <Grid gridDefinition={[{ colspan: { default: 12, xs: 6 } }, { colspan: { default: 12, xs: 6 } }]}>
                <div>
                    <SpaceBetween size={'m'} direction='vertical'>
                        <Container header={<Header variant='h2'>{appLabels.assessment.pre_event.sponsor}</Header>}>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <Form>
                                    <SpaceBetween size='m'>
                                        <FormField label={`${appLabels.common.email}*`} errorText={getErrorText('sponsorEmail')}>
                                            <Input
                                                value={state.sponsorEmail}
                                                onChange={(event) => {
                                                    onChange({ sponsorEmail: event.detail.value.trim() });
                                                }}
                                                placeholder={appLabels.assessment.pre_event.email_placeholder}
                                            />
                                        </FormField>
                                        <FormField
                                            label={`${appLabels.assessment.pre_event.due_date}*`}
                                            description={appLabels.assessment.pre_event.due_date_description}
                                            errorText={getErrorText('dueDate')}
                                        >
                                            <DatePicker
                                                value={state.dueDate || ''}
                                                onChange={(event) => {
                                                    onChange({ dueDate: event.detail.value });
                                                }}
                                                openCalendarAriaLabel={(selectedDate) =>
                                                    appLabels.common.choose_date +
                                                    (selectedDate ? `, ${appLabels.common.selected_date} ${selectedDate}` : '')
                                                }
                                                nextMonthAriaLabel={appLabels.common.next_month}
                                                placeholder={appLabels.common.date_placeholder}
                                                previousMonthAriaLabel={appLabels.assessment.pre_event.previous_month}
                                                todayAriaLabel={appLabels.assessment.pre_event.today}
                                                locale={locale}
                                            />
                                        </FormField>
                                    </SpaceBetween>
                                </Form>
                            </form>
                        </Container>
                        {optionToSendAll ? <ManageOptions disabled={!!questionnaire} /> : null}
                        <Box margin={{ top: 'm' }}>
                            <SpaceBetween size='xs' direction='horizontal'>
                                <Button
                                    variant={sponsorInfoChanged() ? 'primary' : 'normal'}
                                    disabled={!sponsorInfoChanged()}
                                    loading={updateRequestStatus.loading}
                                    onClick={save}
                                >
                                    {appLabels.user_actions.save}
                                </Button>
                                <Button variant={sendEmailDisabled() ? 'normal' : 'primary'} disabled={sendEmailDisabled()} onClick={sendEmail}>
                                    {appLabels.user_actions.send}
                                </Button>
                            </SpaceBetween>
                        </Box>
                    </SpaceBetween>
                </div>
                <div>
                    <SpaceBetween size='m' direction='vertical'>
                        <Container
                            header={
                                <Header
                                    variant='h2'
                                    actions={
                                        <SpaceBetween direction='horizontal' size='xs'>
                                            <Button
                                                variant='icon'
                                                iconName='refresh'
                                                loading={reloadRequestStatus.loading}
                                                onClick={loadQuestionnaire}
                                            ></Button>
                                        </SpaceBetween>
                                    }
                                >
                                    {appLabels.assessment.pre_event.status}
                                </Header>
                            }
                        >
                            {questionnaire ? (
                                <ColumnLayout borders='vertical' columns={2}>
                                    <div>
                                        {appLabels.assessment.pre_event.participants}
                                        <Box color='text-status-info' fontSize='display-l' fontWeight='light'>
                                            {questionnaire.summaryProgress?.numUsers}
                                        </Box>
                                    </div>
                                    <div>
                                        {appLabels.assessment.pre_event.submitted_responses}
                                        <Box color='text-status-info' fontSize='display-l' fontWeight='light'>
                                            {questionnaire.summaryProgress?.numSubmitted}
                                        </Box>
                                    </div>
                                </ColumnLayout>
                            ) : (
                                <Box textAlign='center' color='inherit'>
                                    <b>{appLabels.assessment.pre_event.pre_event_not_created}</b>
                                </Box>
                            )}
                        </Container>

                        {resourcesContainer()}
                    </SpaceBetween>
                </div>
            </Grid>
        </Box>
    );
};

export default withAppLabelsContext(withFlashContext(withAuthContext(ManageSponsor)));
