import { AssessmentFeature, AssessmentTemplateDefaultsAnswers } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { AuthContextInterface, FlashType, withAuthContext } from '@amzn/awscat-react-components';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
    Box,
    CollectionPreferences,
    CollectionPreferencesProps,
    Header,
    Pagination,
    Table,
    TableProps,
    Tabs,
    TabsProps,
    TextFilter,
} from '@amzn/awsui-components-react';
import { useLazyQuery } from '@apollo/client';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import a2sApolloClient from '../../api/a2s/ApolloClient';
import { GET_ORG_ASSESSMENT_SUMMARY } from '../../api/a2s/ApolloQueries';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../common/AppLabelsContext';
import Constants from '../../common/Constants';
import rumClient from '../../common/monitoring/RumClient';
import { ScoreScale } from '../../common/score/ScoreScale';
import { ScoredItem, getScoreLabel } from '../../common/score/ScoreUtils';
import EmptyTable from '../common/EmptyTable';
import RequestStatusFlashbar, { RequestStatus, defaultRequestStatus } from '../common/RequestStatusFlashbar';
import { clearAppHelpPanel, updateAppHelpPanel } from '../common/help-panel/AppHelpPanelSlice';
import { useAppSelector } from '../redux/hooks';

const ITEM_SUMMARIES_KEY = 'ItemSummaries';
const CUSTOMER_ACCOUNT_KEY = 'CustomerAccount';
const ASSESSMENT_DESCRIPTION_KEY = 'Description';
const METADATA_COLUMN_WIDTH = '18%';
const SCORE_COLUMN_WIDTH = 'auto';
const MIN_COLUMN_WIDTH = '100px';

// Define the accepted assessment types and keys to get for each assessment type. Exported so it can be checked in tests
export const OVERVIEW_INCLUDE_KEYS: { [key: string]: string[] } = {
    MRA: [
        'Business Case',
        'Customer Migration Project Plan',
        'Skills & COE',
        'Application Portfolio Discovery & Planning',
        'Migration Process & Experience',
        'Operating Model',
        'Security',
        'Landing Zone',
    ],
    MRA2: [
        'Business Case',
        'Customer Migration Project Plan',
        'Skills & COE',
        'Application Portfolio Discovery & Planning',
        'Migration Process & Experience',
        'Operating Model',
        'Security',
        'Landing Zone',
    ],
};

type AdminOverviewProp = AuthContextInterface & AppLabelsContextInterface;
const AdminOverview: FunctionComponent<AdminOverviewProp> = ({ appLabels, auth }): JSX.Element => {
    const scoreScale = useMemo(() => {
        return new ScoreScale({} as AssessmentTemplateDefaultsAnswers, appLabels);
    }, [appLabels]);

    const isAuthenticated = auth.isAuthenticated();
    const dispatch = useDispatch();

    const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
        pageSize: Constants.TABLE_DEFAULT_PAGE_SIZE_CUSTOMERS,
    });

    const authorizedFeatures = useAppSelector((state) => state.assessmentFeaturesState.authorizedFeatures);
    const adminOverviewIsEnabled = authorizedFeatures.includes(AssessmentFeature.ADMIN_OVERVIEW);

    const [doInitialLoad, setDoInitialLoad] = useState<boolean>(true);
    const [requestStatus, setRequestStatus] = useState<RequestStatus>(defaultRequestStatus);
    const [overviewJSON, setOverviewJSON] = useState<string>(undefined);

    const [getPartnerOverview, { loading }] = useLazyQuery(GET_ORG_ASSESSMENT_SUMMARY, {
        client: a2sApolloClient,
        fetchPolicy: 'network-only',
        onError: (error) => {
            // If the error is that "the specified key doesn't exist", that means this partner org doesn't have an overview file
            if (error.message.includes('specified key does not exist')) {
                setRequestStatus({
                    loading: false,
                    messageType: FlashType.warning,
                    messageHeader: appLabels.org_overview.no_overview_found,
                    messageContent: error.message,
                });
            } else {
                rumClient.recordError(error);
                setRequestStatus({
                    loading: false,
                    messageType: FlashType.error,
                    messageHeader: appLabels.org_overview.error_loading_overview,
                    messageContent: error.message,
                });
            }
        },
        onCompleted: (data) => {
            setOverviewJSON(data['getOrgAssessmentSummary']);
            setRequestStatus({
                ...requestStatus,
                loading: false,
            });
        },
    });

    const filterAssessments = (item: any, filteringText: string): boolean => {
        return item.Assessment?.includes(filteringText);
    };

    const buildOverviewTabs = useCallback(() => {
        // Define the table within the callback
        type OverviewTableProps = {
            // Assessments needs to be of any type, because different assessment types have different models
            assessments: any[];
            assessmentType: string;
        };

        const ScoredRatingBadge = (scored: ScoredItem | null): JSX.Element => {
            const scoreLabel = getScoreLabel(scored);
            const scoreColor = scoreScale?.getStratificationBackgroundColor(scored);
            return (
                <div className={'badge'} style={{ backgroundColor: scoreColor, textAlign: 'center' }}>
                    {scoreLabel}
                </div>
            );
        };

        const OverviewTable: FunctionComponent<OverviewTableProps> = ({ assessments, assessmentType }) => {
            // Transform the assessment list into a list that can be used in the table
            const {
                items: tableItems,
                collectionProps,
                filterProps,
                paginationProps,
            } = useCollection(
                assessments.map((assessment) => {
                    // Get assessment metadata: customer account name, assessment description,
                    const customerName: string = assessment[CUSTOMER_ACCOUNT_KEY] || '';
                    const assessmentDescription: string = assessment[ASSESSMENT_DESCRIPTION_KEY] || '';

                    // Get the activity scores, in the order specified by the INCLUDE_KEYS array
                    const activityScores = {};
                    for (const key of OVERVIEW_INCLUDE_KEYS[assessmentType]) activityScores[key] = assessment[key] || '';

                    return {
                        Account: customerName,
                        Assessment: assessmentDescription,
                        ...activityScores,
                    };
                }),
                {
                    filtering: {
                        empty: (
                            <EmptyTable
                                title={appLabels.org_overview.no_assessments_created}
                                subtitle={appLabels.org_overview.no_assessments_created_subtitle}
                            />
                        ),
                        noMatch: (
                            <EmptyTable title={appLabels.org_overview.no_assessments_found} subtitle={appLabels.org_overview.try_different_search} />
                        ),
                        filteringFunction: filterAssessments,
                    },
                    pagination: { pageSize: preferences.pageSize },
                }
            );

            const columnDefinitions: TableProps.ColumnDefinition<any>[] = [];
            // Add column definitions using an item
            if (tableItems.length > 0)
                Object.keys(tableItems[0]).forEach((key, index) => {
                    const isScore = OVERVIEW_INCLUDE_KEYS[assessmentType].includes(key);
                    columnDefinitions.push({
                        id: `col${index}`,
                        header: key,
                        cell: (e) => (isScore ? <ScoredRatingBadge score={e[key]} /> : e[key]),
                        width: isScore ? SCORE_COLUMN_WIDTH : METADATA_COLUMN_WIDTH,
                        minWidth: MIN_COLUMN_WIDTH,
                    });
                });

            // Create the table header & preferences
            const tableHeader = <Header counter={`(${tableItems.length})`}>{appLabels.org_overview.assessments}</Header>;

            const tableOptionsSetting = () => [
                { label: `10 ${appLabels.org_overview.assessments.toLowerCase()}`, value: 10 },
                { label: `25 ${appLabels.org_overview.assessments.toLowerCase()}`, value: 25 },
                { label: `50 ${appLabels.org_overview.assessments.toLowerCase()}`, value: 50 },
                { label: `100 ${appLabels.org_overview.assessments.toLowerCase()}`, value: 100 },
            ];

            const tablePreferences = (
                <CollectionPreferences
                    onConfirm={({ detail }) => setPreferences(detail)}
                    preferences={preferences}
                    pageSizePreference={{
                        title: appLabels.org_overview.page_size_preference_title,
                        options: tableOptionsSetting(),
                    }}
                    cancelLabel={appLabels.user_actions.cancel}
                    confirmLabel={appLabels.user_actions.confirm}
                    title={appLabels.customer_selection.preference_title}
                />
            );

            return (
                <Table
                    {...collectionProps}
                    header={tableHeader}
                    columnDefinitions={columnDefinitions}
                    items={tableItems}
                    loadingText={appLabels.org_overview.loading_overview}
                    loading={loading}
                    filter={<TextFilter {...filterProps} countText={''} filteringPlaceholder={appLabels.org_overview.filter_placeholder} />}
                    preferences={tablePreferences}
                    pagination={<Pagination {...paginationProps} />}
                    resizableColumns={true}
                />
            );
        };

        // Check if the query failed. If so, don't make any tabs
        if (overviewJSON === undefined) return [];

        // Parse overview JSON
        const partnerOverviewData = JSON.parse(overviewJSON);

        // One tab per assessment type
        const overviewTabs: TabsProps.Tab[] = [];
        for (const assessmentType of Object.keys(OVERVIEW_INCLUDE_KEYS)) {
            // Might not be any assessments of this type
            if (partnerOverviewData[assessmentType] === undefined) continue;

            // Filter the data for this assessment type. See test_resources/sampleOverview.json for how this is formatted
            const assessmentTypeSummary = partnerOverviewData[assessmentType][ITEM_SUMMARIES_KEY];

            // Overview is formatted incorrectly for this assessment type
            if (assessmentTypeSummary === undefined) {
                rumClient.recordError(`Overview for assessment type ${assessmentType} was not formatted correctly`);
                continue;
            }

            // Add a tab with the assessment type name and table
            overviewTabs.push({
                label: assessmentType,
                id: assessmentType,
                content: <OverviewTable assessments={assessmentTypeSummary} assessmentType={assessmentType} />,
            });
        }

        return overviewTabs;
    }, [overviewJSON, preferences, loading, appLabels, scoreScale]);

    useEffect(() => {
        const cleanup = () => {
            // Component unmounted, restore help panel to default
            dispatch(clearAppHelpPanel());
        };
        dispatch(
            updateAppHelpPanel({
                header: appLabels.org_overview.help_panel_header,
                content: appLabels.org_overview.help_panel_content,
            })
        );

        // Make query for the overview
        if (doInitialLoad) {
            if (isAuthenticated) getPartnerOverview();
            else rumClient.recordError('Not authenticated');
            setDoInitialLoad(false);
        }

        return cleanup;
    }, [
        dispatch,
        appLabels.org_overview.help_panel_header,
        appLabels.org_overview.help_panel_content,
        doInitialLoad,
        setDoInitialLoad,
        getPartnerOverview,
        isAuthenticated,
    ]);

    if (!adminOverviewIsEnabled) {
        return null;
    }
    return (
        <div className='awscat-applayout-content awscat-assessment-wrapper'>
            <RequestStatusFlashbar requestStatus={requestStatus} setRequestStatus={setRequestStatus} />
            <Box variant='h2' margin={{ bottom: 's' }}>
                {appLabels.org_overview.overview}
            </Box>
            <Tabs tabs={buildOverviewTabs()} />
        </div>
    );
};

export default withAuthContext(withAppLabelsContext(AdminOverview));
