import { AssessmentResourceAction } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { Alert, Badge, Box, Button, Table, TableProps } from '@amzn/awsui-components-react';
import { useCallback, useState } from 'react';

import { AppLabels } from '../../../common/AppLabels';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import { AssessmentPermissionViewModel } from '../PermissionViewModel';

export enum Change {
    Add,
    Update,
    Delete,
    Invite,
    Invited,
    None,
}

export interface ShareViewModel extends AssessmentPermissionViewModel {
    change: Change;
}

interface ShareTableProps {
    permissions: Array<ShareViewModel>;
    setPermissions: React.Dispatch<React.SetStateAction<ShareViewModel[]>>;
}

const ShareTable = (props: ShareTableProps & AppLabelsContextInterface): JSX.Element | null => {
    const appLabels: AppLabels = props.appLabels;

    const [errorAlertVisible, setErrorAlertVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const mapChange = useCallback(
        (change: Change) => {
            switch (change) {
                case Change.Add:
                    return <Badge color='blue'>{appLabels.assessment.share.status_pending_add}</Badge>;
                case Change.Update:
                    return <Badge color='blue'>{appLabels.assessment.share.status_pending_update}</Badge>;
                case Change.Invite:
                    return <Badge color='blue'>{appLabels.assessment.share.status_pending_invite}</Badge>;
                case Change.Invited:
                    return <Badge color='blue'>{appLabels.assessment.share.status_invited}</Badge>;
                case Change.None:
                    return <Badge color='green'>{appLabels.assessment.share.status_saved}</Badge>;
            }
            return null;
        },
        [
            appLabels.assessment.share.status_invited,
            appLabels.assessment.share.status_pending_add,
            appLabels.assessment.share.status_pending_invite,
            appLabels.assessment.share.status_pending_update,
            appLabels.assessment.share.status_saved,
        ]
    );

    const removePermission = useCallback(
        (e: ShareViewModel) => {
            const newPermissions = props.permissions.map((p) => {
                // remove this row by looking it up and setting to Delete
                // Mark for delete only in both userId and permission matches
                return p.userId === e.userId && p.action === e.action ? { ...p, change: Change.Delete } : p;
            });
            const numberOfRemainingFullPermissions = newPermissions.filter(
                (p) => p.change !== Change.Delete && p.action === AssessmentResourceAction.FULL
            ).length;

            if (numberOfRemainingFullPermissions > 0) {
                props.setPermissions(newPermissions);
            } else {
                // cannot remove last FULL permission
                setErrorMessage(appLabels.assessment.share.error_remove_last_permission);
                setErrorAlertVisible(true);
            }
        },
        [appLabels.assessment.share.error_remove_last_permission, props]
    );

    const columnDefs: TableProps.ColumnDefinition<ShareViewModel>[] = [
        {
            id: 'status',
            header: appLabels.assessment.share.status,
            cell: (e) => mapChange(e.change),
        },
        {
            id: 'email',
            header: appLabels.common.emailOrUserName,
            cell: (e) => e.userEmail,
        },
        {
            id: 'permission',
            header: appLabels.assessment.share.permission,
            cell: (e) => e.action,
        },
        {
            id: 'actions',
            header: appLabels.assessment.share.remove,
            cell: (e) => <Button iconName='close' variant='icon' onClick={() => removePermission(e)} />,
        },
    ];

    return (
        <div>
            <Alert
                onDismiss={() => setErrorAlertVisible(false)}
                visible={errorAlertVisible}
                dismissAriaLabel={appLabels.user_actions.close_alert}
                dismissible
                type='error'
                header={appLabels.assessment.share.error_remove_permissions}
            >
                {errorMessage}
            </Alert>
            <Table
                trackBy='userId'
                columnDefinitions={columnDefs}
                items={props.permissions.filter((p) => p.change !== Change.Delete)}
                loadingText={appLabels.assessment.share.loading_permissions}
                loading={!props.permissions?.length}
                empty={
                    <Box textAlign='center' color='inherit'>
                        <b>{appLabels.assessment.share.table_no_permissions}</b>
                        <Box padding={{ bottom: 's' }} variant='p' color='inherit'>
                            {appLabels.assessment.share.table_no_permissions_to_display}
                        </Box>
                    </Box>
                }
            />
        </div>
    );
};

export default withAppLabelsContext(ShareTable);
