/* eslint-disable camelcase */
export default {
    // Common section
    common_actions: 'アクション',
    common_description: '記述',
    common_organization_name: '組織',
    common_info: '情報',
    common_unknown: '不明',
    common_total: '合計',
    common_percentage: 'パーセント',
    common_count: 'カウント',
    common_email: 'Eメール',
    common_email_or_username: 'メールまたはユーザー名',
    common_legend: '凡例',
    common_date_format_constraint_text: 'YYYY/MM/DD 形式を使用',
    common_selected_date: '選択した日付は',
    common_choose_date: '日付を選択',
    common_next_month: '来月',
    common_date_placeholder: 'YYYY/MM/DD',
    common_preferences_title: '環境設定',
    common_page_size_preference_title: 'ページあたりの項目数',
    common_max_char_200: '最大 200 文字まで入力できます',
    common_max_char_2000: '最大 2000 文字まで入力できます',
    common_options: 'オプション',
    common_yes: 'はい',
    common_no: 'いいえ',
    common_i_dont_know: '不明',
    common_optional: 'オプション',
    common_error: 'エラー',
    common_step: 'ステップ',
    common_and: 'と',
    common_length_must_be_between: '長さは次の範囲でなくてはなりません。',
    common_short: '短',
    common_medium: '中',
    common_long: '長',
    common_high: '高',
    common_low: '低',
    common_na: 'N/A',
    common_partners: 'パートナー',

    // User actions section
    user_actions_action: 'アクション',
    user_actions_create: '作成',
    user_actions_cancel: 'キャンセル',
    user_actions_apply: '申し込み',
    user_actions_confirm: '確認',
    user_actions_close: '閉じる',
    user_actions_close_dialog: 'ダイアログを閉じる',
    user_actions_close_alert: 'アラートを閉じる',
    user_actions_send: '送信',
    user_actions_save: '保存する',
    user_actions_selected: '選択済み',
    user_actions_search: '検索',
    user_actions_dismiss: '却下',
    user_actions_edit: '編集',
    user_actions_edit_settings: '編集設定',
    user_actions_share: '共有',
    user_actions_delete: '削除',
    user_actions_drag: 'ドラッグ',
    user_actions_retry_update: '再更新',
    user_actions_previous: '前',
    user_actions_back: '戻る',
    user_actions_next: '次',
    user_actions_submit: '評価を作成する',
    user_actions_preview: 'プレビュー',
    user_actions_publish: '発行',
    user_actions_reset: 'または',
    user_actions_add: '追加',

    statuses_saved: '保存済み',
    statuses_saving: '保存中...',
    statuses_failed_to_save_refresh: '保存できませんでした。ページを更新してください。このエラーが再発する場合は、バグを報告してください。',

    // Features section
    features_error_loading_features: '評価機能の読み込みエラー',

    // Header section
    header_feedback_title: 'フィードバック',
    header_resources_title: 'リソース',

    landing_title: 'AWSアセスメントツール',
    landing_serviceName: 'AWSアセスメントツール',
    landing_majorDescription: 'クラウドジャーニーを確認、評価、加速',
    landing_minorDescription:
        'AWSアセスメントツールは、クラウド導入のさまざまな要素やフェーズに関連するさまざまなアセスメントを作成、' +
        '管理、および実施するためのプロフェッショナルなオンラインプラットフォームです。',
    landing_whatItDoes_title: '評価ツールは初めてですか？',
    landing_whatItDoes_description:
        'AWSアセスメントツールは、AWSによって開発されたオンラインプラットフォームであり、' +
        '社内および顧客向けのエンゲージメントの両方について、多数の調査、アンケート、およびアセスメントを提供します。 このプラッ' +
        'トフォームは、必要に応じて、情報を収集し、分析を完了し、レポートを生成するための安全で安全な手段を提供します。',
    landing_gettingStarted_title: '入門',
    landing_gettingStarted_links_1_linkText: '評価を作成する',
    landing_gettingStarted_links_1_linkLocation: '/#',
    landing_gettingStarted_pulldown_header: '最近の評価',
    landing_gettingStarted_pulldown_footer: 'すべての評価を表示',
    landing_benefitsAndFeatures_title: '利点と機能',
    landing_benefitsAndFeatures_1_title: '情報を収集するための体系的なアプローチを調整する',
    landing_benefitsAndFeatures_1_description:
        '定義されたフレームワークに基づいて機能に関する情報を収集し、長期にわたる分析と比較のために永続的で安全なデータプラットフォーム内に結果を保持します',
    landing_benefitsAndFeatures_2_title: '分析を可能にし、ロードマップの形成を形作るための評価結果の提示 ',
    landing_benefitsAndFeatures_2_description:
        '自動的に計算された結果を、ヒートマップ、レーダーチャート、準備スコアの生成など、クラウドジャーニーの次のフェーズに関連するコンポーネントに合わせて、アクションの計画を立てるのに役立てます',
    landing_benefitsAndFeatures_3_title: '結果の伝達とプロジェクト設定のための自動生成されたレポート',
    landing_benefitsAndFeatures_3_description:
        '関連する利害関係者への結果の配布を容易にし、次のステップの加速を促進するためのワンクリックレポート生成',
    landing_moreResources_title: 'その他のリソース',
    landing_moreResources_1_linkLocation: '/#',
    landing_moreResources_1_linkText: 'ユーザーガイド',
    landing_moreResources_2_linkText: '移行準備評価配信ガイド',
    landing_moreResources_3_linkText: 'よくある質問',
    landing_moreResources_6_linkText: 'Slackにご参加ください: #aws-assessment-tool-a2t',
    landing_relatedServices_title: '関連サービス',
    landing_relatedServices_1_title: 'Migration Portfolio Assessment',
    landing_relatedServices_1_description: 'お客様のITポートフォリオを評価し、移行を計画し、ビジネスケースを構築します',
    landing_relatedServices_2_title: 'AWS 規範ガイダンスライブラリ',
    landing_relatedServices_2_description: 'AWS 移行パターンのライブラリ',

    // Side navigation
    side_navigation_administration_administration: '管理',
    side_navigation_administration_customer_accounts: '顧客アカウント',
    side_navigation_administration_overview: '概要',
    side_navigation_administration_recommended_actions: '推奨されるアクション',
    side_navigation_administration_report_templates: 'レポートテンプレート',
    side_navigation_administration_manage_templates: '管理テンプレート',
    side_navigation_view_all_assessments: 'すべての評価を表示',
    side_navigation_pre_event_pre_event: 'プレイベント',
    side_navigation_pre_event_manage_sponsor: 'スポンサーを管理する',
    side_navigation_assessment: '評価',
    side_navigation_results_results: '結果',
    side_navigation_results_update_assessment_info: '評価情報を更新する',
    side_navigation_results_review_all: 'すべて確認する',
    side_navigation_results_generate_report: 'レポートを生成する',
    side_navigation_results_complete_assessment: '完全な評価',
    side_navigation_missing_name: '名前が見つかりません',

    // Customer selection page
    customer_selection_table_title: '顧客',
    customer_selection_filter_space_holder: '顧客を探す',
    customer_selection_create_customer: '顧客の作成',
    customer_selection_customer_account_name: '顧客アカウント名',
    customer_selection_customer_account_id: 'アカウント ID',
    customer_selection_customer_industry: '業界',
    customer_selection_customer_region: '領域',
    customer_selection_customer_territory: '地域',
    customer_selection_customer_website: 'Webサイト',
    customer_selection_customers: '顧客',
    customer_selection_reference_id: 'リファレンス ID',
    customer_selection_created_by: '作成者',
    customer_selection_action: 'アクション',
    customer_selection_action_select: '選択する',
    customer_selection_action_select_all_customers: 'すべての顧客を選択',
    customer_selection_all_customers: 'すべての顧客',
    customer_selection_all_customers_selected: '選択したすべての顧客',
    customer_selection_selected_customer: '選択した顧客',
    customer_selection_see_more_customers: 'より多くの顧客を見る',
    customer_selection_loading_customers: '顧客の読み込み',
    customer_selection_preference_title: '環境設定',
    customer_selection_page_size_preference_title: 'ページあたりの顧客',
    customer_selection_table_no_customers: '顧客なし',
    customer_selection_table_no_customers_to_display: '表示する顧客はありません。',
    customer_selection_table_error_loading_customers: '顧客アカウントのローディングエラー',
    customer_selection_table_error_invalid_customer_id: '無効な顧客ID',
    customer_selection_create_title: '顧客アカウントの作成',
    customer_selection_create_account_details: 'アカウント詳細',
    customer_selection_create_account_name_placeholder: '例: ACME Corp. (2から128文字)',
    customer_selection_create_account_name_validation_min_char: 'アカウント名は最低2文字必要',
    customer_selection_create_account_name_validation_max_char: 'アカウント名は最大128文字まで',
    customer_selection_create_reference_id_placeholder: '例: Salesforce ID (1から128文字)',
    customer_selection_create_account_reference_id_validation_min_char: 'アカウントリファレンス ID は最低1文字必要',
    customer_selection_create_account_reference_id_validation_max_char: 'アカウントリファレンス ID は最大128文字まで',
    customer_selection_create_create_customer_account_successful: '顧客アカウントは正しく作成されました。',
    customer_selection_create_error_creating_customer: '顧客アカウントの作成エラー',
    customer_selection_update_title: '顧客アカウントの更新',
    customer_selection_update_update_customer_account_successful: '顧客アカウントは正しく更新されました。',
    customer_selection_update_error_updating_customer: '顧客アカウントの更新エラー',

    // Assessment metadata section
    assessment_current_assessment: '現在の評価',
    assessment_description: '評価の説明',
    assessment_customer_account_name: 'アカウント名',
    assessment_type: 'タイプ',
    assessment_status: '評価ステータス',
    assessment_created_by: '作成者',
    assessment_last_updated: '最終更新',
    assessment_workstreams: 'ワークストリーム',
    assessment_perspectives: '視点',

    // Assessment status bar section
    assessment_status_bar_answered: '回答済み',
    assessment_status_bar_in_progress: '進行中',
    assessment_status_bar_completed: '完了',
    assessment_status_bar_of: '/', // Japanese word order would reverse the numbers, so use a /
    assessment_status_bar_current_prompt_number: '現在のプロンプト番号:',

    // Assessment section
    assessment_complete_status_constraint:
        'これで、評価はシステムで完了としてマークされます。追加の編集を行う必要がある場合は、評価を進行中の状態に戻すことができます。',
    assessment_complete_info_panel_text:
        'このページを使用して、評価の詳細を確認し、完了としてマークします。' +
        '評価に変更を加える必要がある場合は、このページに戻ってステータスを[進行中]に戻すことができます。 ' +
        '\n' +
        'また、お客様にアンケートを送信することで、製品ロードマップに貴重な情報を提供することもできます。',
    assessment_complete_title: '完全な評価',
    assessment_complete_update_button_text: 'アップデート',
    assessment_complete_complete_button_text: '完了',
    assessment_complete_error_completing_assessment: '評価を完了中にエラーが発生しました',
    assessment_complete_complete_assessment_successful: '評価が完了しました',

    // Assessment create section
    assessment_create_enter_assessment_details: '評価の詳細を入力します',
    assessment_create_review_assessment_details: '評価の詳細を確認してください',
    assessment_create_invalid_assessment_details: '無効な評価の詳細',
    assessment_create_error_creating_assessment: '評価の作成中にエラーが発生しました',
    assessment_create_errors_retrieving_templates: 'テンプレートの取得中にエラーが発生しました',
    assessment_create_error_retrieving_all_templates: 'すべてのテンプレート取得中にエラーが発生しました',
    assessment_create_error_getting_assessment_types: '評価タイプの取得中にエラーが発生しました',
    assessment_create_customer_accounts: '顧客アカウント',
    assessment_create_customer_account: '顧客アカウント',
    assessment_create_account_id: 'アカウントID',
    assessment_create_owner: 'オーナー',
    assessment_create_territory: '地域',
    assessment_create_load_customers_error_message: '顧客アカウントの取得中にエラーが発生しました。最初のエラー:',
    assessment_create_error_retrieving_all_customer_accounts: 'すべての顧客アカウントの取得中にエラーが発生しました',
    assessment_create_error_getting_customer_accounts: '顧客アカウントの取得中にエラーが発生しました',
    assessment_create_loading_customers: '顧客の読み込み',
    assessment_create_customer_accounts_found: '見つかった顧客アカウント',
    assessment_create_no_customer_accounts: '顧客アカウントはありません',
    assessment_create_no_customer_accounts_to_display: '表示する顧客アカウントはありません',
    assessment_create_reload_customer_accounts: '顧客アカウントをリロードする',
    assessment_create_details: '評価の詳細',
    assessment_create_details_account: 'アカウント',
    assessment_create_details_description: '説明',
    assessment_create_details_description_placeholder: '例えばデータセンターの移行',
    assessment_create_map_id: 'MAP プログラム　エンゲージ ID',
    assessment_create_map_id_placeholder: '10桁の MPE ID または NA',
    assessment_create_customer_contact: '顧客の連絡先',
    assessment_create_customer_contact_placeholder: '例えば jdoe@acme.com (最大128文字)',
    assessment_create_workshop_date: 'ワークショップの日付',
    assessment_create_readout_date: '報告会の日付',

    assessment_create_previous_month: '前月',
    assessment_create_today: '今日',
    assessment_create_delivered_by: 'によって配信',
    assessment_create_demo_or_test_constraint: '顧客活動を生成するためにデータを使用しないでください',
    assessment_create_demo_or_test: '実際の顧客データではなく、デモまたはテスト入力',
    assessment_create_help_panel_delivery_guidance: 'デリバリーガイダンス',
    assessment_create_info_panel_step_1:
        'このページを使用して、顧客名を指定し、評価タイプを選択し、' + '評価の詳細を用意して、新しい評価を作成します。',
    assessment_create_workshopdate_readoutdate_constraint: '正確な日付がまだ不明かどうかを確認してください。',
    assessment_create_optout_recommendation_constraint: 'これはプライバシー要件が高いお客様に使用されます',
    assessment_create_optout_recommendation: '完了した評価の詳細をオファリングチームと共有することをオプトアウトする',

    // Assessment create select customer
    assessment_create_select_customer_title: '既存の顧客アカウントの選択',
    assessment_create_select_customer_description:
        'キーワードを使用してSalesForceから顧客アカウントを検索します。新規の顧客は、最初にSalesForceでアカウントを作成してください。',
    assessment_create_select_customer_description_partner:
        '以下から既存の顧客を選択するか、キーワードを使ってアカウントを検索します。顧客の作成をクリックして新しく作成することも出来ます。',
    assessment_create_select_customer_filter_placeholder: '名前または ID で既存の顧客アカウントを検索する',
    assessment_create_select_customer_no_account_selected: '顧客アカウントが選択されていません。先にアカウントを選択してください。',

    // Assessment create customer profile
    assessment_create_customer_profile_title: '顧客プロファイルの記述',
    assessment_create_customer_profile_header: '顧客プロファイル',
    assessment_create_customer_profile_description: 'このページを使用して、プロファイリングに関する質問に回答して顧客情報を提供します。',

    assessment_create_customer_profile_segment_prompt: 'どのMRAカスタマイズバージョンが、顧客プロファイルをより反映していますか？',
    assessment_create_customer_profile_segment_description:
        'このセグメントは、組織の連携ニーズに起因する非技術的な問題や、クラウドがビジネスモデルや運用モデルとどのように連携しているかなど、特定の懸念事項に対処する必要があることを示している可能性があります。',

    assessment_create_customer_profile_segments_ent_label: 'エンタープライズ (ENT)',
    assessment_create_customer_profile_segments_ent_description:
        '独立系ソフトウェアベンダー (ISV) でもグローバルシステムインテグレーター (GSI) でもないもの。これらの組織の中には、クラウドインフラストラクチャに大きく移行した企業もあり、AWS に全面的に取り組んでいる場合もあります。より一般的には、このセグメントに代表されるのは、レガシーテクノロジーに多額の投資を行っている従来の企業です。アプリケーションとデータの大部分をオンプレミス、コロケーションデータセンター、あるいはその2つのハイブリッドでホストしていますが、自社のテクノロジープラットフォーム、アプリケーション、運用モデルの最新化が強く求められています。',

    assessment_create_customer_profile_segments_dnb_label: 'デジタルネイティブビジネス (DNB)',
    assessment_create_customer_profile_segments_dnb_description:
        'デジタルネイティブビジネス (DNB)の顧客は、（1）「クラウドで生まれ」、本質的に「テクノロジーに精通した」消費者中心の企業、（2）B2C（B2C）モデル*を運用している企業、（3）主なビジネスモードがオンラインであるが、商品やサービスは物理的なものである場合があります。DNBは多くの場合、クラウドファーストやモバイルアプリを構築する高度に中央集権化された組織ですが、技術的な負債（レガシーまたは買収による負債）がある場合もあります。DNB は通常、大規模な移行は行わないが、より成熟した DNB には「存在する」こともあります。このセグメントは通常、スタートアップ企業の卒業生（つまり、TTM GARの基準額が120万ドルを超えた顧客、またはIPO後に買収された新興企業）が移行するセグメントです。小規模なDNBはこの定義を満たしますが、スタートアップ企業ではありません。*メインのB2Cモデルに加えてB2BモデルまたはB2B2Cモデルを運営するDNBも、その「B2C」モーションが上記の定義を満たしていれば対象となります。',

    assessment_create_customer_profile_segments_isv_label: '独立系ソフトウェアベンダー (ISV) ',
    assessment_create_customer_profile_segments_isv_description:
        '独立系ソフトウェアベンダー (ISV) とは、(1) ソフトウェアの開発、保守、販売、(2) 企業間取引 (B2B) モデル**の運営、(3) 収益の大部分 (50% 以上) を永久および/またはサブスクリプションソフトウェアライセンス、および関連するサポートおよび保守サービスの販売を通じて生み出している組織です。WWCS の観点から見ると、このセグメントは AWS が ISV に販売するエンゲージメントが特徴です。**メインの B2B モデルに加えて B2C モデルを運用している ISV が含まれ、その「B2B」の動きが上記の定義を満たしていれば B2B2C 組織も含まれる可能性があります。',

    assessment_create_customer_profile_segments_smb_label: '中小企業 (SMB)',
    assessment_create_customer_profile_segments_smb_description:
        '中小企業 (SMB)セグメントには、中小企業とリセラーの両方の顧客が含まれます。中小規模ビジネスには、あらゆる業界にまたがり、一般的には民間企業であり、運用モデル、組織構造、管理スタイル、テクノロジーへの依存、IT専門知識、成長能力の多様性を特徴とする顧客が含まれます。これらを合わせると、他のすべてのセグメントの企業数をはるかに上回り、一般的には中堅企業より前のセグメントを代表しています。リセラーとは、リセラー契約を締結し、エンドカスタマーへのリーチを拡大することで顧客への導入を促進している顧客です。リセラーの収益の大半は、エンドカスタマーのテリトリーに振り向けられます。',

    assessment_create_customer_profile_segments_sup_label: 'スタートアップ (SUP)',
    assessment_create_customer_profile_segments_sup_description:
        'スタートアップ (SUP)セグメントは、テクノロジーインフラストラクチャの基盤を築いている高成長の潜在顧客です。過去 12 か月間の総償却収益 (TTM GAR) が十分に達成されれば、DNB または ISV セグメントへの成長が見込まれます。具体的には、エンジェル、大学、アクセラレーター、インキュベーター、ベンチャーキャピタル（VC）企業、政府系スタートアップインキュベーター、またはその他のスタートアップ組織と提携している、またはそれらから資金を調達した口座、またはActivateなどのスタートアップ発見プログラムに加入している口座、またはそれらから資金を調達した口座、またはActivateなどのスタートアップ発見プログラムに加入している口座で構成されます。さらに、SUPセグメントの顧客は、最近資金調達イベントを行った比較的若い企業で、IPOも買収もされていません。',

    assessment_create_customer_profile_cloud_maturity_prompt:
        'この取り組みに関わっているチームの現在のクラウドアクティビティと能力はどの程度と認識されていますか?',
    assessment_create_customer_profile_cloud_maturity_description:
        '組織内の経験レベルを理解することは、クラウドの基盤と、アカウントやセキュリティの側面を含む基本事項に重点を置く際に役立ちます。',

    assessment_create_customer_profile_cloud_maturity_low_label: '低または不明',
    assessment_create_customer_profile_cloud_maturity_low_description:
        'クラウドサービスはユーティリティです。最先端にとどまるのではなく、テクノロジーが完成するのを待ってから、実証済みのロードマップに従って実装します。インフラストラクチャの一部はパブリッククラウドにありますが、オンプレミスのデータセンターでは大量のレガシーインフラストラクチャを維持しています。このようなテクノロジーに対する保守的な姿勢は、成熟度の低い組織がクラウドサービスから最大限の価値を引き出すことをより困難にしています。' +
        '<br/>さらに、次の要因が1つ以上示されます: <ul>' +
        '<li>現在の AWS 支出額は限られています</li>' +
        '<li>移行速度が制限されている (1 か月あたりに移行されるワークロード)</li>' +
        '<li>限定的なサービスミックス (主にコアクラウドサービス)</li>' +
        '<li>クラウドの経験/能力が限られている</li>' +
        '</ul>',

    assessment_create_customer_profile_cloud_maturity_high_label: '高',
    assessment_create_customer_profile_cloud_maturity_high_description:
        'クラウドサービスがビジネスにもたらす変革の力を十分に認識してください。彼らは積極的に革新を行い、テクノロジーを競争上の優位性と見なしています。成熟度の高い組織はクラウドに全面的に取り組んでおり、インフラストラクチャの大部分はパブリッククラウドに収容されています。このような態度と組織的に見られるクラウド関連の行動は、成熟度の高い組織がクラウドサービスから最大の価値を引き出すことにつながります。' +
        '<br/>さらに、次の要因が1つ以上示されます: <ul>' +
        '<li>現在の AWS への多額の支出</li>' +
        '<li>大幅な移行速度 (1 か月あたりのワークロードの移行)</li>' +
        '<li>バランスの取れたサービスミックス (コアサービスとアドバンスドサービスなど)</li>' +
        '<li>確立され発展を続けるクラウドエクスペリエンス/機能</li>' +
        '</ul>',

    assessment_create_customer_profile_plan_and_alignment_exists_prompt:
        'すべての利害関係者の責任を明確に理解し、連携しながらカスタマージャーニーを進めるための明確な目標、長期的なロードマップ、および短期的なタスクプランはありますか？',
    assessment_create_customer_profile_plan_and_alignment_exists_description:
        '連携のレベルやチームの連携や結束力を理解することは、非技術的な障害に対処する必要性を導くのに役立ちます。特に次のステップについて、明確な計画と意思統一が取れていないことは、前進を妨げる重大な障害となります。',

    assessment_create_customer_profile_plan_and_alignment_exists_yes_label: 'はい',
    assessment_create_customer_profile_plan_and_alignment_exists_yes_description:
        'このクラウドイニシアチブには、組織的な制約や相反する課題はほとんどありません。目標は明確で、把握され、周知されており、お客様、AWS、パートナーにとっての次の正しいステップを含む行動計画の証拠があります。',

    assessment_create_customer_profile_plan_and_alignment_exists_no_label: 'いいえ',
    assessment_create_customer_profile_plan_and_alignment_exists_no_description:
        'このイニシアチブに関連する能力を提供するか、最終的には活用または管理するグループ間およびグループ内の連携を強化する必要があります。お客様は、準備状況のギャップを埋めて前に進めるためのガイダンスを必要としています。',

    // Assessment create select assessment type
    assessment_create_select_assessment_type_title: 'アセスメントタイプの選択',
    assessment_create_select_assessment_type_description: 'このページを使用して、評価タイプを選択してください。',
    assessment_create_select_assessment_type_filter_placeholder: 'アセスメントタイプの検索',
    assessment_create_template_table_empty_title: '利用できるアセスメントがありません',
    assessment_create_template_table_header: 'アセスメントタイプ',

    // Assessment create recommendation alerts
    assessment_create_recommendation_alerts_mra_not_recommended:
        '提供された顧客プロファイル情報によると、「ADV-Advanced」は移行準備評価（MRA）の軽量版として推奨されています。これにより、クラウドの経験が豊富なお客様のリスクを特定し、移行プロセスを加速できます。MRAがMAPの資金調達に必要で、顧客がそれを望んでいない場合は、MRA例外を求めるためのガイダンスに従い、「キャンセル」をクリックして評価作成ワークフローを終了してください。',

    // Assessment list page
    assessment_list_all_customers: 'すべてのお客様',
    assessment_list_create_assessments: '評価の作成',
    assessment_list_active_and_favorite_assessments: '実施中の評価',
    assessment_list_completed_assessments: '完了した評価',
    assessment_list_assessments: '評価',
    assessment_list_filter_placeholder: '評価を見つける',
    assessment_list_loading_assessments: '評価の取得',
    assessment_list_preference_title: '環境設定',
    assessment_list_page_size_preference_title: 'ページごとの評価',
    assessment_list_assessments_table_no_assessments: '評価なし',
    assessment_list_assessments_table_no_assessments_to_display: '表示する評価はありません。',
    assessment_list_workshop_date: 'ワークショップの日付',
    assessment_list_readout_date: '報告会の日付',
    assessment_list_actions: 'アクション',
    assessment_list_info_panel_text1:
        'このページを使用して、自分が所有しているアセスメント、または他のユーザと共有しているアセスメントを表示します。' +
        '「アクティブでお気に入り評価」セクションには、「お気に入り」としてマークされているか、まだ進行中の評価が含まれています。' +
        '「完了した評価」 セクションには、「完了」 とマークされた評価が表示されます。',
    assessment_list_info_panel_text2: '単一の顧客の評価に表示を制限するには、ページ上部のフィルターを使用します。',
    assessment_list_info_panel_text3: '新しい評価の場合は、評価を作成 オプションを使用します。',

    // Assessment share section
    assessment_share_share: '共有',
    assessment_share_user_not_found: 'ユーザーが見つかりません',
    assessment_share_multiple_users_found: '複数のユーザーが見つかりました',
    assessment_share_select_users: '評価を許可するユーザーを選択してください',
    assessment_share_invite: '招待',
    assessment_share_invite_message: 'このユーザーを評価へのアクセスに招待しますか?',
    assessment_share_accept_invitation: '評価へのアクセス招待を受け付ける',
    assessment_share_accept_invitation_message: '招待状を検索中...',
    assessment_share_accept_invitation_successful: '共有評価への招待を正常に受け付けました。',
    assessment_share_current_permissions: '現在の権限',
    assessment_share_share_this_assessment: 'この評価を共有する',
    assessment_share_status: '状態',
    assessment_share_permission: '許可',
    assessment_share_username: 'ユーザー名',
    assessment_share_username_placeholder: '例: jdoe または ドメインが異なる場合は jdoe@othercompany.com',
    assessment_share_name: '名前またはメールアドレス',
    assessment_share_remove: '削除する',
    assessment_share_action_add: '追加',
    assessment_share_status_pending_add: '保留中の追加',
    assessment_share_status_pending_update: '更新待ち',
    assessment_share_status_pending_invite: '招待待ち',
    assessment_share_status_invited: '招待しました',
    assessment_share_status_saved: '保存しました',
    assessment_share_loading_permissions: '権限の取得',
    assessment_share_table_no_permissions: '権限なし',
    assessment_share_table_no_permissions_to_display: '表示する権限がありません',
    assessment_share_without_at_sign: '@ amazon.comなし',
    assessment_share_error_update_permissions: '権限の更新に失敗しました',
    assessment_share_error_remove_permissions: '権限を削除できません',
    assessment_share_error_remove_last_permission: '最後の FULL 権限を削除すると、評価にアクセスできなくなります。',
    assessment_share_error_invalid_username: 'ユーザーエイリアスまたは電子メールアドレスが無効です',
    assessment_share_error_accept_invitation: '共有評価への招待を受け入れるときにエラーが発生しました',

    // Assessment pre-event section
    assessment_pre_event_manage_sponsor: 'スポンサーを管理する',
    assessment_pre_event_sponsor: 'スポンサー',
    assessment_pre_event_email_placeholder: 'username@company.com',
    assessment_pre_event_due_date: '期日',
    assessment_pre_event_due_date_description: 'プレイベントの期日と時刻を指定します',
    assessment_pre_event_previous_month: '前月',
    assessment_pre_event_today: '今日',
    assessment_pre_event_status: '状態',
    assessment_pre_event_participants: '参加者',
    assessment_pre_event_submitted_responses: '提出された回答',
    assessment_pre_event_pre_event_not_created: 'プレイベントのアンケートは作成されていません',
    assessment_pre_event_sponsor_email_validation_message: 'Eメールの形式が無効です',
    assessment_pre_event_due_date_validation_message: '日付の形式が無効です。YYYY/MM/DD',
    assessment_pre_event_couldnt_share:
        'プレイベントを次のユーザーと共有することができません。プレイベントにアクセスできないユーザーに、この評価を再度共有してください。問題が解決しない場合は、問題を送信して支援を依頼してください。',
    assessment_pre_event_error_saving: 'エラー保存',
    assessment_pre_event_assessment_not_loaded: '評価がロードされていません',
    assessment_pre_event_error_loading: '読み込みエラー',
    assessment_pre_event_cannot_update_unsaved: '保存されていないアンケートは更新できません',
    assessment_pre_event_sponsor_not_save: 'プレイベントのスポンサーは保存されていません',
    assessment_pre_event_cannot_send_email: 'メールを送信できません',
    assessment_pre_event_email_template_not_available: 'スポンサーのメールテンプレートは利用できません',
    assessment_pre_event_info_panel_text_1:
        'このページを使用して、プレイベントアンケートのスポンサー (顧客POC) の電子メール・アドレスと回答の提出期限を指定して、評価用のイベント前アンケートを作成します。' +
        'ブラウザがポップアップウィンドウを許可するように設定されていて、デフォルトのメールクライアントが設定されている場合、' +
        '「保存」 を選択すると、イベント前のアンケートの割り当てについてスポンサーに通知するための電子メールが生成されます。' +
        'また、「スポンサー招待メール」テンプレートを使用して、スポンサーへのメッセージを手動で作成することもできます。 ',
    assessment_pre_event_info_panel_text_2:
        'デフォルトのメールクライアントを使用して招待状をスポンサーに再送信する必要がある場合は、「送信」 を選択します。',
    assessment_pre_event_info_panel_text_3:
        'スポンサーは、Eメールの指示に従って AWS ビジネスデータ収集ツールにサインアップし、参加者を追加して完了ステータスをモニタリングすることで、' +
        'イベント前のアンケートを管理する必要があります。サインアッププロセス中、スポンサーは、' +
        'スポンサーに割り当てたときに指定したメールアドレスと同じメールアドレスを使用する必要があります。このプロセスに関する詳細は、' +
        'AWS 規範ガイダンスライブラリにあるこのオファリングに関連する AWS が公開した MRA 提供ガイドに記載されています。' +
        'これには、MRA 実施のファシリテーター、スポンサー、参加者向けの詳細で共有可能な Runbook が含まれます。',
    assessment_pre_event_questionnaire_sponsor_view: 'プレイベントのアンケート管理リンク（スポンサービュー）：',
    assessment_pre_event_questionnaire_participant_view: 'プレイベントのアンケートのリンク (参加者ビュー）：',
    assessment_pre_event_send_designated_questions: 'プレイベントの指定の質問を送信',
    assessment_pre_event_send_all_questions: 'すべての質問を送信 (「任意」を除く)',
    assessment_pre_event_send_all_questions_description: 'アンケートの作成後は変更できません。',

    // Assessment facilitate page
    assessment_facilitate_filter: 'フィルター',
    assessment_facilitate_next: '次',
    assessment_facilitate_previous: '前',
    assessment_facilitate_comments: 'コメント',
    assessment_facilitate_comments_placeholder: 'ここに観測値を入力してください',
    assessment_facilitate_comments_saved: '保存されたコメント',
    assessment_facilitate_error_api: '何かがうまくいかなかった。',
    assessment_facilitate_live_event: 'ライブイベント',
    assessment_facilitate_participant_comments: '参加者のコメント',
    assessment_facilitate_i_dont_know: '不明',
    assessment_facilitate_pre_event: 'プレイベント',
    assessment_facilitate_rating_saved: '評価が保存されました',
    assessment_facilitate_response_saved: 'Response saved',
    assessment_facilitate_try_update_again: 'もう一度やり直してください',
    assessment_facilitate_updating_comments: 'コメントを更新しています...',
    assessment_facilitate_updating_rating: '評価を更新しています...',
    assessment_facilitate_updating_response: 'レスポンスを更新しています...',
    assessment_facilitate_votes: '票',
    assessment_facilitate_error_loading_question: '質問の読み込みエラー',
    assessment_facilitate_error_loading_assessment: 'この評価にアクセスできません。',
    assessment_facilitate_category_has_no_questions: 'このカテゴリには質問はありません。',

    // Assessment live session
    assessment_live_session_start: '開始',
    assessment_live_session_active: 'アクティブ',
    assessment_live_session_create_new_session: '新しいセッションを作成する',
    assessment_live_session_create_password_label: 'パスワード',
    assessment_live_session_create_password_description: 'これは、すべてのワークショップ参加者がログインするために使用されます',
    assessment_live_session_create_password_hint: '8〜20文字で、大文字が1つ、小文字が1つ、数字が1つ以上',
    assessment_live_session_create_password_placeholder: 'RoadRunner2022',
    assessment_live_session_create_password_validation_min_char: 'セッションパスコードは8文字以上である必要があります',
    assessment_live_session_create_password_validation_mix_char:
        'セッションパスコードには、少なくとも1つの大文字、1つの小文字、および1つの数字が含まれている必要があります',
    assessment_live_session_create_password_validation_max_char: 'セッションパスコードは20文字を超えることはできません',
    assessment_live_session_create_session_instruction: 'ワークショップセッションを開始するには、以下を提出してください',
    assessment_live_session_create_session_title_label: 'セッション名',
    assessment_live_session_create_session_title_placeholder: 'AcmeCorpワークショップ',
    assessment_live_session_create_session_title_hint: '最大50文字',
    assessment_live_session_create_session_title_validation_min_char: 'セッションタイトルを空にすることはできません',
    assessment_live_session_create_session_title_validation_max_char: 'セッションタイトルは50文字を超えることはできません',
    assessment_live_session_error_api: 'ライブセッションAPIエラー',
    assessment_live_session_inactive: '非アクティブ',
    assessment_live_session_resume_session: 'セッション再開',
    assessment_live_session_session_link: 'セッションリンク',
    assessment_live_session_start_polling_session: 'ポーリングセッションを開始します',
    assessment_live_session_suspend_session: '中断',

    // Assessment data model
    assessment_model_account_is_not_empty: 'アカウントを空にすることはできません',
    assessment_model_description_is_not_empty: '説明を空にすることはできません',
    assessment_model_map_id_is_not_empty: 'MAP Program engagement ID 空にすることはできません',
    assessment_model_workshop_date_is_not_empty: 'ワークショップの日付を空にすることはできません',
    assessment_model_readout_date_is_not_empty: '報告会の日付を空にすることはできません',
    assessment_model_delivered_by_is_not_empty: 'によって配信されたものを空にすることはできません',
    assessment_model_type_is_not_empty: '評価タイプを選択する必要があります',

    // Assessment update page
    assessment_update_cancel_button_text: 'キャンセル',
    assessment_update_update_assessment_error: 'エラー',
    assessment_update_update_assessment_error_updating: '評価の更新エラー',
    assessment_update_update_assessment_successful: '評価は更新されました。',
    assessment_update_update_assessment_invalid_assessment_details: '無効な評価の詳細',
    assessment_update_load_assessment_error_content: 'エラー負荷評価',
    assessment_update_load_assessment_error_header: 'エラー',
    assessment_update_load_assessment_errors_content: '発生したエラー読み込み評価',
    assessment_update_load_assessment_errors_header: 'エラー',
    assessment_update_update_assment_review_details: '評価の詳細を確認してください',
    assessment_update_save_button_text: '更新を保存する',
    assessment_update_title: '評価情報を更新する',
    assessment_update_info_panel_text: 'このページを使用して、ワークショップ実施日や報告会の日付など、評価の詳細を更新します。',

    // Assessment delete section
    assessment_delete_delete: '削除',
    assessment_delete_delete_this_assessment_permanently: 'この評価を完全に削除しますか？このアクションは元に戻せません。',
    assessment_delete_confirm_prompt: '削除を確定するには、テキスト入力フィールドに「削除」と入力します。',
    assessment_delete_confirm_input_expected: '削除',
    assessment_delete_error_delete_assessment: '評価の更新に失敗しました',

    // Assessment default rating bands
    assessment_default_rating_band_title_incomplete: '不完全',
    assessment_default_rating_band_title_bad: '赤色',
    assessment_default_rating_band_title_concern: '黄色',
    assessment_default_rating_band_title_good: '緑色',
    assessment_default_rating_band_description_incomplete: 'N/A または 進行中',
    assessment_default_rating_band_description_bad: '進行の妨げを解除するには即時の支援が必要',
    assessment_default_rating_band_description_concern: '勢いを加速させるには注意が必要',
    assessment_default_rating_band_description_good: 'レディネスの成熟には最小限の支援しか必要ありません',

    // Assessment results -> review all section
    assessment_results_review_all_legend_incomplete: '該当なしまたは進行中',
    assessment_results_review_all_legend_concern: '勢いを加速するために注意が必要',
    assessment_results_review_all_legend_good: '準備の成熟には最小限の支援が必要です',
    assessment_results_review_all_legend_bad: '進行状況のブロックを解除するには、早急な支援が必要です',
    assessment_results_review_all_design_evolve: 'COEの設計または進化',

    // Assessment results -> review all -> heatmap section
    assessment_results_review_all_heatmap_all_drivers: 'すべての機能',
    assessment_results_review_all_heatmap_heatmap: 'ヒートマップ',
    assessment_results_review_all_heatmap_legends: 'ヒートマップセルの状態',
    assessment_results_review_all_heatmap_info_panel_text:
        'このページを使用して、すべての準備活動における現在の成熟度を確認します。' +
        '1つ以上の質問にスコアが提供されていない場合、対応するアクティビティの平均スコアは計算されません。' +
        '観測を完了または更新するには、ヒートマップカードをクリックして、レポートの関連セクションに移動します。',
    assessment_results_review_all_heatmap_status_text_incomplete: '該当なしまたは進行中',
    assessment_results_review_all_heatmap_status_text_good: '準備の成熟には最小限の支援が必要です',
    assessment_results_review_all_heatmap_status_text_concern: '勢いを加速するために注意が必要',
    assessment_results_review_all_heatmap_status_text_bad: '進行状況のブロックを解除するには、早急な支援が必要です',
    assessment_results_review_all_heatmap_pre_mobilize: '事前動員',

    // Assessment results -> review all -> radar section
    assessment_results_review_all_radar_radar: 'レーダー',
    assessment_results_review_all_radar_info_panel_text:
        'このページを使用して、すべての準備カテゴリとパースペクティブにわたる現在の成熟度レベルを確認します。' +
        '1 つ以上の質問にスコアが指定されていない場合、対応するアクティビティの平均スコアは計算されず、' +
        'アクティビティは「進行中または該当なし」とマークされ、レーダーチャートには反映されません。',

    // Assessment results -> review all -> scores section
    assessment_results_review_all_scores_scores: 'スコア',
    assessment_results_review_all_scores_na: 'N/A',
    assessment_results_review_all_scores_not_applicable: '該当なし',
    assessment_results_review_all_scores_incomplete: '不完全な',
    assessment_results_review_all_scores_info_panel_text:
        'このページを使用して、すべての準備カテゴリにおける現在の成熟度レベルを確認します。' +
        '1 つ以上の質問にスコアが指定されていない場合、対応するアクティビティの平均スコアは計算されず、' +
        'アクティビティは 「進行中」 または「該当なし」 としてマークされます。',

    // Assessment results -> review all -> responseDistribution section
    assessment_results_review_all_response_distribution: '回答分布',
    assessment_results_review_all_response_distribution_info_panel_text:
        'このページを使用して、すべての準備状況カテゴリにわたる回答の分布を確認してください。 ',

    // Assessment results -> review all -> report section
    assessment_results_review_all_report_report: '報告',
    assessment_results_review_all_report_info_header: '結果ダッシュボード',
    assessment_results_review_all_report_info_legend: '説明',
    assessment_results_review_all_report_info_content:
        'このページを使用して、すべての準備カテゴリにわたる現在の成熟度を確認し、要約された観察結果と推奨されるアクションを文書化します。' +
        ' 1つ以上の質問にスコアが提供されていない場合、対応するアクティビティの平均スコアは計算されず、アクティビティは「進行中またはN / A」としてマークされ、レーダーチャートに反映されません。',
    assessment_results_review_all_report_participant_comments: '参加者のコメント',
    assessment_results_review_all_report_text: '文章',
    assessment_results_review_all_report_rating: '評価',
    assessment_results_review_all_report_category: 'カテゴリー',
    assessment_results_review_all_report_activity: 'アクティビティ',
    assessment_results_review_all_report_phase: '段階',
    assessment_results_review_all_report_questions: '質問',
    assessment_results_review_all_report_remove: '削除する',
    assessment_results_review_all_report_filtering_text_placeholder: '何でも検索',
    assessment_results_review_all_report_facilitator_comments: 'ファシリテーターのコメント:',
    assessment_results_review_all_report_facilitator_comments_error_api: '何かがうまくいかなかった。再試行',
    assessment_results_review_all_report_facilitator_comments_placeholder: 'ここに観測値を入力してください',
    assessment_results_review_all_report_facilitator_comments_saved: 'コメントが保存されました。',
    assessment_results_review_all_report_facilitator_comments_try_update_again: '再試行',
    assessment_results_review_all_report_facilitator_comments_updating: 'コメントを更新しています...',
    assessment_results_review_all_report_choose_rating: '評価を選択してください',
    assessment_results_review_all_report_choose_category: 'カテゴリを選択',
    assessment_results_review_all_report_choose_activity: 'アクティビティを選択',
    assessment_results_review_all_report_choose_phase: 'フェーズを選択',
    assessment_results_review_all_report_summary_observations: '要約観察',
    assessment_results_review_all_report_summary_observations_tip:
        '観測値を手動で追加するか、観測値の事前入力をクリックして、すべてのパースペクティブ質問メモを追加します',
    assessment_result_review_all_report_summary_observations_populate_hover_text:
        'ファシリテーターのコメントをサマリーオブザベーションのテキストボックスにコピーします。',
    assessment_results_review_all_report_summary_observations_placeholder: 'ここに観測値を手動で追加するか、観測値の事前入力をクリックします',
    assessment_results_review_all_report_pre_populate_summary_observations: '事前入力観測',
    assessment_results_review_all_report_limit_show_more: '他のフィルターを表示する',
    assessment_results_review_all_report_limit_show_fewer: '表示するフィルターの数を減らす',
    assessment_results_review_all_report_matches: '{count, plural, =0 {0一致する} one {1試合} other {#一致する}}',
    assessment_results_review_all_report_filters_applied:
        '{count, plural, =0 {0個のフィルターが適用されました} one {1つのフィルターが適用されました} other {# 適用されたフィルター}}',
    assessment_results_review_all_report_recommended_actions_recommended_actions: '推奨アクション',
    assessment_results_review_all_report_recommended_actions_add_modal_button_cancel: 'キャンセル',
    assessment_results_review_all_report_recommended_actions_add_modal_button_custom_action: 'カスタムアクションを追加',
    assessment_results_review_all_report_recommended_actions_add_modal_button_ok: 'アクションを確認する',
    assessment_results_review_all_report_recommended_actions_add_modal_header: '推奨アクションを追加する',
    assessment_results_review_all_report_recommended_actions_add_modal_header_2: '所定のアクションリストから選択',
    assessment_results_review_all_report_recommended_actions_grid_button_add_action: 'アクションを追加',
    assessment_results_review_all_report_recommended_actions_grid_button_add_custom_action: 'カスタムアクションを追加',
    assessment_results_review_all_report_recommended_actions_grid_button_add_prescribed_action: '所定アクションを追加',
    assessment_results_review_all_report_recommended_actions_grid_column_action: 'アクション',
    assessment_results_review_all_report_recommended_actions_grid_column_owner: 'オーナー',
    assessment_results_review_all_report_recommended_actions_grid_column_recommendation_owner: '推奨事項オーナー',
    assessment_results_review_all_report_recommended_actions_grid_column_action_owner: 'アクションオーナー',
    assessment_results_review_all_report_recommended_actions_grid_column_priority: '優先順位',
    assessment_results_review_all_report_recommended_actions_grid_column_effort: '努力',
    assessment_results_review_all_report_recommended_actions_grid_column_target_date: '予定期限',
    assessment_results_review_all_report_recommended_actions_grid_column_progress: '進展',
    assessment_results_review_all_report_recommended_actions_grid_column_benefit: 'メリット',
    assessment_results_review_all_report_recommended_action_grid_column_delete: '削除',
    assessment_results_review_all_report_recommended_action_grid_column_drag: 'ドラッグ',
    assessment_results_review_all_report_recommended_actions_grid_header: '推奨アクション',
    assessment_results_review_all_report_recommended_actions_grid_nodata_message: 'アクションなし',
    assessment_results_review_all_report_recommended_actions_grid_placeholder_targetdate: 'mm/dd/yyyy',
    assessment_results_review_all_report_recommended_actions_grid_placeholder_owner: '所有者名を入力してください',
    assessment_results_review_all_report_recommended_actions_grid_actions_saved: '保存されたアクション。',
    assessment_results_review_all_report_recommended_actions_grid_error_api: '何かがうまくいかなかった。再試行',
    assessment_results_review_all_report_recommended_actions_grid_try_update_again: '再試行',
    assessment_results_review_all_report_recommended_actions_grid_updating_actions: 'アクションを更新しています...',
    assessment_results_review_all_report_recommended_actions_delete_recommendations: '推奨事項を削除',
    assessment_results_review_all_report_recommended_actions_confirm_delete_recommendations: '次の推奨事項を削除しますか?',
    assessment_results_review_all_report_recommended_actions_create_custom_recommendation: 'カスタム推奨事項の作成',
    assessment_results_review_all_report_recommended_actions_edit_recommendation: '推奨事項を編集',
    assessment_results_review_all_report_recommended_actions_select_prescribed_recommendations: '規定のアクションを選択する',
    assessment_results_review_all_report_recommended_actions_failed_delete_recommendations: '推奨事項を削除できませんでした',
    assessment_results_review_all_report_recommended_actions_failed_create_custom_recommendation: 'カスタム推奨事項を削除できませんでした',
    assessment_results_review_all_report_recommended_actions_failed_edit_recommendation: '推奨事項の編集に失敗しました',
    assessment_results_review_all_report_recommended_actions_failed_load_recommendations: '推奨事項を読み込めませんでした',
    assessment_results_review_all_report_recommended_actions_failed_select_prescribed_recommendations: '規定された推奨事項を追加できませんでした',
    assessment_results_review_all_report_recommended_actions_in_progress: '進行中',
    assessment_results_review_all_report_recommended_actions_inline_edit_disabled: 'このフィールドの更新は無効です',
    assessment_results_review_all_report_recommended_actions_completed: '完了しました',
    assessment_results_review_all_report_recommended_actions_not_started: '未開始',
    assessment_results_review_all_report_error_empty_report: 'レポートデータが見つかりません',

    // Assessment results -> manage snapshots section
    assessment_results_manage_snapshots_info_panel_text:
        'このページを使用して、長期にわたる評価のスナップショットを作成し、それらを比較してください。' +
        'スナップショットのリンクをクリックすると、スナップショットが読み込まれます。<br/><br/>現在の評価ビューに戻るには、' +
        'ドロップダウンメニューを使用して現在の評価の名前を選択するか、スナップショットのリストから [現在の評価] をクリックします。',

    // Assessment results -> generate report page
    assessment_results_generate_report_generate_report: 'レポート生成',
    assessment_results_generate_report_summary: '概要',
    assessment_results_generate_report_summary_with_qa: '概要とQ&A',
    assessment_results_generate_report_rating_only: '格付けのみ',
    assessment_results_generate_report_rating_and_comments: '格付けとコメント',
    assessment_results_generate_report_results_presentation: 'プレゼンテーション結果',
    assessment_results_generate_report_powerpoint_slides: 'PowerPointスライド',
    assessment_results_generate_report_assessment_report: '評価とコメント',
    assessment_results_generate_report_word_document: 'Word文書',
    assessment_results_generate_report_recommended_action_backlog: '推奨対策一覧',
    assessment_results_generate_report_excel_spreadsheet: 'エクセルスプレッドシート',
    assessment_results_generate_report_download: 'ダウンロード',
    assessment_results_generate_report_info_panel_text:
        'このページを使用し、使用可能なフォーマットを使用してさまざまなタイプの評価リポートを生成します。',
    assessment_results_generate_report_report_template_lookup: 'レポートテンプレートの検索',
    assessment_results_generate_report_error_report_template_lookup: 'レポートテンプレートの検索中にエラーが発生しました',
    assessment_results_generate_report_report_download_progress_text: 'レポートを生成しています。これには数秒かかることがあります...',
    assessment_results_generate_report_report_download_complete_text: 'レポート生成が成功しました。',
    assessment_results_generate_report_report_template_not_available: 'このアセスメントではレポートテンプレートが利用できません。',
    assessment_results_generate_report_error_report_download: 'レポートの生成中にエラーが発生しました。',
    assessment_results_generate_report_error_assessment_scorecard: '評価またはスコアスケールが用意されていません。',

    // Generate report parameters sent to RGS
    assessment_results_generate_report_parameters_workstream: 'ワークストリーム',
    assessment_results_generate_report_parameters_workstream_phase: 'ワークストリームのフェーズ',
    assessment_results_generate_report_parameters_workstreams: 'ワークストリーム',
    assessment_results_generate_report_parameters_average_workstream_score: '平均スコア',
    assessment_results_generate_report_parameters_section: 'セクション',
    assessment_results_generate_report_parameters_perspective: 'パースペクティブ',
    assessment_results_generate_report_parameters_perspectives: 'パースペクティブ',
    assessment_results_generate_report_parameters_readiness_activity: '準備活動',
    assessment_results_generate_report_parameters_recommended_actions: '推奨されるアクション',
    assessment_results_generate_report_parameters_action: 'アクション',
    assessment_results_generate_report_parameters_actions: 'アクション',
    assessment_results_generate_report_parameters_action_status: '状況',
    assessment_results_generate_report_parameters_actions_and_next_steps: 'アクションと次のステップ',
    assessment_results_generate_report_parameters_action_priority: 'アクションの優先度',
    assessment_results_generate_report_parameters_action_effort: 'アクションの取組み',
    assessment_results_generate_report_parameters_target_completion_date: '目標完了日',
    assessment_results_generate_report_parameters_owner: 'オーナー',
    assessment_results_generate_report_parameters_target_date: '目標日',
    assessment_results_generate_report_parameters_priority: '優先度',
    assessment_results_generate_report_parameters_effort: '取組み',
    assessment_results_generate_report_parameters_observations: '観察結果',
    assessment_results_generate_report_parameters_summary_observation: '観察結果の概要',
    assessment_results_generate_report_parameters_summary_observations: '観察結果の概要',
    assessment_results_generate_report_parameters_category: 'カテゴリー',
    assessment_results_generate_report_parameters_participant: '参加者',
    assessment_results_generate_report_parameters_comment: 'コメント',
    assessment_results_generate_report_parameters_comments: 'コメント',
    assessment_results_generate_report_parameters_polling_responses: '投票結果',
    assessment_results_generate_report_parameters_vote: '票',
    assessment_results_generate_report_parameters_role: '役割',
    assessment_results_generate_report_parameters_response: 'レスポンス',
    assessment_results_generate_report_parameters_rating: '格付け',
    assessment_results_generate_report_parameters_question: '質問',
    assessment_results_generate_report_parameters_question_number: '番号',
    assessment_results_generate_report_parameters_response_type: '回答',
    assessment_results_generate_report_parameters_rating_guide: '評価ガイド（可能な場合',
    assessment_results_generate_report_parameters_average_score: '平均スコア',
    assessment_results_generate_report_parameters_perspective_high_explanation:
        'あなたはすでに{}の面で非常に優れています。あなたの決定的な強みは{}にあり、' + 'そこではすでに十分な準備が整っています。',
    assessment_results_generate_report_parameters_perspective_low_explanation:
        '私たちは、{}について最初に多くの努力が必要と評価しています。' + 'クラウドの素晴らしい旅を始めるには、特に{}に注力すべきです。',
    assessment_results_generate_report_parameters_perspective_second_low_explanation:
        'さらに、｛｝の点では、｛｝も注意を払われるべきなので、' + 'さっそく始めましょう。',
    assessment_results_generate_report_parameters_workstream_high_explanation: 'すでに｛｝の点で優れています。この調子で継続してください。',
    assessment_results_generate_report_parameters_workstream_low_explanation:
        'クラウド移行を着実に成功させるために、｛｝についての改善に重点を置いてください。',
    assessment_results_generate_report_parameters_workstream_second_low_explanation: 'さらに、｛｝の点で自分の能力を高めることを意識してください。',
    assessment_results_generate_report_parameters_response_type_rating: '格付け',
    assessment_results_generate_report_parameters_response_type_number: '数',
    assessment_results_generate_report_parameters_response_type_yes_no: 'はい/いいえ',
    assessment_results_generate_report_parameters_response_type_text: '文章',
    assessment_results_generate_report_parameters_response_type_date_value: '日付',
    assessment_results_generate_report_parameters_response_type_single_selection: '単一選択',
    assessment_results_generate_report_parameters_response_type_multi_selection: '複数選択',
    assessment_results_generate_report_parameters_score: 'スコア',
    assessment_results_generate_report_parameters_scores: 'スコア',
    assessment_results_generate_report_low_ams_score:
        'フィットスコアから判断すると、AMSは依然として興味深い選択肢かもしれません。' +
        'このオプションについて詳しく知りたい場合は、AWS アカウントチームにお問い合わせください。',
    assessment_results_generate_report_medium_ams_score:
        'フィットスコアから判断すると、クラウド環境の運用にはAMSが適しているようです。' +
        'このオプションについて詳しく知りたい場合は、AWS アカウントチームにお問い合わせください。',
    assessment_results_generate_report_high_ams_score:
        'フィットスコアから判断すると、AWS環境の管理に役立つ選択肢としては、AMSが推奨されているようです。' +
        'このオプションについて詳しく知りたい場合は、AWS アカウントチームにお問い合わせください。',
    assessment_results_generate_report_facilitated_by: 'Facilitated By',

    // Report customization page
    report_customization_loading_header_message: 'カスタマイズを読み込む',
    report_customization_failed_to_load_header_message: '既存のデータを取得できませんでした-カスタマイズは引き続き設定できます',
    report_customization_failed_to_save_header_message:
        'そのファイルに潜在的な悪質なコンテンツが検出されました。信頼できるソースからアップロードしてみてください。',
    report_customization_help_panel_header: 'レポートテンプレート',
    report_customization_help_panel_content:
        'あなたとあなたの組織によって生成されたレポートには、このデータが使用されます。これらを設定しない場合、AWS のデフォルトが使用されます。',
    report_customization_reload: '再再読み込み',
    report_customization_save: '保存する',
    failed_to_save_header_message: 'データを保存中にエラーが発生しました。',
    report_customization_page_header: 'レポートテンプレート',
    report_customization_organization_name_field: '組織名',
    report_customization_organization_name_placeholder: 'ACME Corp',
    report_customization_logo_field: 'ロゴ',
    report_customization_existing_logo_text: 'ロゴはすでにアップロードされています',
    report_customization_upload: 'ファイルを選択',
    report_customization_upload_constraint_text: '使用可能なファイルタイプ: .jpg, .png 最大ファイルサイズ: ',
    report_customization_upload_error_text_too_big: 'ファイルが上限を超えています。最大ファイルサイズ: ',
    report_customization_upload_error_text_wrong_type: 'ファイルは.jpg または.png である必要があります',
    report_customization_kb: 'KB',

    // Prescribed actions customization
    prescribed_actions_customization_prescribed_actions: '所定アクション',
    prescribed_actions_customization_choose_assessment_type: '評価タイプを選択してください',
    prescribed_actions_customization_add_custom_action: 'カスタムアクションを追加',
    prescribed_actions_customization_merge_in_default_actions: 'デフォルトアクションで結合',
    prescribed_actions_customization_confirm_delete_customized_prescribed_action: 'この所定アクションを完全に削除しますか？',
    prescribed_actions_customization_updating_customized_prescribed_actions: 'カスタマイズされた所定アクションの更新',
    prescribed_actions_customization_customized_prescribed_actions_saved: 'カスタマイズされた所定アクションが保存されました',
    prescribed_actions_customization_customized: 'カスタマイズされました',
    prescribed_actions_customization_error_unable_to_load_any_templates: '評価テンプレートを読み込めません。',
    prescribed_actions_customization_error_loading_templates_metadata: '評価テンプレートのメタデータを読み込んでいるときにエラーが発生しました。',
    prescribed_actions_customization_error_loading_template_prescribed_actions:
        '評価テンプレートから所定アクションを読み込み中にエラーが発生しました。',
    prescribed_actions_customization_error_loading_customized_prescribed_actions:
        'カスタマイズされた所定アクションをロード中にエラーが発生しました。',
    prescribed_actions_customization_error_updating_customized_prescribed_actions: 'カスタマイズされた所定アクションの更新中にエラーが発生しました。',

    // Org overview page
    org_overview_overview: '概要',
    org_overview_assessments: '評価',
    org_overview_page_size_preference_title: '1ページあたりの評価数',
    org_overview_filter_placeholder: '評価を探す',
    org_overview_no_assessments_created: '評価は作成されませんでした',
    org_overview_no_assessments_created_subtitle: '作成したアセスメントはここに表示されます',
    org_overview_no_assessments_found: '評価が見つかりません',
    org_overview_try_different_search: '別の検索を試してください',
    org_overview_help_panel_header: '組織概要',
    org_overview_help_panel_content: 'このページには、組織が完了したすべての評価の概要が表示されます。',
    org_overview_loading_overview: '概要読み込み',
    org_overview_no_overview_found: '概要が見つかりません',
    org_overview_error_loading_overview: '概要を取得できませんでした',

    // Assessment workshop filters
    assessment_workshop_filters_answered: '回答済み',
    assessment_workshop_filters_header: 'ワークショップフィルター',
    assessment_workshop_filters_live_event: 'ライブイベント',
    assessment_workshop_filters_polling_results_description: '次のタイプを表示します。',
    assessment_workshop_filters_polling_results_header: '投票結果',
    assessment_workshop_filters_pre_event: 'プレイベント',
    assessment_workshop_filters_questions_description: '次のタイプを表示します。',
    assessment_workshop_filters_questions_header: '質問',
    assessment_workshop_filters_unanswered: '未回答',

    assessment_snapshot_add_snapshot: 'スナップショットの追加',
    assessment_snapshot_compare_snapshots: 'スナップショットの比較',
    assessment_snapshot_create_snapshot: 'スナップショットの作成',
    assessment_snapshot_create_snapshot_error: 'スナップショットの作成でエラーが発生しました。',
    assessment_snapshot_create_snapshot_error_same_name: 'すでに同じ名前のスナップショットがあります。',
    assessment_snapshot_create_snapshot_success: 'スナップショットの作成に成功しました。',
    assessment_snapshot_manage_snapshot: 'スナップショットの管理',
    assessment_snapshot_snapshots: 'スナップショット',
    assessment_snapshot_view_snapshot: 'スナップショットの表示',

    // Feedback
    feedback_dropdown_submit_issue: '問題を提出する',
    feedback_dropdown_feature_request: '機能リクエスト',
    feedback_loading_assessment_types: '評価タイプを読み込み中...',
    feedback_dialog_title_placeholder: 'ここにタイトルを入力',
    feedback_dialog_bug_issue_type_placeholder: '問題の種類を選択してください',
    feedback_dialog_feature_request_type_placeholder: 'リクエストタイプを選択してください',
    feedback_dialog_feature_request_category_placeholder: 'リクエストカテゴリを選択してください',
    feedback_dialog_assessment_type_placeholder: '評価タイプを選択してください',
    feedback_dialog_assessment_description_placeholder: '評価の説明をここに入力してください',
    feedback_dialog_request_description_placeholder: 'ここに説明を入力',
    feedback_dialog_expected_behavior_placeholder: '予想される動作をここに入力してください',
    feedback_dialog_further_context_placeholder: 'その他のオプションコンテキストをここに入力してください',
    feedback_submit_request: 'リクエストを送信中...',
    feedback_submit_bug_success: '新しい問題が正常に送信されました',
    feedback_submit_bug_fail: '新しい問題の送信に失敗しました ',
    feedback_submit_bug_dialog_submit_button: '問題を送信',
    feedback_submit_bug_dialog_header: 'バグを送信',
    feedback_submit_bug_dialog_title: 'タイトル',
    feedback_submit_bug_dialog_issue_type: '問題タイプ',
    feedback_submit_bug_dialog_description_of_issue: '説明',
    feedback_submit_bug_dialog_expected_behavior: '期待される動作',
    feedback_submit_bug_dialog_assessment_type: '評価タイプ',
    feedback_submit_bug_dialog_assessment_name: '評価の説明 (オプション)',
    feedback_submit_bug_dialog_troubleshooting_label: 'トラブルシューティングガイド',
    feedback_submit_bug_dialog_troubleshooting_hint: 'バグを送信する前に、一般的な問題についてトラブルシューティングガイドを参照してください。',
    feedback_submit_feature_request_success: '新機能のリクエストは正常に送信されました',
    feedback_submit_feature_request_fail: '新機能のリクエストを送信できませんでした',
    feedback_submit_feature_request_dialog_header: '機能をリクエストする',
    feedback_submit_feature_request_dialog_title: 'タイトル',
    feedback_submit_feature_request_dialog_type_of_request: 'リクエストのタイプ',
    feedback_submit_feature_request_dialog_assessment_type: '評価タイプ',
    feedback_submit_feature_request_dialog_assessment_name: '評価の説明 (オプション)',
    feedback_submit_feature_request_dialog_category: 'カテゴリー',
    feedback_submit_feature_request_dialog_request_description: 'ユーザーとして私がしたいこと',
    feedback_submit_feature_request_dialog_request_outcome: '私にとっての価値',
    feedback_submit_feature_request_dialog_request_more_details: 'その他のコンテキスト (オプション)',
    feedback_submit_feature_request_dialog_submit_button: 'リクエストを送信',
    feedback_submit_feature_request_category_newfeature: '新機能',
    feedback_submit_feature_request_category_newfeature_description: 'ツール/プラットフォームの機能の変更',
    feedback_submit_feature_request_category_content: 'コンテンツ',
    feedback_submit_feature_request_category_content_description: '機能的な変更を必要としないコンテンツへの変更',
    feedback_submit_feature_request_category_access: 'アクセス',
    feedback_submit_feature_request_category_access_description: 'ダッシュボードまたは評価へのアクセスのリクエスト',
    feedback_submit_feature_request_category_assistance: 'アシスタンス',
    feedback_submit_feature_request_category_assistance_description: '既存のツール/評価の使用に関するヘルプ',
    feedback_submit_feature_request_category_onboarding: 'オンボーディング',
    feedback_submit_feature_request_category_onboarding_description: '新しい評価タイプの導入',
    feedback_submit_feature_request_category_feedback: 'フィードバック',
    feedback_submit_feature_request_category_feedback_description: 'フィードバック、改善、称賛を提供する',

    // Manage templates
    manage_templates_requester_guide_link:
        'https://w.amazon.com/bin/view/AWS/Teams/Proserve/Migration/AWSAssessmentTool/A2TRequesterGuide/#HStandaloneAssessmentsOnboardingProcess',

    manage_templates_template_template: 'テンプレート',
    manage_templates_template_templates: 'テンプレート',
    manage_templates_template_type: 'タイプ',
    manage_templates_template_name: '名前',
    manage_templates_template_version: 'バージョン',
    manage_templates_template_status: 'ステータス',
    manage_templates_template_created_at: '作成日',
    manage_templates_template_statuses_primary: 'プライマリテンプレート',
    manage_templates_template_statuses_primary_popover_content: '新しく作成されたアセスメントにはプライマリテンプレートが使用されます。',
    manage_templates_template_statuses_published: '公開済み',
    manage_templates_template_statuses_published_for_version: '公開対象 ', // version should be appended
    manage_templates_template_statuses_published_for_version_popover_content:
        'このテンプレートは既存の評価で使用されます。新しい評価では使用されません。',
    manage_templates_template_statuses_latest_draft_for_version: '最新ドラフト ', // version should be appended
    manage_templates_template_statuses_draft: 'ドラフト',

    manage_templates_template_question_types_text: 'テキスト',
    manage_templates_template_question_types_number: '番号',
    manage_templates_template_question_types_yes_no: 'はい/いいえ',
    manage_templates_template_question_types_rating: '格付け',
    manage_templates_template_question_types_single_selection: 'シングルセレクト',
    manage_templates_template_question_types_multi_selection: '複数選択',
    manage_templates_template_question_types_date: '日付',

    manage_templates_table_page_title: 'テンプレートを管理',
    manage_templates_table_preferences: '環境設定',
    manage_templates_table_templates_per_page: '1ページあたりのテンプレート数',
    manage_templates_table_loading_templates: 'テンプレートを読み込み中...',
    manage_templates_table_no_match: '検索条件に一致するテンプレートはありません',
    manage_templates_table_empty: 'テンプレートが見つかりません',
    manage_templates_table_error_loading_templates: 'テンプレートを読み込み中にエラーが発生しました',
    manage_templates_table_filter_status: 'フィルターステータス',
    manage_templates_table_all_templates: 'すべてのテンプレート',
    manage_templates_table_published_and_latest_drafts: '公開済みおよび最新の下書き',
    manage_templates_table_help_panel_header: 'テンプレートを管理',
    manage_templates_table_help_panel_content:
        '今のところ、このページを使用して「テンプレートの作成」ウィザードを開始できます。' +
        'そこで、評価の Excel 版をアップロードして、ツールでどのように表示されるかをプレビューできます。' +
        '満足したら、それをアップロードして SIM でオンボーディングチケットを作成できます。',
    manage_templates_table_help_panel_link_text: 'Excel の作成方法については、A2T リクエスターガイドをご覧ください。',

    manage_templates_actions_find_template: 'テンプレートを探す',
    manage_templates_actions_create_template: 'テンプレートを作成',
    manage_templates_actions_finalize_template: 'ファイナライズ',
    manage_templates_actions_create_draft: '下書きを作成',
    manage_templates_actions_publish_for_version: 'バージョン用に公開',
    manage_templates_actions_set_primary_template: 'プライマリテンプレートを設定',
    manage_templates_actions_errors_error_creating_draft: '下書きの作成中にエラーが発生しました',
    manage_templates_actions_errors_error_publishing_template: 'テンプレートの公開中にエラーが発生しました',
    manage_templates_actions_errors_no_template_selected: 'テンプレートが選択されていません',
    manage_templates_actions_errors_template_already_published: 'テンプレートはすでに公開されています',
    manage_templates_actions_errors_already_primary_template: 'すでにプライマリテンプレートです',
    manage_templates_actions_errors_cannot_create_draft_from_draft: '別のドラフトからドラフトを作成することはできません',

    manage_templates_publish_template_confirmation_header: '公開を確認',
    manage_templates_publish_template_confirmation_body:
        '公開は即有効になります。<ul>' +
        '<li><b>「バージョン用に公開」</b> を選択すると、下書きが特定のバージョンのメインテンプレートになります。これは既存の評価にのみ影響します。</li>' +
        '<li><b>「メインテンプレートを設定」</b> を選択すると、下書きが評価タイプ全体のメインテンプレートになります。</li>' +
        '<li>この新しいテンプレートは、公開後に作成されるすべての評価で使用されます。</li>' +
        '<li>プライマリテンプレートを上書きする場合、どちらのオプションでもドラフトが新しいプライマリテンプレートになります。</li>' +
        '</ul>このテンプレートを公開してもよろしいですか?',

    manage_templates_create_assessment_type_label: '評価タイプを作成',
    manage_templates_create_assessment_type_description:
        '評価タイプの頭字語と言語を指定してください。言語は後で追加できます。<b>公開する準備ができたら、テンプレートを A2T 製品チームと共有し、レビュー用の機能リクエストチケットを提出します。</b>',
    manage_templates_create_assessment_type_acronym_field: '評価タイプの略語',
    manage_templates_create_assessment_type_invalid_acronym: '頭字語が無効です',
    manage_templates_create_assessment_type_available: '利用可能',
    manage_templates_create_assessment_type_not_available: 'ご利用いただけません',
    manage_templates_create_assessment_type_initial_locale_field: '初期テンプレート言語',

    manage_templates_wizard_upload_template_upload_excel_template: 'Excel テンプレートをアップロード',
    manage_templates_wizard_upload_template_dropzone_text: 'ここにファイルをドロップ',
    manage_templates_wizard_upload_template_upload_button_text: 'テンプレートをアップロード',
    manage_templates_wizard_upload_template_upload_info_text1: 'インポートを正常に行うには、以下のように質問してください。 ',
    manage_templates_wizard_upload_template_upload_info_linked_text: 'A2T Excel テンプレート',
    manage_templates_wizard_upload_template_upload_info_text2: '.',
    manage_templates_wizard_upload_template_failed_to_parse_template: 'Excel テンプレートを解析できませんでした',
    manage_templates_wizard_upload_template_template_successfully_uploaded: 'テンプレートは正常にインポートされました。',
    manage_templates_wizard_upload_template_no_template_uploaded:
        'テンプレートがアップロードされていません。次に進む前にテンプレートをアップロードしてください。',
    manage_templates_wizard_upload_template_help_panel_header: 'テンプレートをアップロード',
    manage_templates_wizard_upload_template_help_panel_content:
        'A2T Excel テンプレートをここにアップロードしてください。後で、評価タイプのプレビューが表示されます。Excel テンプレートを変更して再アップロードし、プレビューをもう一度確認します。 ' +
        '問題がなければ、SIM を使用して評価タイプを送信して審査を受けることができます。',
    manage_templates_wizard_enter_details_title: '詳細を入力',
    manage_templates_wizard_enter_details_description: 'これらは後で変更できます',
    manage_templates_wizard_enter_details_details: '詳細',
    manage_templates_wizard_enter_details_template_name: 'テンプレート名',
    manage_templates_wizard_enter_details_template_name_placeholder: '移行準備評価 (MRA)',
    manage_templates_wizard_enter_details_template_name_constraint_text: '最大50文字',
    manage_templates_wizard_enter_details_template_name_error_text: '名前が長すぎます',
    manage_templates_wizard_enter_details_template_description: 'テンプレート説明',
    manage_templates_wizard_enter_details_template_description_placeholder: '移行準備評価 (MRA) はAWSのプロセスです...',
    manage_templates_wizard_enter_details_template_description_constraint_text: '最大200文字',
    manage_templates_wizard_enter_details_template_description_error_text: '説明が長すぎます',
    manage_templates_wizard_enter_details_template_acronym: 'テンプレートの略称',
    manage_templates_wizard_enter_details_template_acronym_placeholder: 'MRA2',
    manage_templates_wizard_enter_details_template_acronym_constraint_text: '略称はユニークで、10文字未満である必要があります',
    manage_templates_wizard_enter_details_template_acronym_text_too_long: '略称が長すぎます',
    manage_templates_wizard_enter_details_template_acronym_text_not_alphanumeric: '略称は英数字でなければなりません',
    manage_templates_wizard_enter_details_template_cannot_be_empty: 'フィールドを空にすることはできません',
    manage_templates_wizard_enter_details_template_must_fill_out_all_required_fields: '次に進む前に、すべての必須フィールドが入力されている',
    manage_templates_wizard_finalize_help_panel_header: 'テンプレートをファイナライズ',
    manage_templates_wizard_finalize_help_panel_content:
        'テンプレートに関する最後の詳細をすべて変更してください。満足したら、テンプレートを作成して、パーソナライズされたオンボーディングリンクを受け取ります。 ' +
        'このリンクを使用して、次のステップについて A2T チームに連絡するためのオンボーディングチケットを作成します。',
    manage_templates_wizard_finalize_no_template_imported:
        'テンプレートが見つかりません。[テンプレートの作成] ページ ([テンプレートの管理] の下) に戻り、テンプレートをアップロードします。',
    manage_templates_wizard_finalize_failed_to_upload:
        'テンプレートをアップロードできませんでした。このエラーが続く場合は、サービス管理者に連絡してください。',
    manage_templates_wizard_finalize_successful_upload_text: 'おめでとう!テンプレートを受け取りました。 ',
    manage_templates_wizard_finalize_successful_upload_linked_text:
        'この SIM テンプレートを使用してチケットを作成して、オンボーディングを進めてください。',
    manage_templates_wizard_finalize_copy_template: 'テンプレートをコピー',
    manage_templates_wizard_finalize_copy_template_success: 'テンプレートがコピーされました',
    manage_templates_wizard_finalize_copy_template_fail: 'テンプレートをコピーできませんでした',

    manage_templates_edit_error_loading_template: 'テンプレートをロード中にエラーが発生しました',
    manage_templates_edit_toggle: '編集の切り替え',
    manage_templates_edit_template: 'テンプレートを編集',
    manage_templates_edit_metadata: 'テンプレートメタデータの編集',
    manage_templates_edit_add_locale: '言語を追加',
    manage_templates_edit_select_locale_placeholder: '有効にする言語を選択してください',
    manage_templates_edit_request_language: 'お探しの言語がここにない場合は、A2T チームに機能リクエストを提出してください。',
    manage_templates_edit_modify_structure_button_label: '構造を変更',
    manage_templates_edit_modify_structure_sections_heading: 'セクション',
    manage_templates_edit_modify_structure_add_section: 'セクションを追加',
    manage_templates_edit_modify_structure_add_section_modal_content:
        'セクションを作成したら、新しいカテゴリをそのセクションに追加できます。新しいセクションを追加してもよろしいですか?',
    manage_templates_edit_modify_structure_delete_section: 'セクションを削除',
    manage_templates_edit_modify_structure_delete_section_warning: 'このセクションを削除してよろしいですか?<b>これは元に戻せません。</b>',
    manage_templates_edit_modify_structure_categories_heading: 'カテゴリー',
    manage_templates_edit_modify_structure_add_category: 'カテゴリを追加',
    manage_templates_edit_modify_structure_add_category_modal_content:
        'カテゴリを作成したら、そのカテゴリに新しい質問を追加できます。新しいカテゴリを追加してもよろしいですか?',
    manage_templates_edit_modify_structure_delete_category: 'カテゴリを削除',
    manage_templates_edit_modify_structure_delete_category_warning: 'このカテゴリを削除してよろしいですか?<b>これは元に戻せません。</b>',
    manage_templates_edit_modify_structure_questions_heading: '質問',
    manage_templates_edit_modify_structure_change_question_type: '質問タイプを変更',
    manage_templates_edit_modify_structure_add_question: '質問を追加',
    manage_templates_edit_modify_structure_delete_question: '質問を削除',
    manage_templates_edit_modify_structure_delete_question_warning: 'この質問を削除してよろしいですか?<b>これは元に戻せません。</b>',
    manage_templates_edit_modify_structure_button_disabled_reasons_no_section: 'セクションが見つかりません',
    manage_templates_edit_modify_structure_button_disabled_reasons_delete_categories_first:
        'セクションを削除するには、まずそのセクションのカテゴリをすべて削除してください',
    manage_templates_edit_modify_structure_button_disabled_reasons_cannot_delete_last_section: '最後のセクションは削除できません',
    manage_templates_edit_modify_structure_button_disabled_reasons_no_category: 'カテゴリが見つかりません',
    manage_templates_edit_modify_structure_button_disabled_reasons_delete_questions_first:
        'カテゴリを削除するには、まずそのカテゴリのすべての質問を削除してください',
    manage_templates_edit_modify_structure_button_disabled_reasons_no_question: '質問が見つかりません',
    manage_templates_edit_modify_structure_button_disabled_reasons_navigate_to_last_question: '質問を追加するには、カテゴリの最後の質問に移動します',

    manage_templates_editable_fields_supports_html: 'このフィールドは HTML をサポートしています',
    manage_templates_editable_fields_assessment_help_panel_category_context: 'カテゴリコンテキスト',
    manage_templates_editable_fields_assessment_help_panel_prompt_context: 'プロンプトコンテキスト',

    manage_templates_update_template_info_link_text: 'テンプレート情報を更新',
    manage_templates_update_template_info_template_name: 'テンプレート名',
    manage_templates_update_template_info_delivery_guidance_link: '配送ガイダンスリンク',
    manage_templates_update_template_info_delivery_guidance_help_context: '配信ガイダンスヘルプコンテキスト',
    manage_templates_update_template_info_help_panel_header: 'テンプレート情報を更新',
    manage_templates_update_template_info_help_panel_content:
        '<h3>テンプレート名</h3>評価タイプの名前。これは、ユーザーが作成するアセスメントのタイプを選択するときに表示されます。<br/>' +
        '<h3>配送ガイダンスリンク</h3>評価タイプとともに表示されるリンクテキスト。例: ' +
        "<code>&lt;a href='https://more-info-link' target='_blank' rel='noopener noreferrer'&gt;サンプル評価提供ガイド&lt;/a&gt;</code>" +
        '<h3>配信ガイダンスヘルプコンテキスト</h3>これは、ユーザーが評価タイプカードを選択すると、ヘルプパネルに表示されます。',

    manage_templates_configure_template_link_text: 'テンプレートを設定',
    manage_templates_configure_template_error_updating_configuration: '設定の更新中にエラーが発生しました',
    manage_templates_configure_template_find_setting: '検索設定',
    manage_templates_configure_template_name: '名前',
    manage_templates_configure_template_description: '説明',
    manage_templates_configure_template_value: '値',
    manage_templates_configure_template_help_panel_header: 'テンプレートを設定',
    manage_templates_configure_template_help_panel_content:
        'このページを使用してテンプレートを設定します。変更は、ドラフトが公開された後に有効になります。',

    manage_templates_configure_template_editable_defaults_enable_pre_event_name: 'イベント前アンケートを有効にする',
    manage_templates_configure_template_editable_defaults_enable_pre_event_description: 'ワークショップの前に顧客に質問を送ることができます',
    manage_templates_configure_template_editable_defaults_enable_live_polling_name: 'ライブポーリングを有効にする',
    manage_templates_configure_template_editable_defaults_enable_live_polling_description: 'ワークショップ中に顧客から投票を集める',
    manage_templates_configure_template_editable_defaults_rating_na_enabled_name: 'NAレーティングを有効にする',
    manage_templates_configure_template_editable_defaults_rating_na_enabled_description: 'レーティング質問にNAオプションを有効にします',
    manage_templates_configure_template_editable_defaults_snapshots_disabled_name: 'スナップショットを無効にする',
    manage_templates_configure_template_editable_defaults_snapshots_disabled_description: 'スナップショット機能を無効にします',
    manage_templates_configure_template_editable_defaults_radar_disabled_name: 'レーダーチャートを無効にする',
    manage_templates_configure_template_editable_defaults_radar_disabled_description: 'レビューページとレポートからレーダーチャートを削除します',
    manage_templates_configure_template_editable_defaults_heatmap_disabled_name: 'ヒートマップを無効にする',
    manage_templates_configure_template_editable_defaults_heatmap_disabled_description: 'レビューすべてページのヒートマップグラフを無効にします',
    manage_templates_configure_template_editable_defaults_score_bar_chart_disabled_name: 'バーチャートを無効にする',
    manage_templates_configure_template_editable_defaults_score_bar_chart_disabled_description: 'レビューすべてページのバーチャートを無効にします',
    manage_templates_configure_template_editable_defaults_ppt_disabled_name: 'PowerPointレポートを無効にする',
    manage_templates_configure_template_editable_defaults_ppt_disabled_description: 'レポート生成ページからPowerPointオプションを削除します',
    manage_templates_configure_template_editable_defaults_word_disabled_name: 'Wordレポートを無効にする',
    manage_templates_configure_template_editable_defaults_word_disabled_description: 'レポート生成ページからWordオプションを削除します',
    manage_templates_configure_template_editable_defaults_excel_disabled_name: 'Excelレポートを無効にする',
    manage_templates_configure_template_editable_defaults_excel_disabled_description: 'レポート生成ページからExcelオプションを削除します',

    manage_templates_share_share_template: 'テンプレートを共有',
    manage_templates_share_error_sharing_template: 'テンプレート共有エラー',
    manage_templates_share_owner: 'オーナー',
    manage_templates_share_coowners: '共同所有者',
    manage_templates_share_add_coowner: '共同所有者を追加',
    manage_templates_share_a2t_product_team: 'A2T プロダクトチーム',
    manage_templates_share_locale_managers: 'ローカリゼーションマネージャー',
    manage_templates_share_add_locale_manager: 'ローカルマネージャーを追加',
    manage_templates_share_assessment_creators: '評価作成者',
    manage_templates_share_add_assessment_creator: '評価作成者を追加',
    manage_templates_share_all_partners: 'すべてのパートナー',
    manage_templates_share_pending_save: '保存待ち',
    manage_templates_share_share_template_help_panel_header: 'テンプレートを共有',
    manage_templates_share_share_template_help_panel_content:
        '<h3>オーナー</h3>テンプレートの所有者。これを変更する必要がある場合は、A2T チームにお問い合わせください。<br/>' +
        '<h3>共同所有者</h3>テンプレートに対する完全な権限を持つテンプレートマネージャー。' +
        '新しい評価タイプを公開する場合は、テンプレートを A2T 製品チームと共有し、レビューをリクエストするためのチケットを提出してください。<br/>' +
        '<h3>ロケールマネージャー</h3>ロケールマネージャーは、指定されたロケールのテキストをローカライズできます。<br/>' +
        '<h3>評価作成者</h3>あなたのアセスメントタイプからアセスメントを作成できるすべての人。',
    manage_templates_template_publication_info_modal_header: 'テンプレートの公開',
    manage_templates_template_publication_info_modal_content:
        'アセスメントタイプを初めて公開するには、A2Tプロダクトチームによるレビューを受ける必要があります。以下の手順に従ってください:<br/>' +
        '<ol><li>チェックボックスにチェックを入れてテンプレートをA2Tプロダクトチームと共有し、「保存」をクリックしてください</li>' +
        '<li>チケットを作成してください (トップメニューの「フィードバック - 機能リクエスト」)</li>' +
        '<li>プロダクトオーナーからの懸念事項に対処してください</li>' +
        '<li>プロダクトオーナーがアセスメントタイプを公開します</li></ol>' +
        '今後の編集については、A2Tプロダクトチームによるレビューは必要ありません。',
};
