import {
    Assessment,
    AssessmentResourceAction,
    CreateAssessmentPermissionInput,
    DeleteAssessmentPermissionInput,
    ResourcePermission,
} from '@amzn/awscat-aws-assessment-service-typescript-client';
import { UserInfo, UserType } from '@amzn/awscat-portal-authentication-library';
import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';
import {
    Alert,
    Box,
    Button,
    ColumnLayout,
    Container,
    FormField,
    Header,
    Input,
    Modal,
    Select,
    SpaceBetween,
    TextContent,
} from '@amzn/awsui-components-react';
import { IsEmail, ValidationError, isEmail, validate } from 'class-validator';
import React, { useCallback, useEffect, useState } from 'react';

import ShareTable, { Change, ShareViewModel } from './PermissionsTable';
import ShareInvitationConfirmationDialog from './ShareInvitationConfirmationDialog';
import ShareMultiUserSelectDialog from './ShareMultiUserSelectDialog';
import a2sClient from '../../../api/a2s/A2SClient';
import DataCollectionClient, { ExistingQuestionnaire } from '../../../api/data-collection/DataCollectionClient';
import { AppLabels } from '../../../common/AppLabels';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import Constants from '../../../common/Constants';
import { logger } from '../../../common/Logger';
import { extractAmazonEmailFromUserId, getUserIdFromAmazonUserEmail } from '../../../common/Utils';
import { errorLookup } from '../../../common/ValidatorUtils';
import rumClient from '../../../common/monitoring/RumClient';
import { Action, ActionType } from '../AssessmentActions';
import { AssessmentViewModel } from '../AssessmentViewModel';
import { AssessmentPermissionViewModel } from '../PermissionViewModel';

export class ShareAssessmentUserInput {
    @IsEmail(undefined, {
        message: 'assessment_share_error_invalid_username',
    })
    userEmail: string;
}

type Conclusion = {
    newPermissions: AssessmentPermissionViewModel[];
    changedPermissions: AssessmentPermissionViewModel[];
    deletedPermissions: AssessmentPermissionViewModel[];
};

interface Props {
    assessment: AssessmentViewModel;
    assessmentActionDispatch: React.Dispatch<Action>;
}

/**
 * Delete Permissions associated with Assessments
 */
const deletePermission = async (assessment: AssessmentViewModel, p: AssessmentPermissionViewModel) => {
    let apiResult = null;
    if (p.invitationId) {
        // This is a pending invitation
        apiResult = await a2sClient?.deleteAssessmentPermissionInvitation(p.invitationId);
    } else {
        const deleteInput: DeleteAssessmentPermissionInput = {
            assessmentId: assessment.id,
            userId: p.userId,
            action: p.action,
        };
        apiResult = await a2sClient?.deleteAssessmentPermission(deleteInput);
    }

    if (apiResult?.errors) {
        throw new Error(apiResult.errors.toString());
    }
};

/**
 * Add Permissions to associate with Assessments
 */
const createPermission = async (assessment: AssessmentViewModel, p: AssessmentPermissionViewModel) => {
    const createInput: CreateAssessmentPermissionInput = {
        assessmentId: assessment.id,
        action: p.action,
        assessmentLink: `https://${window.location.host}/assessments/${assessment.id}`,
        inviteeEmail: p.inviteeEmail,
        userId: p.userId,
    };
    const apiResult = await a2sClient?.createAssessmentPermission(createInput);
    if (apiResult?.errors) {
        throw new Error(apiResult.errors.toString());
    }
};

const getQuestionnaire = async (assessmentId: string): Promise<ExistingQuestionnaire | undefined> => {
    const sourceResourceId = `awscat:a2s:${assessmentId}`;
    const questionnaires = await DataCollectionClient.getQuestionnaires(sourceResourceId);
    const existingQuestionnaire = questionnaires.length ? questionnaires[0] : undefined;
    return existingQuestionnaire;
};

const updateQuestionnaire = async (questionnaireId: string, owners: string[]): Promise<any> => {
    const updatedQuestionnaire: Partial<ExistingQuestionnaire> = {
        owners,
    };
    const result = await DataCollectionClient.patchQuestionnaire(questionnaireId, updatedQuestionnaire);
    return result;
};

/**
 * Remove Permissions associated with Pre-Event Questionnaire
 */
const removePreEventPermission = async (assessment: AssessmentViewModel, p: AssessmentPermissionViewModel) => {
    const existingQuestionnaire = await getQuestionnaire(assessment.id);
    if (existingQuestionnaire) {
        const email = p.userEmail ?? p.inviteeEmail ?? extractAmazonEmailFromUserId(p.userId);
        const newOwners = existingQuestionnaire.owners.filter((owner) => owner !== email);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const result = await updateQuestionnaire(existingQuestionnaire.id, newOwners);
        return;
    }
};

/**
 * Add Permissions to associate Pre-Event Questionnaire
 */
const addPreEventPermission = async (assessment: AssessmentViewModel, p: AssessmentPermissionViewModel) => {
    const existingQuestionnaire = await getQuestionnaire(assessment.id);
    if (existingQuestionnaire) {
        const email: string = p.userEmail ?? p.inviteeEmail ?? extractAmazonEmailFromUserId(p.userId);
        const newOwners: string[] = existingQuestionnaire.owners || []; // just in case it's not initialized
        newOwners.push(email);
        existingQuestionnaire.owners = [...new Set(newOwners)]; // remove any potential duplicates
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const result = await updateQuestionnaire(existingQuestionnaire.id, newOwners);
        return;
    }
};

/**
 * Remove Permissions associated with both Assessments and Pre-Event Questionnaire
 */
export const deletePermissions = async (assessment: AssessmentViewModel, deletedPermissions: AssessmentPermissionViewModel[]): Promise<void> => {
    for (const p of deletedPermissions) {
        await deletePermission(assessment, p);
        await removePreEventPermission(assessment, p);
    }
};

/**
 * Add Permissions to associate with both Assessments and Pre-Event Questionnaire
 */
export const createPermissions = async (assessment: AssessmentViewModel, createdPermissions: AssessmentPermissionViewModel[]): Promise<void> => {
    for (const p of createdPermissions) {
        await createPermission(assessment, p);
        await addPreEventPermission(assessment, p);
    }
};

const ShareAssessmentDialog = (props: Props & AuthContextInterface & AppLabelsContextInterface): JSX.Element => {
    const appLabels: AppLabels = props.appLabels;
    const [newUserAliasOrEmail, setUserAliasOrEmail] = useState('');
    const [newPermission, setNewPermission] = useState<AssessmentResourceAction>(AssessmentResourceAction.VIEW);
    const [updateInProgress, setUpdateInProgress] = useState(false);
    const [errorAlertVisible, setErrorAlertVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
    const getValidationErrorText = errorLookup<ShareAssessmentUserInput>(validationErrors);
    const getErrorText = (attribute: keyof ShareAssessmentUserInput): string | undefined => {
        const validationErrorText = getValidationErrorText(attribute);
        return validationErrorText ? appLabels.intlGet(validationErrorText) : '';
    };
    const [inviteUserEmail, setInviteUserEmail] = useState<string>('');
    const [usersToSelectFrom, setUsersToSelectFrom] = useState<UserInfo[]>([]);

    // This tracks the contents of the table
    const [permissions, setPermissions] = useState<ShareViewModel[]>([]);

    const validUserName = newUserAliasOrEmail.length > 0;

    const updatePermissions = async (
        newPermissions: AssessmentPermissionViewModel[],
        changedPermissions: AssessmentPermissionViewModel[],
        deletedPermissions: AssessmentPermissionViewModel[]
    ) => {
        const assessment = props.assessment;

        if (logger.level === 'DEBUG' || logger.level === 'VERBOSE') {
            logger.debug('shareItemAsync()');
            logger.debug('newPermissions: ', newPermissions);
            logger.debug('changedPermissions: ', changedPermissions);
            logger.debug('deletedPermissions: ', deletedPermissions);
        }

        // 1. delete the deletedPermissions
        await deletePermissions(assessment, deletedPermissions);
        // 2. delete the changedPermissions
        await deletePermissions(assessment, changedPermissions);
        // 3. create the changedPermissions
        await createPermissions(assessment, changedPermissions);
        // 4. create the newPermissions
        await createPermissions(assessment, newPermissions);
    };

    const onConfirmShare = async (conclusion: Conclusion) => {
        setUpdateInProgress(true);
        try {
            await updatePermissions(conclusion.newPermissions, conclusion.changedPermissions, conclusion.deletedPermissions);
            // successfully shared assessment, reload tables
            props.assessmentActionDispatch({ type: ActionType.RELOAD_ASSESSMENTS });
        } catch (err: any) {
            rumClient.recordError(err);
            const errorMessage = `${err.errors[0]?.message || appLabels.assessment.share.error_update_permissions}`;
            const conclusionObject = JSON.stringify(conclusion);
            logger.info(`Error encountered while adding permissions: ${errorMessage}. Conclusion Object details: ${conclusionObject}`);
            setErrorMessage(errorMessage);
            setErrorAlertVisible(true);
        }
        setUpdateInProgress(false);
    };

    const cancelAction = useCallback(() => {
        props.assessmentActionDispatch({ type: ActionType.CANCEL });
    }, [props]);

    const resetAddUser = useCallback(() => {
        setUserAliasOrEmail('');
        setInviteUserEmail('');
        setUsersToSelectFrom([]);
        setNewPermission(AssessmentResourceAction.VIEW);
    }, []);

    const inviteUserAction = useCallback(async () => {
        let userEmail = newUserAliasOrEmail;
        if (!isEmail(newUserAliasOrEmail)) {
            const myEmail = props.auth.getUserInfo()?.email;
            const myEmailDomain = myEmail.split('@')[1];
            userEmail = `${newUserAliasOrEmail}@${myEmailDomain}`;
        }
        const newPerms = permissions.slice();
        newPerms.push({
            action: newPermission,
            userEmail,
            change: Change.Invite,
            userId: undefined,
            inviteeEmail: userEmail,
        });
        setPermissions(newPerms);
        resetAddUser();
    }, [newPermission, newUserAliasOrEmail, permissions, props.auth, resetAddUser]);

    const addSelectedUsersAction = useCallback(
        async (selectedUsers: UserInfo[]) => {
            const newPerms = permissions.slice();
            selectedUsers.forEach((u) => {
                // check if user permission already granted
                const userAlreadyHasPermission = permissions.find((p) => p.userId === u.userId && p.action === newPermission);
                if (!userAlreadyHasPermission) {
                    const userEmail = u.email;
                    newPerms.push({
                        action: newPermission,
                        userEmail,
                        change: Change.Add,
                        userId: u.userId,
                        inviteeEmail: userEmail,
                    });
                }
            });
            setPermissions(newPerms);
            resetAddUser();
        },
        [newPermission, permissions, resetAddUser]
    );

    const onAddUserPermission = useCallback(async () => {
        let userEmail = newUserAliasOrEmail;
        if (!isEmail(newUserAliasOrEmail)) {
            const myEmail = props.auth.getUserInfo()?.email;
            const myEmailDomain = myEmail.split('@')[1];
            userEmail = `${newUserAliasOrEmail}@${myEmailDomain}`;
        }
        const userInput = Object.assign(new ShareAssessmentUserInput(), { userEmail });
        const validationErrors = await validate(userInput);
        setValidationErrors(validationErrors);
        if (!validationErrors.length) {
            const userProfiles = await props.auth.searchUserInfoByEmail(encodeURIComponent(userEmail));
            const supportedUserProfiles = userProfiles.filter((u) => [UserType.AWS, UserType.PARTNER].includes(u.type));
            if (supportedUserProfiles?.length === 0) {
                // unable to find user from cat portal user pool
                const userId = getUserIdFromAmazonUserEmail(userEmail);
                if (userId) {
                    addSelectedUsersAction([
                        {
                            userId,
                            email: userEmail,
                            displayName: userEmail,
                            organizationId: Constants.AWS_ORG_ID,
                        },
                    ]);
                } else {
                    // set invite user email and trigger confirm invitation dialog
                    setInviteUserEmail(userEmail);
                }
            } else if (supportedUserProfiles?.length === 1) {
                // found exactly one user
                addSelectedUsersAction(supportedUserProfiles);
            } else {
                setUsersToSelectFrom(supportedUserProfiles);
            }
        }
    }, [newUserAliasOrEmail, props.auth, addSelectedUsersAction]);

    useEffect(() => {
        // assessment permissions may have changed after assessment is initially loaded
        // reload permissions again
        const loadAssessmentPermissions = async (): Promise<(ResourcePermission | null)[]> => {
            if (a2sClient) {
                const response = await a2sClient.getAssessments(
                    { assessments: [{ id: props.assessment.id }] },
                    {
                        permissions: true,
                    }
                );
                const results = response.data?.getAssessments;
                const assessment: Assessment | null = results?.items ? results?.items[0] : null;
                return assessment?.permissions || [];
            }
            return [];
        };

        const loadUserProfiles = async () => {
            const newPermissions = await loadAssessmentPermissions();
            const userIdsWithPermission: string[] = [];
            newPermissions.forEach((p) => {
                if (p?.userId) {
                    userIdsWithPermission.push(p.userId);
                }
            });
            const userProfiles = await props.auth.getUserInfoList(userIdsWithPermission);
            const shareViewModel: ShareViewModel[] = [];
            newPermissions.forEach((p) => {
                if (p) {
                    const user = userProfiles.find((u) => u.userId === p?.userId);
                    // User invitee email if permission is still pending (e.g. user have not accepted invitation)
                    // Otherwise, extract from profile email or amazon userId
                    const name = user?.name || user?.displayName;
                    const orgName = user?.organizationName || user?.organizationId;
                    const userName = `${name} (${orgName})`;
                    const userEmailOrUsername = p.isPending ? p.inviteeEmail : userName;
                    shareViewModel.push({
                        userId: p.userId ?? '',
                        userEmail: userEmailOrUsername,
                        action: p.action,
                        change: p.isPending ? Change.Invited : Change.None,
                        invitationId: p.invitationId,
                    });
                }
            });
            setPermissions(shareViewModel);
        };
        loadUserProfiles();
    }, [props.assessment.id, props.auth]);

    return (
        <Modal
            visible={true}
            header={`${appLabels.assessment.share.share}: '${props.assessment.description}'?`}
            closeAriaLabel={appLabels.user_actions.close_dialog}
            onDismiss={cancelAction}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={cancelAction}>
                            {appLabels.user_actions.cancel}
                        </Button>
                        <Button
                            variant='primary'
                            loading={updateInProgress}
                            onClick={() =>
                                onConfirmShare({
                                    newPermissions: permissions.filter((p) => p.change === Change.Add || p.change === Change.Invite),
                                    changedPermissions: permissions.filter((p) => p.change === Change.Update),
                                    deletedPermissions: permissions.filter((p) => p.change === Change.Delete),
                                })
                            }
                            disabled={permissions.filter((p) => p.change !== Change.None).length === 0}
                        >
                            {appLabels.user_actions.apply}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <ColumnLayout borders='horizontal'>
                <div>
                    <TextContent>
                        <h4>{appLabels.assessment.share.current_permissions}</h4>
                    </TextContent>
                    <ShareTable setPermissions={setPermissions} permissions={permissions} />
                </div>
                <Container
                    header={
                        <Header
                            variant='h2'
                            actions={
                                <SpaceBetween direction='horizontal' size='xs'>
                                    <Button disabled={!validUserName} onClick={onAddUserPermission}>
                                        {appLabels.assessment.share.action_add}
                                    </Button>
                                </SpaceBetween>
                            }
                        >
                            {appLabels.assessment.share.share_this_assessment}
                        </Header>
                    }
                >
                    <FormField label={appLabels.assessment.share.username} errorText={getErrorText('userEmail')}>
                        <Input
                            placeholder={appLabels.assessment.share.username_placeholder}
                            value={newUserAliasOrEmail}
                            onChange={(event) => {
                                setUserAliasOrEmail(event.detail.value);
                            }}
                        />
                    </FormField>
                    <FormField label={appLabels.assessment.share.permission}>
                        <Select
                            onChange={(event) => setNewPermission(event.detail.selectedOption.label as AssessmentResourceAction)}
                            selectedOption={{ label: newPermission }}
                            options={[
                                { label: AssessmentResourceAction.FULL },
                                { label: AssessmentResourceAction.UPDATE },
                                { label: AssessmentResourceAction.VIEW },
                            ]}
                        />
                    </FormField>
                </Container>

                <Alert
                    onDismiss={() => setErrorAlertVisible(false)}
                    visible={errorAlertVisible}
                    dismissAriaLabel={appLabels.user_actions.close_alert}
                    dismissible
                    type='error'
                    header={appLabels.assessment.share.error_update_permissions}
                >
                    {errorMessage}
                </Alert>
            </ColumnLayout>
            <ShareInvitationConfirmationDialog
                inviteeEmail={inviteUserEmail}
                assessmentDescription={props.assessment.description}
                confirmAction={inviteUserAction}
                cancelAction={() => setInviteUserEmail('')}
            />
            <ShareMultiUserSelectDialog
                usersToSelectFrom={usersToSelectFrom}
                assessmentDescription={props.assessment.description}
                addSelectedUsersAction={addSelectedUsersAction}
                cancelAction={() => setUsersToSelectFrom([])}
            />
        </Modal>
    );
};

export default withAppLabelsContext(withAuthContext(ShareAssessmentDialog));
