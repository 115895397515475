import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';
import { FunctionComponent, useEffect, useState } from 'react';

import { isApnUser } from '../../../common/Utils';
import rumClient from '../../../common/monitoring/RumClient';

type AssessmentCreatedByProps = AuthContextInterface & {
    createdBy: string;
};

const AssessmentCreatedBy: FunctionComponent<AssessmentCreatedByProps> = ({ auth, createdBy }): JSX.Element => {
    const [createdByDetails, setCreatedByDetails] = useState<string | null>(null);

    useEffect(() => {
        async function getCreatedByDetails(userId: string) {
            try {
                const userProfiles = await auth.getUserInfoList([userId]);
                const userName = userProfiles?.[0]?.name || userProfiles?.[0]?.displayName;
                const userOrg = userProfiles?.[0]?.organizationName || userProfiles?.[0]?.organizationId;
                if (userName && userOrg) {
                    setCreatedByDetails(`${userName} (${userOrg})`);
                    return;
                }
            } catch (error) {
                // unable to get user profile. Not much we can do here
                // fall thru and use userId instead.
                rumClient.recordError(error);
            }
            setCreatedByDetails(userId);
        }
        if (createdByDetails === null) {
            if (isApnUser(createdBy)) {
                getCreatedByDetails(createdBy);
            } else {
                setCreatedByDetails(createdBy);
            }
        }
    }, [auth, createdBy, createdByDetails]);

    return <div className='awscat-assessment-created-by'>{createdByDetails}</div>;
};

export default withAuthContext(AssessmentCreatedBy);
