/* eslint-disable camelcase */
export default {
    // Common section
    common_actions: 'Actions',
    common_description: 'Description',
    common_organization_name: 'Organization',
    common_info: 'Info',
    common_unknown: 'Unknown',
    common_total: 'Total',
    common_percentage: 'Percentage',
    common_count: 'Count',
    common_email: 'Email',
    common_email_or_username: 'Email or username',
    common_legend: 'Legend',
    common_date_format_constraint_text: 'Use YYYY/MM/DD format',
    common_selected_date: 'selected date is',
    common_choose_date: 'Choose Date',
    common_next_month: 'Next month',
    common_date_placeholder: 'YYYY/MM/DD',
    common_preferences_title: 'Preferences',
    common_page_size_preference_title: 'Items per page',
    common_max_char_200: 'Max 200 characters allowed',
    common_max_char_2000: 'Max 2000 characters allowed',
    common_options: 'Options',
    common_yes: 'Yes',
    common_no: 'No',
    common_i_dont_know: 'Idk',
    common_optional: 'Optional',
    common_error: 'Error',
    common_step: 'Step',
    common_and: 'and',
    common_length_must_be_between: 'Length must be between',
    common_short: 'Short',
    common_medium: 'Medium',
    common_long: 'Long',
    common_high: 'High',
    common_low: 'Low',
    common_na: 'N/A',
    common_partners: 'Partners',

    // User actions section
    user_actions_action: 'Action',
    user_actions_create: 'Create',
    user_actions_cancel: 'Cancel',
    user_actions_apply: 'Apply',
    user_actions_confirm: 'Confirm',
    user_actions_close: 'Close',
    user_actions_close_dialog: 'Close dialog',
    user_actions_close_alert: 'Close alert',
    user_actions_send: 'Send',
    user_actions_save: 'Save',
    user_actions_selected: 'Selected',
    user_actions_search: 'Search',
    user_actions_dismiss: 'Dismiss',
    user_actions_edit: 'Edit',
    user_actions_edit_settings: 'Edit settings',
    user_actions_share: 'Share',
    user_actions_delete: 'Delete',
    user_actions_drag: 'Drag',
    user_actions_retry_update: 'Retry update',
    user_actions_previous: 'Previous',
    user_actions_back: 'Back',
    user_actions_next: 'Next',
    user_actions_submit: 'Submit',
    user_actions_preview: 'Preview',
    user_actions_publish: 'Publish',
    user_actions_reset: 'Reset',
    user_actions_add: 'Add',

    statuses_saved: 'Saved',
    statuses_saving: 'Saving...',
    statuses_failed_to_save_refresh: 'Failed to save. Please refresh the page. File a bug if this error recurs.',

    // Features section
    features_error_loading_features: 'Error loading assessment features',

    // Header section
    header_feedback_title: 'Feedback',
    header_resources_title: 'Resources',

    // Landing page
    landing_title: 'AWS Assessment Tool',
    landing_serviceName: 'AWS Assessment Tool',
    landing_majorDescription: 'Measure, assess, and accelerate the journey to the cloud',
    landing_minorDescription:
        'The AWS Assessment Tool is a professional online platform for creating, managing, ' +
        'and conducting a variety of assessments related to different elements and phases of cloud adoption.',
    landing_whatItDoes_title: 'Are you new to the Assessment Tool?',
    landing_whatItDoes_description:
        'The AWS Assessment Tool is an online platform developed by AWS for delivering ' +
        'a multitude of surveys, questionnaires, and assessments for both internal and customer-facing engagements. ' +
        'This platform provides a safe and secure means to gather information, complete an analysis, and generate a report, where applicable.',
    landing_gettingStarted_title: 'Getting Started',
    landing_gettingStarted_links_1_linkText: 'Create assessments',
    landing_gettingStarted_links_1_linkLocation: '/#',
    landing_gettingStarted_pulldown_header: 'Recent assessments',
    landing_gettingStarted_pulldown_footer: 'View all assessments',
    landing_benefitsAndFeatures_title: 'Benefits and features',
    landing_benefitsAndFeatures_1_title: 'Coordinate a systematic approach to gathering information',
    landing_benefitsAndFeatures_1_description:
        'Gather information around capabilities based on a defined framework and retain results within ' +
        'a persistent and secure data platform for analysis and comparison over time',
    landing_benefitsAndFeatures_2_title: 'Presentation of assessment results to enable analysis and shape the formation of a roadmap',
    landing_benefitsAndFeatures_2_description:
        'Align automatically-calculated results with components associated with the next phase of the ' +
        'cloud journey including generation of heatmap, radar chart, and readiness scores to help establish a plan of action.',
    landing_benefitsAndFeatures_3_title: 'Automatically generated reports for communication of results and project setup',
    landing_benefitsAndFeatures_3_description:
        'One-click report generation for ease of distributing results to relevant stakeholders ' + 'and facilitating acceleration of next steps',
    landing_moreResources_title: 'More Resources',
    landing_moreResources_1_linkLocation: '/#',
    landing_moreResources_1_linkText: 'User guide',
    landing_moreResources_2_linkText: 'Migration Readiness Assessment delivery guide',
    landing_moreResources_3_linkText: 'FAQ',
    landing_moreResources_6_linkText: 'For AWS users, join us on Slack: #aws-assessment-tool-a2t',
    landing_relatedServices_title: 'Related Services',
    landing_relatedServices_1_title: 'Migration Portfolio Assessment',
    landing_relatedServices_1_description: 'Assess customer IT portfolios, plan migrations, build business case',
    landing_relatedServices_2_title: 'AWS Prescriptive Guidance Library',
    landing_relatedServices_2_description: 'Library of AWS migration patterns',

    // Side navigation
    side_navigation_administration_administration: 'Administration',
    side_navigation_administration_customer_accounts: 'Customer accounts',
    side_navigation_administration_overview: 'Overview',
    side_navigation_administration_recommended_actions: 'Prescribed actions',
    side_navigation_administration_report_templates: 'Report templates',
    side_navigation_administration_manage_templates: 'Manage templates',
    side_navigation_view_all_assessments: 'View all assessments',
    side_navigation_pre_event_pre_event: 'Pre-event',
    side_navigation_pre_event_manage_sponsor: 'Manage sponsor',
    side_navigation_assessment: 'Assessment',
    side_navigation_results_results: 'Results',
    side_navigation_results_update_assessment_info: 'Update assessment info',
    side_navigation_results_review_all: 'Review all',
    side_navigation_results_generate_report: 'Generate report',
    side_navigation_results_complete_assessment: 'Complete assessment',
    side_navigation_missing_name: 'Missing name',

    // Customer selection page
    customer_selection_table_title: 'Customers',
    customer_selection_filter_space_holder: 'Find customers',
    customer_selection_create_customer: 'Create customer',
    customer_selection_customer_account_name: 'Account name',
    customer_selection_customer_account_id: 'Account ID',
    customer_selection_customer_industry: 'Industry',
    customer_selection_customer_region: 'Region',
    customer_selection_customer_territory: 'Territory',
    customer_selection_customer_website: 'Website',
    customer_selection_customers: 'customers',
    customer_selection_reference_id: 'Reference ID',
    customer_selection_created_by: 'Created by',
    customer_selection_action: 'Action',
    customer_selection_action_select: 'Select',
    customer_selection_action_select_all_customers: 'Select all customers',
    customer_selection_all_customers: 'All customers',
    customer_selection_all_customers_selected: 'All customers selected',
    customer_selection_selected_customer: 'Selected customer',
    customer_selection_see_more_customers: 'See more customers',
    customer_selection_loading_customers: 'Loading customers',
    customer_selection_preference_title: 'Preferences',
    customer_selection_page_size_preference_title: 'Customers per page',
    customer_selection_table_no_customers: 'No customers',
    customer_selection_table_no_customers_to_display: 'No customers to display.',
    customer_selection_table_error_loading_customers: 'Error loading customer accounts',
    customer_selection_table_error_invalid_customer_id: 'Invalid customer ID',
    customer_selection_create_title: 'Create customer account',
    customer_selection_create_account_details: 'Account details',
    customer_selection_create_account_name_placeholder: 'e.g. ACME Corp. (2 to 128 characters)',
    customer_selection_create_account_name_validation_min_char: 'Account name must be at least 2 characters',
    customer_selection_create_account_name_validation_max_char: 'Account name cannot be more than 128 characters',
    customer_selection_create_reference_id_placeholder: 'e.g. Salesforce ID (1 to 128 characters)',
    customer_selection_create_account_reference_id_validation_min_char: 'Account reference ID must be at least 1 character',
    customer_selection_create_account_reference_id_validation_max_char: 'Account reference ID cannot be more than 128 characters',
    customer_selection_create_create_customer_account_successful: 'Customer account created successfully.',
    customer_selection_create_error_creating_customer: 'Error creating customer account',
    customer_selection_update_title: 'Update customer account',
    customer_selection_update_update_customer_account_successful: 'Customer account updated successfully.',
    customer_selection_update_error_updating_customer: 'Error updating customer account',

    // Assessment metadata section
    assessment_current_assessment: 'Current assessment',
    assessment_description: 'Description',
    assessment_customer_account_name: 'Account name',
    assessment_type: 'Type',
    assessment_status: 'Status',
    assessment_created_by: 'Created by',
    assessment_last_updated: 'Last updated',
    assessment_workstreams: 'Workstreams',
    assessment_perspectives: 'Perspectives',
    assessment_version: 'Version',
    assessment_name: 'Name',

    // Assessment status bar section
    assessment_status_bar_answered: 'answered',
    assessment_status_bar_in_progress: 'In progress',
    assessment_status_bar_completed: 'Completed',
    assessment_status_bar_of: ' of ',
    assessment_status_bar_current_prompt_number: 'Current prompt number: ',

    // Assessment complete section
    assessment_complete_status_constraint:
        'Your assessment is now marked as Completed in the system. ' +
        'If you need to make additional edits, you may return the assessment to the In progress state. ',
    assessment_complete_info_panel_text:
        'Use this page to confirm details of the assessment and mark it as Completed. ' +
        'If you need to make changes to the assessment you may return to this page to change its status back to In Progress. ' +
        '\n' +
        'You can also provide valuable input to our product roadmap by sending out a survey to your customer.',
    assessment_complete_title: 'Complete assessment',
    assessment_complete_update_button_text: 'Update',
    assessment_complete_complete_button_text: 'Complete assessment',
    assessment_complete_error_completing_assessment: 'Error completing assessment',
    assessment_complete_complete_assessment_successful: 'Assessment successfully completed',

    // Assessment create section
    assessment_create_enter_assessment_details: 'Enter assessment details',
    assessment_create_review_assessment_details: 'Please review assessment details',
    assessment_create_invalid_assessment_details: 'Invalid Assessment Details',
    assessment_create_error_creating_assessment: 'Error creating assessment',
    assessment_create_errors_retrieving_templates: 'errors retrieving templates',
    assessment_create_error_retrieving_all_templates: 'Error retrieving all templates',
    assessment_create_error_getting_assessment_types: 'Error Getting Assessment Types',
    assessment_create_customer_accounts: 'Customer Accounts',
    assessment_create_customer_account: 'Customer Account',
    assessment_create_account_id: 'Account ID',
    assessment_create_owner: 'Owner',
    assessment_create_territory: 'Territory',
    assessment_create_load_customers_error_message: 'errors retrieving customer accounts. First error:',
    assessment_create_error_retrieving_all_customer_accounts: 'Error retrieving all customer accounts',
    assessment_create_error_getting_customer_accounts: 'Error Getting Customer Accounts',
    assessment_create_loading_customers: 'Loading customers',
    assessment_create_customer_accounts_found: 'customer accounts found',
    assessment_create_no_customer_accounts: 'No customer accounts',
    assessment_create_no_customer_accounts_to_display: 'No customer accounts to display',
    assessment_create_reload_customer_accounts: 'Reload Customer Accounts',
    assessment_create_details: 'Assessment Details',
    assessment_create_details_account: 'Account',
    assessment_create_details_description: 'Description',
    assessment_create_details_description_placeholder: 'e.g. Data center migration',
    assessment_create_map_id: 'MAP Program engagement ID',
    assessment_create_map_id_placeholder: '10-digit MPE ID or NA',
    assessment_create_customer_contact: 'Customer Contact',
    assessment_create_customer_contact_placeholder: 'e.g. jdoe@acme.com (up to 128 characters)',
    assessment_create_workshop_date: 'Workshop date',
    assessment_create_readout_date: 'Readout date',
    assessment_create_previous_month: 'Previous month',
    assessment_create_today: 'Today',
    assessment_create_delivered_by: 'Delivered by',
    assessment_create_demo_or_test_constraint: 'Please do not use the data to generate customer activity',
    assessment_create_demo_or_test: 'Demo or test input and not actual customer data',
    assessment_create_help_panel_delivery_guidance: 'Delivery guidance',
    assessment_create_info_panel_step_1:
        'Use this page to create a new assessment by specifying customer name, ' + 'choosing assessment type, and providing assessment details.',
    assessment_create_workshopdate_readoutdate_constraint: 'Please estimate if exact date is still unknown',
    assessment_create_optout_recommendation_constraint: 'This is used for customers with high privacy requirements',
    assessment_create_optout_recommendation: 'Opt out of sharing completed assessment details with offering teams',

    // Assessment create select customer
    assessment_create_select_customer_title: 'Select existing customer account',
    assessment_create_select_customer_description:
        'Search for a customer account from' + ' SalesForce using a keyword. For a new customer, please create an account in SalesForce first.',
    assessment_create_select_customer_description_partner:
        'Select an existing Customer Account' + ' from below or Search account by keyword. Or you can create a new one by clicking Create customer.',
    assessment_create_select_customer_filter_placeholder: 'Find existing customer account by name or ID',
    assessment_create_select_customer_no_account_selected: 'No customer account selected. Select an account before proceeding.',

    // Assessment create customer profile
    assessment_create_customer_profile_title: 'Describe customer profile',
    assessment_create_customer_profile_header: 'Customer profile',
    assessment_create_customer_profile_description: 'Using this page to provide customer information by answering some profiling questions.',

    assessment_create_customer_profile_segment_prompt: 'What MRA tailored version is more reflective of your customer profile? ',
    assessment_create_customer_profile_segment_description:
        'The segment can be indicative of the need for addressing specific ' +
        'concerns like non-technical blockers due to organizational alignment ' +
        'needs as well as how cloud aligns with their business and operating models.',

    assessment_create_customer_profile_segments_ent_label: 'Enterprise (ENT)',
    assessment_create_customer_profile_segments_ent_description:
        'The Enterprise (ENT) segment consists of some of our highest potential customers in terms ' +
        'of Total Addressable Spend, as well as mid-to-large size organizations that are not ' +
        'Independent Software Vendors (ISVs) or Global System Integrator (GSIs). Some of these ' +
        'organizations have made significant movement to cloud infrastructure and may even be all-in ' +
        'on AWS. More typically, this segment is represented by traditional enterprises with large ' +
        'legacy technology investments. They host a significant portion of applications and data on-premises ' +
        'or in co-location data centers or a hybrid of the two, but have a significant need to ' +
        'modernize their technology platforms, applications and operating models.',

    assessment_create_customer_profile_segments_dnb_label: 'Digital Native Business (DNB)',
    assessment_create_customer_profile_segments_dnb_description:
        'The Digital Native Business (DNB) customer is (1) a consumer-focused company that is ' +
        '"born in the cloud" and is "tech savvy" by nature, (2) operates a Business to Consumer' +
        '(B2C) model*, and (3) whose primary mode of business is online but the goods and services ' +
        'may be physical. DNBs are often highly centralized organizations building cloud-first and ' +
        'mobile apps, although they may have some technical debt (either legacy or through ' +
        "acquisitions). DNBs typically don't have large scale migrations, though they can be present " +
        'in more mature DNBs. This is generally the segment where startup alumni (i.e. customers ' +
        'who cross over the $1.2M TTM GAR threshold or post-IPO or acquired startups) move to. ' +
        'A small DNB meets this definition, but is not a Startup. ' +
        '*DNBs who operate a B2B model or a B2B2C model in addition to their main B2C model ' +
        'may also be included if their ‘B2C’ motion meets the above definition.',

    assessment_create_customer_profile_segments_isv_label: 'Independent Software Vendor (ISV)',
    assessment_create_customer_profile_segments_isv_description:
        'An Independent Software Vendor (ISV) is an organization that (1) develops, maintains, and ' +
        'sells software, (2) operates a Business to Business (B2B) model**, and (3) generates the ' +
        'majority (>~50%) of their revenue through sales of perpetual and/or subscription software ' +
        'licenses and the associated support and maintenance services. From a WWCS perspective, ' +
        'this segment is characterized by engagement where AWS sells to ISVs. ' +
        '**ISVs who operate a B2C model in addition to their main B2B model are included, and ' +
        'B2B2C organizations may also be included if their ‘B2B’ motion meets the above definition.',

    assessment_create_customer_profile_segments_smb_label: 'Small and Midsize Business (SMB)',
    assessment_create_customer_profile_segments_smb_description:
        'The Small and Midsize Business (SMB) segment includes both SMB and Reseller (sell to) ' +
        'customers. SMB includes customers that span all industries, are typically private, and are ' +
        'characterized by diversity in operating models, org structures, management styles, ' +
        'technology dependence IT expertise and capacity for growth. Collectively, they greatly ' +
        'outnumber the number of companies across all other segments and typically represent the ' +
        'segment before Midsize Enterprise. Resellers are customers that have signed a Reseller ' +
        'Agreement and accelerate customer adoption by expanding reach to end customers. The ' +
        'majority of Reseller revenue is re-routed to end customer territories.',

    assessment_create_customer_profile_segments_sup_label: 'Startup (SUP)',
    assessment_create_customer_profile_segments_sup_description:
        'The Startup (SUP) segment represents high growth potential customers that are laying the ' +
        'foundation for their technology infrastructure. They are expected to grow into DNB or ISV ' +
        'segments once they achieve enough Trailing Twelve Month Gross Amortized Revenue (TTM GAR). ' +
        'Specifically, they are comprised of accounts that are either affiliated with or have raised ' +
        'capital from an Angel, University, Accelerator, Incubator, Venture Capital (VC) firm, Government ' +
        'Startup incubator, or other Startup organization or are affiliated with a Startup discovery ' +
        'program such as Activate. In addition, customers in the SUP segment are relatively young companies ' +
        'with recent funding events and have not IPO’d or been acquired.',

    assessment_create_customer_profile_cloud_maturity_prompt:
        'What is the perceived level of current cloud activity and capability ' + 'of the team involved in this initiative?',
    assessment_create_customer_profile_cloud_maturity_description:
        'Understanding the level of experience within an organization can ' +
        'help guide the emphasis on cloud foundation and fundamentals including account and security aspects.',

    assessment_create_customer_profile_cloud_maturity_low_label: "Low or don't know",
    assessment_create_customer_profile_cloud_maturity_low_description:
        'Cloud services are a utility. Rather than staying on the cutting-edge, ' +
        'they wait for technology to be perfected, then follow proven roadmaps ' +
        'before implementing. While they have some of their infrastructure in the ' +
        'public cloud, they maintain a large amount of legacy infrastructure in on-premises ' +
        'data centers. This conservative attitude toward technology leads to greater difficulty ' +
        'for Low maturity organizations in realizing maximum value from cloud services.' +
        '<br/>In addition, one or more the following factors are exhibited: <ul>' +
        '<li>limited current AWS spend</li>' +
        '<li>limited migration velocity (workloads migrated per month)</li>' +
        '<li>limited service mix (i.e. mostly core cloud services)</li>' +
        '<li>limited cloud experience/capability</li>' +
        '</ul>',

    assessment_create_customer_profile_cloud_maturity_high_label: 'High',
    assessment_create_customer_profile_cloud_maturity_high_description:
        'Fully recognize the transformative power that cloud services can have ' +
        'on their business.  They aggressively innovate and view technology as a ' +
        'competitive advantage. High maturity organizations have fully committed to ' +
        'the cloud, with a high percent of their infrastructure housed in a public cloud. ' +
        'These attitudes and the cloud-related behaviors they exhibit organizationally lead ' +
        'to High maturity organizations realizing the most value from cloud services.' +
        '<br/>In addition, one or more the following factors are exhibited: <ul>' +
        '<li>significant current AWS spend</li>' +
        '<li>significant migration velocity (workloads migrated per month)</li>' +
        '<li>balanced service mix (i.e. core and advanced services)</li>' +
        '<li>established and advancing cloud experience/capability</li>' +
        '</ul>',

    assessment_create_customer_profile_plan_and_alignment_exists_prompt:
        'Is there a clear objective and long range roadmap as well as a near-term ' +
        "task plan to advance the customer's journey with clear understanding and alignment " +
        'on responsibilities across all stakeholders?',
    assessment_create_customer_profile_plan_and_alignment_exists_description:
        'Understanding the level of federation and/or alignment and cohesion of the team ' +
        'can help guide the need for addressing non-technical blockers. The absence of a clear plan and ' +
        'alignment , especially on next steps, is a critical blocker to moving forward.',

    assessment_create_customer_profile_plan_and_alignment_exists_yes_label: 'Yes',
    assessment_create_customer_profile_plan_and_alignment_exists_yes_description:
        'There are little to no organizational constraints or conflicting agendas related ' +
        'to this cloud initiative. The Objectives are clear, captured, and communicated, and ' +
        'there is evidence of a plan of action including the next right steps for customer, AWS, and/or partner.',

    assessment_create_customer_profile_plan_and_alignment_exists_no_label: 'No',
    assessment_create_customer_profile_plan_and_alignment_exists_no_description:
        'There is a need to gain better alignment between and within groups that ' +
        'will either deliver or eventually utilize or manage the capabilities related ' +
        'to this initiative. The customer needs guidance on how to close readiness gaps to move forward.',

    // Assessment create select assessment type
    assessment_create_select_assessment_type_title: 'Select assessment type',
    assessment_create_select_assessment_type_description: 'Use this page to choose an assessment type.',
    assessment_create_select_assessment_type_filter_placeholder: 'Find assessment types',
    assessment_create_template_table_empty_title: 'No assessment type available',
    assessment_create_template_table_header: 'Assessment types',

    // Assessment create recommendation alerts
    assessment_create_recommendation_alerts_mra_not_recommended:
        'Based on the customer profile information provided, the "ADV - Advanced" is the recommended lightweight version of Migration Readiness Assessment ' +
        '(MRA) to identify risks and accelerate migration journey of customers with proven cloud experience. ' +
        "If MRA is required for MAP funding and your customer doesn't have an appetite for it, " +
        'please follow the guidance for seeking an MRA exception and click "Cancel" to exit the create assessment workflow.',

    // Assessment list section
    assessment_list_all_customers: 'All customers',
    assessment_list_create_assessments: 'Create assessments',
    assessment_list_active_and_favorite_assessments: 'Active assessments',
    assessment_list_completed_assessments: 'Completed assessments',
    assessment_list_assessments: 'assessments',
    assessment_list_filter_placeholder: 'Find assessments',
    assessment_list_loading_assessments: 'Loading assessments',
    assessment_list_preference_title: 'Preferences',
    assessment_list_page_size_preference_title: 'Assessments per page',
    assessment_list_assessments_table_no_assessments: 'No assessments',
    assessment_list_assessments_table_no_assessments_to_display: 'No assessments to display.',
    assessment_list_workshop_date: 'Workshop date',
    assessment_list_readout_date: 'Readout date',
    assessment_list_actions: 'Actions',
    assessment_list_info_panel_text1:
        'Use this page to view assessments you own or those that were shared with you by others. The Active and favorite ' +
        'assessments section contains assessments which are either marked as Favorites or are still In Progress. The Completed assessments section ' +
        'contains assessments which are marked as Completed.',
    assessment_list_info_panel_text2: 'To limit the view to assessments of a single customer, use the filter on the top of the page.',
    assessment_list_info_panel_text3: 'For new assessments, use the Create assessment option.',

    // Assessment share section
    assessment_share_share: 'Share',
    assessment_share_user_not_found: 'user not found',
    assessment_share_multiple_users_found: 'Multiple users found',
    assessment_share_select_users: 'Please select user to grant permission for assessment',
    assessment_share_invite: 'Invite',
    assessment_share_invite_message: 'Would you like to invite this user to access assessment',
    assessment_share_accept_invitation: 'Accepting invitation to access assessment',
    assessment_share_accept_invitation_message: 'Looking up invitation...',
    assessment_share_accept_invitation_successful: 'Successfully accepted invitation to shared assessment.',
    assessment_share_current_permissions: 'Current permissions',
    assessment_share_share_this_assessment: 'Share this assessment',
    assessment_share_status: 'Status',
    assessment_share_permission: 'Permission',
    assessment_share_username: 'Username or email address',
    assessment_share_username_placeholder: 'e.g. jdoe or jdoe@othercompany.com if different email domain as mine',
    assessment_share_name: 'Name or email address',
    assessment_share_remove: 'Remove',
    assessment_share_action_add: 'Add',
    assessment_share_status_pending_add: 'Pending add',
    assessment_share_status_pending_update: 'Pending update',
    assessment_share_status_pending_invite: 'Pending invite',
    assessment_share_status_invited: 'Invited',
    assessment_share_status_saved: 'Saved',
    assessment_share_loading_permissions: 'Loading permissions',
    assessment_share_table_no_permissions: 'No permissions',
    assessment_share_table_no_permissions_to_display: 'No Permissions to display',
    assessment_share_without_at_sign: 'without @amazon.com',
    assessment_share_error_update_permissions:
        'Failed to update permission, please re-share. If error persists, ' + 'click Feedback on top of this page and then Submit Issue',
    assessment_share_error_remove_permissions: 'Unable to remove permission',
    assessment_share_error_remove_last_permission: 'Removing last FULL permission will result in lost of access to assessment.',
    assessment_share_error_invalid_username: 'Invalid user alias or email address',
    assessment_share_error_accept_invitation: 'Error encountered accepting invitation to a shared assessment',

    // Assessment pre-event section
    assessment_pre_event_manage_sponsor: 'Manage sponsor',
    assessment_pre_event_sponsor: 'Sponsor',
    assessment_pre_event_email_placeholder: 'username@company.com',
    assessment_pre_event_due_date: 'Due date',
    assessment_pre_event_due_date_description: 'Specify the date and time when the pre-event is due',
    assessment_pre_event_previous_month: 'Previous month',
    assessment_pre_event_today: 'Today',
    assessment_pre_event_status: 'Status',
    assessment_pre_event_participants: 'Participants',
    assessment_pre_event_submitted_responses: 'Submitted responses',
    assessment_pre_event_pre_event_not_created: 'Pre-event questionnaire has not been created',
    assessment_pre_event_sponsor_email_validation_message: 'Invalid email format',
    assessment_pre_event_due_date_validation_message: 'Invalid due date format. Expecting YYYY/MM/DD',
    assessment_pre_event_couldnt_share:
        'Unable to share pre-event with the following user(s). Please re-share this assessment with the users ' +
        'unable to access the pre-event. If the issue persists, please submit an issue for assistance',
    assessment_pre_event_error_saving: 'Error saving',
    assessment_pre_event_assessment_not_loaded: 'Assessment not loaded',
    assessment_pre_event_error_loading: 'Error loading',
    assessment_pre_event_cannot_update_unsaved: 'Cannot update an unsaved questionnaire',
    assessment_pre_event_sponsor_not_save: 'Pre-event sponsor not saved',
    assessment_pre_event_cannot_send_email: 'Cannot send email',
    assessment_pre_event_email_template_not_available: 'Sponsor email template not available',
    assessment_pre_event_info_panel_text_1:
        'Use this page to create a pre-event questionnaire for the assessment by specifying an email address for the ' +
        'pre-event questionnaire Sponsor (customer POC) and a due date for submitting responses. If your browser is configured to allow pop-up windows and' +
        ' has a default mail client configured, choosing *Save* will generate an email that you can use to inform the Sponsor about the pre-event ' +
        'questionnaire assignment. You can also use *Sponsor invitation email* template to manually construct a message to the Sponsor. ',
    assessment_pre_event_info_panel_text_2: 'Choose Send if you need to resend invitation to the Sponsor using your default mail client.',
    assessment_pre_event_info_panel_text_3:
        'The Sponsor should follow the instructions in the email to sign up with the AWS Business Data Gathering ' +
        'tool and to manage the pre-event questionnaire by adding participants and monitoring the completion status. During the sign-up process, ' +
        'the Sponsor must use the same email address you specified when you assigned them to be the Sponsor. Details regarding this process can be found ' +
        'in the AWS published MRA Delivery Guide associated with this offering in our AWS Prescriptive Guidance Library, including detailed and sharable ' +
        'Runbooks for the Facilitator, Sponsor and Participants of an MRA engagement.',
    assessment_pre_event_questionnaire_sponsor_view: 'Pre-event questionnaire management link (Sponsor view):',
    assessment_pre_event_questionnaire_participant_view: 'Pre-event questionnaire link (Participant view):',
    assessment_pre_event_send_designated_questions: 'Send pre-event designated questions',
    assessment_pre_event_send_all_questions: 'Send all questions (excluding "optional")',
    assessment_pre_event_send_all_questions_description: 'Cannot be modified once the questionnaire is created.',

    // Assessment facilitate section
    assessment_facilitate_filter: 'Filter',
    assessment_facilitate_next: 'Next',
    assessment_facilitate_previous: 'Previous',
    assessment_facilitate_comments: 'Comments',
    assessment_facilitate_comments_placeholder: 'Enter observations here',
    assessment_facilitate_comments_saved: 'Comments saved',
    assessment_facilitate_error_api: 'Something went wrong.',
    assessment_facilitate_live_event: 'Live event',
    assessment_facilitate_participant_comments: 'Participant comments',
    assessment_facilitate_i_dont_know: "I don't know",
    assessment_facilitate_pre_event: 'Pre-event',
    assessment_facilitate_rating_saved: 'Rating saved',
    assessment_facilitate_response_saved: 'Response saved',
    assessment_facilitate_try_update_again: 'Try again',
    assessment_facilitate_updating_comments: 'Updating comments...',
    assessment_facilitate_updating_rating: 'Updating rating...',
    assessment_facilitate_updating_response: 'Updating response...',
    assessment_facilitate_votes: 'votes',
    assessment_facilitate_error_loading_question: 'Error loading question',
    assessment_facilitate_error_loading_assessment: 'Unable to access this assessment.',
    assessment_facilitate_category_has_no_questions: 'This category has no questions.',

    // Assessment live session
    assessment_live_session_start: 'Start',
    assessment_live_session_active: 'Active',
    assessment_live_session_create_new_session: 'Create new session',
    assessment_live_session_create_password_label: 'Password',
    assessment_live_session_create_password_description: 'This will be used by all workshop participants to login',
    assessment_live_session_create_password_hint: 'Between 8 to 20 characters with at least 1 of upper case, 1 lower case and 1 number',
    assessment_live_session_create_password_placeholder: 'RoadRunner2022',
    assessment_live_session_create_password_validation_min_char: 'Session passcode must be at least 8 characters',
    assessment_live_session_create_password_validation_mix_char: 'Session passcode must contain at least 1 of upper case, 1 lower case and 1 number',
    assessment_live_session_create_password_validation_max_char: 'Session passcode cannot be more than 20 characters',
    assessment_live_session_create_session_instruction: 'To begin a workshop session please submit the following',
    assessment_live_session_create_session_title_label: 'Session title',
    assessment_live_session_create_session_title_placeholder: 'Acme Corp workshop',
    assessment_live_session_create_session_title_hint: 'Maximum 50 characters',
    assessment_live_session_create_session_title_validation_min_char: 'Session title cannot be empty',
    assessment_live_session_create_session_title_validation_max_char: 'Session title cannot be greater than 50 characters',
    assessment_live_session_error_api: 'Live session API error',
    assessment_live_session_inactive: 'Inactive',
    assessment_live_session_resume_session: 'Resume',
    assessment_live_session_session_link: 'Session link',
    assessment_live_session_start_polling_session: 'Start polling session',
    assessment_live_session_suspend_session: 'Suspend',

    // Assessment data model
    assessment_model_account_is_not_empty: 'Account cannot be empty',
    assessment_model_description_is_not_empty: 'Description cannot be empty',
    assessment_model_map_id_is_not_empty: 'MAP Program engagement ID cannot be empty',
    assessment_model_workshop_date_is_not_empty: 'Workshop date cannot be empty',
    assessment_model_readout_date_is_not_empty: 'Readout date cannot be empty',
    assessment_model_delivered_by_is_not_empty: 'Delivered by cannot be empty',
    assessment_model_type_is_not_empty: 'Must select assessment type',

    // Assessment update section
    assessment_update_cancel_button_text: 'Cancel',
    assessment_update_update_assessment_error: 'Error',
    assessment_update_update_assessment_error_updating: 'Error updating assessment',
    assessment_update_update_assessment_successful: 'Assessment successfully updated',
    assessment_update_update_assessment_invalid_assessment_details: 'Invalid Assessment Details',
    assessment_update_load_assessment_error_content: 'Error loading assessment',
    assessment_update_load_assessment_error_header: 'Error',
    assessment_update_load_assessment_errors_content: 'Encountered errors loading assessment',
    assessment_update_load_assessment_errors_header: 'Errors',
    assessment_update_update_assment_review_details: 'Please review assessment details',
    assessment_update_save_button_text: 'Save updates',
    assessment_update_title: 'Update assessment info',
    assessment_update_info_panel_text: 'Use this page to update details of the assessment including Workshop date and Readout date.',

    // Assessment delete section
    assessment_delete_delete: 'Delete',
    assessment_delete_delete_this_assessment_permanently: 'Delete this assessment permanently? This action cannot be undone.',
    assessment_delete_confirm_prompt: 'To confirm deletion, enter "DELETE" in the text input field.',
    assessment_delete_confirm_input_expected: 'DELETE',
    assessment_delete_error_delete_assessment: 'Failed to delete assessment',

    // Assessment default rating bands
    assessment_default_rating_band_title_incomplete: 'Incomplete',
    assessment_default_rating_band_title_bad: 'Red',
    assessment_default_rating_band_title_concern: 'Amber',
    assessment_default_rating_band_title_good: 'Green',
    assessment_default_rating_band_description_incomplete: 'N/A or in-progress',
    assessment_default_rating_band_description_bad: 'Requires immediate assistance to unblock progress',
    assessment_default_rating_band_description_concern: 'Needs attention to accelerate momentum',
    assessment_default_rating_band_description_good: 'Readiness maturity needs minimal assistance',

    // Assessment results -> review all section
    assessment_results_review_all_legend_incomplete: 'N/A or in-progress',
    assessment_results_review_all_legend_concern: 'Needs attention to accelerate momentum',
    assessment_results_review_all_legend_good: 'Readiness maturity needs minimal assistance',
    assessment_results_review_all_legend_bad: 'Requires immediate assistance to unblock progress',
    assessment_results_review_all_design_evolve: 'Design or Evolve COE',

    // Assessment results -> review all -> heatmap section
    assessment_results_review_all_heatmap_all_drivers: 'All Capabilities',
    assessment_results_review_all_heatmap_heatmap: 'Heatmap',
    assessment_results_review_all_heatmap_legends: 'Heatmap cell states',
    assessment_results_review_all_heatmap_info_panel_text:
        'Use this page to review current level of maturity across all readiness activities. ' +
        'If a score is not provided for one or more questions, the average score for the corresponding activity will not be calculated. ' +
        'To complete or update the observations, you can navigate to the associated section of the report by clicking on a heatmap card. ',
    assessment_results_review_all_heatmap_status_text_incomplete: 'N/A or in progress',
    assessment_results_review_all_heatmap_status_text_good: 'Readiness maturity needs minimal assistance',
    assessment_results_review_all_heatmap_status_text_concern: 'Need attention to accelerate momentum',
    assessment_results_review_all_heatmap_status_text_bad: 'Require immediate assistance to unblock progress',
    assessment_results_review_all_heatmap_pre_mobilize: 'Pre-mobilize',

    // Assessment results -> review all -> radar section
    assessment_results_review_all_radar_radar: 'Radar',
    assessment_results_review_all_radar_info_panel_text:
        'Use this page to review current level of maturity across all readiness categories and perspectives.  ' +
        'If score is not provided for one or more questions, average score for the corresponding activity will not be calculated and the activity will ' +
        'be marked as “In progress or N/A” and will not be reflected on the Radar chart.',

    // Assessment results -> review all -> scores section
    assessment_results_review_all_scores_scores: 'Scores',
    assessment_results_review_all_scores_na: 'N/A',
    assessment_results_review_all_scores_not_applicable: 'Not applicable',
    assessment_results_review_all_scores_incomplete: 'Incomplete',
    assessment_results_review_all_scores_info_panel_text:
        'Use this page to review current level of maturity across all readiness categories. ' +
        'If score is not provided for one or more questions, average score for the corresponding activity will not be calculated and the ' +
        'activity will be marked as In progress or N/A.',

    // Assessment results -> review all -> responseDistribution section
    assessment_results_review_all_response_distribution: 'Response distribution',
    assessment_results_review_all_response_distribution_info_panel_text:
        'Use this page to review response distribution across all readiness categories. ',

    // Assessment results -> review all -> report section
    assessment_results_review_all_report_report: 'Report',
    assessment_results_review_all_report_info_header: 'Results Dashboard',
    assessment_results_review_all_report_info_legend: 'Legend',
    assessment_results_review_all_report_info_content:
        'Use this page to review current level of maturity across all readiness categories and to' +
        ' document summary observations and recommended actions. If score is not provided for one or more questions, average score for the corresponding' +
        ' activity will not be calculated and the activity will be marked as "In progress or N/A" and will not be reflected on the Radar chart',
    assessment_results_review_all_report_participant_comments: 'participant comments',
    assessment_results_review_all_report_text: 'Text',
    assessment_results_review_all_report_rating: 'Rating',
    assessment_results_review_all_report_category: 'Category',
    assessment_results_review_all_report_activity: 'Activity',
    assessment_results_review_all_report_phase: 'Phase',
    assessment_results_review_all_report_questions: 'Questions',
    assessment_results_review_all_report_remove: 'Remove',
    assessment_results_review_all_report_filtering_text_placeholder: 'Search anything',
    assessment_results_review_all_report_choose_rating: 'Choose rating',
    assessment_results_review_all_report_choose_category: 'Choose category',
    assessment_results_review_all_report_choose_activity: 'Choose activity',
    assessment_results_review_all_report_choose_phase: 'Choose phase',
    assessment_results_review_all_report_summary_observation: 'Summary Observation',
    assessment_results_review_all_report_summary_observations: 'Summary Observations',
    assessment_results_review_all_report_summary_observations_tip:
        'Add observations manually or click pre-populate observations to add all' + ' perspective question notes',
    assessment_result_review_all_report_summary_observations_populate_hover_text: 'Copy facilitator comments into summary observation text box',
    assessment_results_review_all_report_facilitator_comments: 'Facilitator comments:',
    assessment_results_review_all_report_facilitator_comments_error_api: 'Something went wrong. Try again',
    assessment_results_review_all_report_facilitator_comments_placeholder: 'Enter observations here',
    assessment_results_review_all_report_facilitator_comments_saved: 'Comments saved.',
    assessment_results_review_all_report_facilitator_comments_try_update_again: 'Try again',
    assessment_results_review_all_report_facilitator_comments_updating: 'Updating comments...',
    assessment_results_review_all_report_summary_observations_placeholder: 'Manually add observations here or click pre-populate observations',
    assessment_results_review_all_report_pre_populate_summary_observations: 'Pre-populate observations',
    assessment_results_review_all_report_limit_show_more: 'Show more filters',
    assessment_results_review_all_report_limit_show_fewer: 'Show fewer filters',
    assessment_results_review_all_report_matches: '{count, plural, =0 {0 matches} one {# match} other {# matches}}',
    assessment_results_review_all_report_filters_applied: '{count, plural, =0 {filters applied} one {# filter applied} other {# filters applied}}',
    assessment_results_review_all_report_recommended_actions_recommended_actions: 'Recommended actions',
    assessment_results_review_all_report_recommended_actions_add_modal_header: 'Add recommended actions',
    assessment_results_review_all_report_recommended_actions_add_modal_header_2: 'Choose from prescribed action list',
    assessment_results_review_all_report_recommended_actions_add_modal_button_cancel: 'Cancel',
    assessment_results_review_all_report_recommended_actions_add_modal_button_custom_action: 'Add custom action',
    assessment_results_review_all_report_recommended_actions_add_modal_button_ok: 'Confirm actions',
    assessment_results_review_all_report_recommended_actions_grid_button_add_action: 'Add action',
    assessment_results_review_all_report_recommended_actions_grid_button_add_custom_action: 'Add custom action',
    assessment_results_review_all_report_recommended_actions_grid_button_add_prescribed_action: 'Add prescribed action',
    assessment_results_review_all_report_recommended_actions_grid_column_action: 'Action',
    assessment_results_review_all_report_recommended_actions_grid_column_owner: 'Owner',
    assessment_results_review_all_report_recommended_actions_grid_column_recommendation_owner: 'Recommendation owner',
    assessment_results_review_all_report_recommended_actions_grid_column_action_owner: 'Action owner',
    assessment_results_review_all_report_recommended_actions_grid_column_priority: 'Priority',
    assessment_results_review_all_report_recommended_actions_grid_column_effort: 'Effort',
    assessment_results_review_all_report_recommended_actions_grid_column_target_date: 'Target date',
    assessment_results_review_all_report_recommended_actions_grid_column_progress: 'Progress',
    assessment_results_review_all_report_recommended_actions_grid_column_benefit: 'Benefit',
    assessment_results_review_all_report_recommended_action_grid_column_delete: 'Delete',
    assessment_results_review_all_report_recommended_action_grid_column_drag: 'Drag',
    assessment_results_review_all_report_recommended_actions_grid_header: 'Recommended actions',
    assessment_results_review_all_report_recommended_actions_grid_nodata_message: 'No actions',
    assessment_results_review_all_report_recommended_actions_grid_placeholder_targetdate: 'mm/dd/yyyy',
    assessment_results_review_all_report_recommended_actions_grid_placeholder_owner: 'Enter owner name',
    assessment_results_review_all_report_recommended_actions_grid_actions_saved: 'Actions saved.',
    assessment_results_review_all_report_recommended_actions_grid_error_api: 'Something went wrong. Try again',
    assessment_results_review_all_report_recommended_actions_grid_try_update_again: 'Try again',
    assessment_results_review_all_report_recommended_actions_grid_updating_actions: 'Updating actions...',
    assessment_results_review_all_report_recommended_actions_delete_recommendations: 'Delete recommendations',
    assessment_results_review_all_report_recommended_actions_confirm_delete_recommendations: 'Do you want to delete the following recommendation(s)?',
    assessment_results_review_all_report_recommended_actions_create_custom_recommendation: 'Create custom recommendation',
    assessment_results_review_all_report_recommended_actions_edit_recommendation: 'Edit recommendation',
    assessment_results_review_all_report_recommended_actions_select_prescribed_recommendations: 'Select prescribed actions',
    assessment_results_review_all_report_recommended_actions_failed_delete_recommendations: 'Failed to delete recommendations',
    assessment_results_review_all_report_recommended_actions_failed_create_custom_recommendation: 'Failed to create custom recommendation',
    assessment_results_review_all_report_recommended_actions_failed_edit_recommendation: 'Failed to edit recommendation',
    assessment_results_review_all_report_recommended_actions_failed_load_recommendations: 'Failed to load recommendations',
    assessment_results_review_all_report_recommended_actions_failed_select_prescribed_recommendations: 'Failed to add prescribed recommendations',
    assessment_results_review_all_report_recommended_actions_in_progress: 'In Progress',
    assessment_results_review_all_report_recommended_actions_inline_edit_disabled: 'Update is disabled for this field',
    assessment_results_review_all_report_recommended_actions_completed: 'Completed',
    assessment_results_review_all_report_recommended_actions_not_started: 'Not Started',
    assessment_results_review_all_report_error_empty_report: 'Unable to find report data',

    // Assessment results -> manage snapshots section
    assessment_results_manage_snapshots_info_panel_text:
        'Use this page to create snapshots of your assessment over time and compare them. Click on the link of the snapshot to load ' +
        'a snapshot into view.<br/><br/>To return to current assessment view, use the dropdown menu and select the name of the current assessment or ' +
        'click "Current assessment" from list of snapshots',

    // Assessment results -> generate report section
    assessment_results_generate_report_generate_report: 'Generate report',
    assessment_results_generate_report_results_presentation: 'Results presentation',
    assessment_results_generate_report_powerpoint_slides: 'PowerPoint slides',
    assessment_results_generate_report_assessment_report: 'Assessment report',
    assessment_results_generate_report_word_document: 'Word document',
    assessment_results_generate_report_recommended_action_backlog: 'Recommended action backlog',
    assessment_results_generate_report_excel_spreadsheet: 'Excel spreadsheet',
    assessment_results_generate_report_download: 'Download',
    assessment_results_generate_report_info_panel_text: 'Use this page to generate different types of assessment reports using available formats.',
    assessment_results_generate_report_report_template_lookup: 'Looking up report templates',
    assessment_results_generate_report_error_report_template_lookup: 'Error encountered while looking up report templates',
    assessment_results_generate_report_report_download_progress_text: 'Generating report. This may take a few seconds...',
    assessment_results_generate_report_report_download_complete_text: 'Report generated successfully.',
    assessment_results_generate_report_report_template_not_available: 'Report template not available for this assessment.',
    assessment_results_generate_report_report_download: 'Generate report',
    assessment_results_generate_report_error_report_download: 'Error encountered while generating report.',
    assessment_results_generate_report_error_assessment_scorecard: 'Assessment or scorescale not provided ',

    // Generate report parameters sent to RGS
    assessment_results_generate_report_parameters_workstream: 'Workstream',
    assessment_results_generate_report_parameters_workstream_phase: 'Workstream Phase',
    assessment_results_generate_report_parameters_workstreams: 'Workstreams',
    assessment_results_generate_report_parameters_average_workstream_score: 'Average Readiness Scores',
    assessment_results_generate_report_parameters_section: 'Section',
    assessment_results_generate_report_parameters_perspective: 'Perspective',
    assessment_results_generate_report_parameters_perspectives: 'Perspectives',
    assessment_results_generate_report_parameters_readiness_activity: 'Readiness Activity',
    assessment_results_generate_report_parameters_recommended_actions: 'Recommended Actions',
    assessment_results_generate_report_parameters_action: 'Action',
    assessment_results_generate_report_parameters_actions: 'Actions',
    assessment_results_generate_report_parameters_action_status: 'Status',
    assessment_results_generate_report_parameters_actions_and_next_steps: 'Actions & Next Steps',
    assessment_results_generate_report_parameters_action_priority: 'Action Priority',
    assessment_results_generate_report_parameters_action_effort: 'Action Effort',
    assessment_results_generate_report_parameters_target_completion_date: 'Target Completion Date',
    assessment_results_generate_report_parameters_owner: 'Owner',
    assessment_results_generate_report_parameters_target_date: 'Target Date',
    assessment_results_generate_report_parameters_priority: 'Priority',
    assessment_results_generate_report_parameters_effort: 'Effort',
    assessment_results_generate_report_parameters_observations: 'Observations',
    assessment_results_generate_report_parameters_summary_observation: 'Summary Observation',
    assessment_results_generate_report_parameters_summary_observations: 'Summary Observations',
    assessment_results_generate_report_parameters_category: 'Category',
    assessment_results_generate_report_parameters_participant: 'Participant',
    assessment_results_generate_report_parameters_comment: 'Comment',
    assessment_results_generate_report_parameters_comments: 'Comments',
    assessment_results_generate_report_parameters_polling_responses: 'Polling Responses',
    assessment_results_generate_report_parameters_vote: 'Vote',
    assessment_results_generate_report_parameters_role: 'Role',
    assessment_results_generate_report_parameters_response: 'Response',
    assessment_results_generate_report_parameters_rating: 'Rating',
    assessment_results_generate_report_parameters_question: 'Question',
    assessment_results_generate_report_parameters_question_number: '#',
    assessment_results_generate_report_parameters_response_type: 'Response Type',
    assessment_results_generate_report_parameters_rating_guide: 'Rating Guide (if applicable)',
    assessment_results_generate_report_parameters_average_score: 'AVERAGE',
    // {} placeholders are replaced with perspectives and workstreams
    assessment_results_generate_report_parameters_perspective_high_explanation:
        "You're already doing very well in the {} dimension; your definitive " + "strength lies in {}, where you're already well-prepared.",
    assessment_results_generate_report_parameters_perspective_low_explanation:
        'We estimate the most initial effort in the {} dimension; we should ' + 'especially focus on {} and improve your capabilities.',
    assessment_results_generate_report_parameters_perspective_second_low_explanation:
        'In addition to that, in the {} dimension, {} ' + "should receive some attention as well; so let's get started!",
    assessment_results_generate_report_parameters_workstream_high_explanation:
        "You're already excelling in the {} dimension. Keep up the great work!",
    assessment_results_generate_report_parameters_workstream_low_explanation:
        'Put emphasis on improving the {} dimension to ' + "ensure you're well-prepared for a steady and successful cloud migration.",
    assessment_results_generate_report_parameters_workstream_second_low_explanation:
        'Furthermore, be deliberate in boosting ' + 'your capabilities in the {} dimension.',
    assessment_results_generate_report_parameters_response_type_rating: 'Rating',
    assessment_results_generate_report_parameters_response_type_number: 'Number',
    assessment_results_generate_report_parameters_response_type_yes_no: 'Yes/No',
    assessment_results_generate_report_parameters_response_type_text: 'Text',
    assessment_results_generate_report_parameters_response_type_date_value: 'Date',
    assessment_results_generate_report_parameters_response_type_single_selection: 'Single selection',
    assessment_results_generate_report_parameters_response_type_multi_selection: 'Multi selection',
    assessment_results_generate_report_parameters_score: 'Score',
    assessment_results_generate_report_parameters_scores: 'Scores',
    assessment_results_generate_report_low_ams_score:
        'Based on your fit score, AMS may still be an interesting option for you. ' +
        'Please contact your AWS Account Team to explore this option further.',
    assessment_results_generate_report_medium_ams_score:
        'Based on your fit score, AMS looks to be a good option for operating your cloud environment. ' +
        'Please contact your AWS Account Team to explore this option further.',
    assessment_results_generate_report_high_ams_score:
        'Based on your fit score, AMS looks like a preferred option to help you manage your AWS environment. ' +
        'Please contact your AWS Account Team to explore this option further.',
    assessment_results_generate_report_facilitated_by: 'Facilitated By',

    // Report customization page
    report_customization_loading_header_message: 'Loading your customizations',
    report_customization_failed_to_load_header_message: 'Failed to retrieve existing data - you can still set your customizations',
    report_customization_failed_to_save_header_message:
        "We've detected potential malicious content in that file, please try uploading one from a trusted source.",
    report_customization_help_panel_header: 'Report Customizations',
    report_customization_help_panel_content:
        "Reports generated by you and your organization will use this data. If you don't set these," + ' AWS defaults will be used.',
    report_customization_reload: 'Reload',
    report_customization_save: 'Save',
    failed_to_save_header_message: 'Error encountered while saving data.',
    report_customization_page_header: 'Customizations',
    report_customization_organization_name_field: 'Organization Name',
    report_customization_organization_name_placeholder: 'ACME Corp',
    report_customization_logo_field: 'Logo',
    report_customization_existing_logo_text: 'You have already uploaded a logo',
    report_customization_upload: 'Choose file',
    report_customization_upload_constraint_text: 'Accepted file types: .jpg, .png. Dimensions should be about 400x250. Maximum file size: ',
    report_customization_upload_error_text_too_big: 'File exceeds maximum limit. Maximum file size: ',
    report_customization_upload_error_text_wrong_type: 'File must be .jpg or .png',
    report_customization_kb: 'KB',

    // Prescribed actions customization
    prescribed_actions_customization_prescribed_actions: 'Prescribed Actions',
    prescribed_actions_customization_choose_assessment_type: 'Choose an assessment type',
    prescribed_actions_customization_add_custom_action: 'Add custom action',
    prescribed_actions_customization_merge_in_default_actions: 'Merge in default actions',
    prescribed_actions_customization_confirm_delete_customized_prescribed_action: 'Delete this prescribed action permanently?',
    prescribed_actions_customization_updating_customized_prescribed_actions: 'Updating customized prescribed actions',
    prescribed_actions_customization_customized_prescribed_actions_saved: 'Customized prescribed actions saved',
    prescribed_actions_customization_customized: 'Customized',
    prescribed_actions_customization_error_unable_to_load_any_templates: 'Unable to load any assessment templates.',
    prescribed_actions_customization_error_loading_templates_metadata: 'Error loading assessment templates metadata.',
    prescribed_actions_customization_error_loading_template_prescribed_actions: 'Error loading prescribed actions from assessment template.',
    prescribed_actions_customization_error_loading_customized_prescribed_actions: 'Error loading customized prescribed actions.',
    prescribed_actions_customization_error_updating_customized_prescribed_actions: 'Error updating customized prescribed actions.',

    // Org overview page
    org_overview_overview: 'Overview',
    org_overview_assessments: 'Assessments',
    org_overview_page_size_preference_title: 'Assessments per page',
    org_overview_filter_placeholder: 'Find assessment',
    org_overview_no_assessments_created: 'No assessments created',
    org_overview_no_assessments_created_subtitle: 'Assessments you create will be shown here',
    org_overview_no_assessments_found: 'No assessments found',
    org_overview_try_different_search: 'Try a different search',
    org_overview_help_panel_header: 'Organization Overview',
    org_overview_help_panel_content: 'This page displays a summary of all assessments your organization has completed.',
    org_overview_loading_overview: 'Loading overview',
    org_overview_no_overview_found: 'No overview found',
    org_overview_error_loading_overview: 'Failed to retrieve your overview',

    // Assessment workshop filters
    assessment_workshop_filters_answered: 'Answered',
    assessment_workshop_filters_header: 'Workshop filters',
    assessment_workshop_filters_live_event: 'Live event',
    assessment_workshop_filters_polling_results_description: 'Display the following types:',
    assessment_workshop_filters_polling_results_header: 'Polling results',
    assessment_workshop_filters_pre_event: 'Pre-event',
    assessment_workshop_filters_questions_description: 'Display the following types:',
    assessment_workshop_filters_questions_header: 'Questions',
    assessment_workshop_filters_unanswered: 'Unanswered',

    assessment_snapshot_add_snapshot: 'Add snapshot',
    assessment_snapshot_compare_snapshots: 'Compare snapshots',
    assessment_snapshot_create_snapshot: 'Create snapshot',
    assessment_snapshot_create_snapshot_error: 'Error creating snapshot',
    assessment_snapshot_create_snapshot_error_same_name: 'Snapshot with same name exists already',
    assessment_snapshot_create_snapshot_success: 'Snapshot created successfully',
    assessment_snapshot_manage_snapshot: 'Manage snapshots',
    assessment_snapshot_snapshots: 'Snapshots',
    assessment_snapshot_view_snapshot: 'View snapshot',

    // Feedback
    feedback_dropdown_submit_issue: 'Submit Issue',
    feedback_dropdown_feature_request: 'Feature Request',
    feedback_loading_assessment_types: 'Loading assessment types...',
    feedback_dialog_title_placeholder: 'Enter title here',
    feedback_dialog_bug_issue_type_placeholder: 'Select an issue type',
    feedback_dialog_feature_request_type_placeholder: 'Select a request type',
    feedback_dialog_feature_request_category_placeholder: 'Select a request category',
    feedback_dialog_assessment_type_placeholder: 'Select an assessment type',
    feedback_dialog_assessment_description_placeholder: 'Enter assessent description here',
    feedback_dialog_request_description_placeholder: 'Enter description here',
    feedback_dialog_expected_behavior_placeholder: 'Enter expected behavior here',
    feedback_dialog_further_context_placeholder: 'Enter further optional context here',
    feedback_submit_request: 'Submitting request...',
    feedback_submit_bug_success: 'New issue submitted successfully',
    feedback_submit_bug_fail: 'New issue submission failed ',
    feedback_submit_bug_dialog_submit_button: 'Submit issue',
    feedback_submit_bug_dialog_header: 'Submit a bug',
    feedback_submit_bug_dialog_title: 'Title',
    feedback_submit_bug_dialog_issue_type: 'Issue type',
    feedback_submit_bug_dialog_description_of_issue: 'Description',
    feedback_submit_bug_dialog_expected_behavior: 'Expected behavior',
    feedback_submit_bug_dialog_assessment_type: 'Assessment type',
    feedback_submit_bug_dialog_assessment_name: 'Assessment description (optional)',
    feedback_submit_bug_dialog_troubleshooting_label: 'Troubleshooting guide',
    feedback_submit_bug_dialog_troubleshooting_hint: 'See troubleshooting guide for common issues before submitting a bug. ',
    feedback_submit_feature_request_success: 'New feature request submitted successfully',
    feedback_submit_feature_request_fail: 'New feature request submission failed',
    feedback_submit_feature_request_dialog_header: 'Request a feature',
    feedback_submit_feature_request_dialog_title: 'Title',
    feedback_submit_feature_request_dialog_type_of_request: 'Type of request',
    feedback_submit_feature_request_dialog_assessment_type: 'Assessment type',
    feedback_submit_feature_request_dialog_assessment_name: 'Assessment description (optional)',
    feedback_submit_feature_request_dialog_category: 'Category',
    feedback_submit_feature_request_dialog_request_description: 'As a user I want to',
    feedback_submit_feature_request_dialog_request_outcome: 'So that I can',
    feedback_submit_feature_request_dialog_request_more_details: 'Further context (optional)',
    feedback_submit_feature_request_dialog_submit_button: 'Submit request',
    feedback_submit_feature_request_category_newfeature: 'New feature',
    feedback_submit_feature_request_category_newfeature_description: 'Changes in functionality of tool/platform',
    feedback_submit_feature_request_category_content: 'Content',
    feedback_submit_feature_request_category_content_description: 'Changes to the content not requiring a functional modification',
    feedback_submit_feature_request_category_access: 'Access',
    feedback_submit_feature_request_category_access_description: 'Requests for dashboard or assessment access',
    feedback_submit_feature_request_category_assistance: 'Assistance',
    feedback_submit_feature_request_category_assistance_description: 'Help using existing tool / assessment',
    feedback_submit_feature_request_category_onboarding: 'Onboarding',
    feedback_submit_feature_request_category_onboarding_description: 'Onboard a new assessment type',
    feedback_submit_feature_request_category_feedback: 'Feedback',
    feedback_submit_feature_request_category_feedback_description: 'Provide feedback, improvements and accolades',

    // Manage templates
    manage_templates_requester_guide_link:
        'https://w.amazon.com/bin/view/AWS/Teams/Proserve/Migration/AWSAssessmentTool/A2TRequesterGuide/#HStandaloneAssessmentsOnboardingProcess',

    manage_templates_template_template: 'Template',
    manage_templates_template_templates: 'Templates',
    manage_templates_template_type: 'Type',
    manage_templates_template_name: 'Name',
    manage_templates_template_version: 'Version',
    manage_templates_template_status: 'Status',
    manage_templates_template_created_at: 'Created at',
    manage_templates_template_statuses_primary: 'Primary template',
    manage_templates_template_statuses_primary_popover_content: 'Any newly created assessments will use the primary template.',
    manage_templates_template_statuses_published: 'Published',
    manage_templates_template_statuses_published_for_version: 'Published for ', // version should be appended
    manage_templates_template_statuses_published_for_version_popover_content:
        'This template is used by existing assessments. New assessments will not use it.',
    manage_templates_template_statuses_latest_draft_for_version: 'Latest draft for ', // version should be appended
    manage_templates_template_statuses_draft: 'Draft',

    manage_templates_template_question_types_text: 'Text',
    manage_templates_template_question_types_number: 'Number',
    manage_templates_template_question_types_yes_no: 'Yes/no',
    manage_templates_template_question_types_rating: 'Ratings',
    manage_templates_template_question_types_single_selection: 'Single selection',
    manage_templates_template_question_types_multi_selection: 'Multi selection',
    manage_templates_template_question_types_date: 'Date',

    manage_templates_table_page_title: 'Manage templates',
    manage_templates_table_preferences: 'Preferences',
    manage_templates_table_templates_per_page: 'Templates per page',
    manage_templates_table_loading_templates: 'Loading templates...',
    manage_templates_table_no_match: 'No templates match search',
    manage_templates_table_empty: 'No templates found',
    manage_templates_table_error_loading_templates: 'Error loading templates',
    manage_templates_table_filter_status: 'Filter status',
    manage_templates_table_all_templates: 'All',
    manage_templates_table_published_and_latest_drafts: 'Published and latest drafts',
    manage_templates_table_help_panel_header: 'Manage Templates',
    manage_templates_table_help_panel_content:
        'For now, you can use this page to enter the "Create template" wizard. There, you can upload an Excel version of your assessment and preview ' +
        'what it will look like in the tool. Once you are satisfied, you can upload it to us and create an onboarding ticket in SIM.',
    manage_templates_table_help_panel_link_text: 'To learn how to create your Excel, check the A2T requester guide.',

    manage_templates_actions_find_template: 'Find template',
    manage_templates_actions_create_template: 'Create template',
    manage_templates_actions_finalize_template: 'Finalize',
    manage_templates_actions_create_draft: 'Create draft',
    manage_templates_actions_publish_for_version: 'Publish for version',
    manage_templates_actions_set_primary_template: 'Set primary template',
    manage_templates_actions_errors_error_creating_draft: 'Error creating draft',
    manage_templates_actions_errors_error_publishing_template: 'Error publishing template',
    manage_templates_actions_errors_no_template_selected: 'No template selected',
    manage_templates_actions_errors_template_already_published: 'Template already published',
    manage_templates_actions_errors_already_primary_template: 'Already primary template',
    manage_templates_actions_errors_cannot_create_draft_from_draft: 'Cannot create draft from another draft',

    manage_templates_publish_template_confirmation_header: 'Confirm publication',
    manage_templates_publish_template_confirmation_body:
        'Publication has immediate effect. <ul>' +
        '<li>Selecting <b>"Publish for version"</b> will make your draft the main template for a specific version. This affects only existing assessments.</li>' +
        '<li>Selecting <b>"Set primary template"</b> will make your draft the main template for the whole assessment type. This new template will be used by all assessments created after publication.</li>' +
        '<li>If you are overriding a primary template, then either option will make the draft become the new primary template.</li>' +
        '</ul>Are you sure you want to publish this template?',

    manage_templates_create_assessment_type_label: 'Create assessment type',
    manage_templates_create_assessment_type_description:
        "Specify the acronym and language of your assessment type. You can add more languages later. <b>Once you're ready to publish, share your template with the A2T product team and file a feature request ticket for review.</b>",
    manage_templates_create_assessment_type_acronym_field: 'Assessment type acronym',
    manage_templates_create_assessment_type_invalid_acronym: 'Invalid acronym',
    manage_templates_create_assessment_type_available: 'Available',
    manage_templates_create_assessment_type_not_available: 'Not available',
    manage_templates_create_assessment_type_initial_locale_field: 'Initial template language',

    manage_templates_wizard_upload_template_upload_excel_template: 'Upload Excel template',
    manage_templates_wizard_upload_template_dropzone_text: 'Drop file here',
    manage_templates_wizard_upload_template_upload_button_text: 'Upload template',
    manage_templates_wizard_upload_template_upload_info_text1: 'For a successful import, your questions should follow the ',
    manage_templates_wizard_upload_template_upload_info_linked_text: 'A2T Excel template',
    manage_templates_wizard_upload_template_upload_info_text2: '.',
    manage_templates_wizard_upload_template_failed_to_parse_template: 'Failed to parse Excel template',
    manage_templates_wizard_upload_template_template_successfully_uploaded: 'Your template has been imported successfully.',
    manage_templates_wizard_upload_template_no_template_uploaded: 'No template uploaded. Upload a template before proceeding.',
    manage_templates_wizard_upload_template_help_panel_header: 'Upload template',
    manage_templates_wizard_upload_template_help_panel_content:
        'Upload your A2T Excel template here. Later, you will be shown a preview of your assessment type. Make changes to your Excel template, re-upload it, and check the preview again. ' +
        'When you are satisfied, you will be able to submit your assessment type for review using SIM.',
    manage_templates_wizard_enter_details_title: 'Enter details',
    manage_templates_wizard_enter_details_description: 'You can change these later',
    manage_templates_wizard_enter_details_details: 'Details',
    manage_templates_wizard_enter_details_template_name: 'Template name',
    manage_templates_wizard_enter_details_template_name_placeholder: 'Migration Readiness Assessment',
    manage_templates_wizard_enter_details_template_name_constraint_text: '50 characters max',
    manage_templates_wizard_enter_details_template_name_error_text: 'Name is too long',
    manage_templates_wizard_enter_details_template_description: 'Template description',
    manage_templates_wizard_enter_details_template_description_placeholder: 'Migration Readiness Assessment (MRA) is an AWS process...',
    manage_templates_wizard_enter_details_template_description_constraint_text: '200 characters max',
    manage_templates_wizard_enter_details_template_description_error_text: 'Description is too long',
    manage_templates_wizard_enter_details_template_acronym: 'Template acronym',
    manage_templates_wizard_enter_details_template_acronym_placeholder: 'MRA2',
    manage_templates_wizard_enter_details_template_acronym_constraint_text: 'Acronym must be unique and shorter than 10 characters',
    manage_templates_wizard_enter_details_template_acronym_text_too_long: 'Acronym is too long',
    manage_templates_wizard_enter_details_template_acronym_text_not_alphanumeric: 'Acronym must be alphanumeric',
    manage_templates_wizard_enter_details_template_cannot_be_empty: 'Field cannot be empty',
    manage_templates_wizard_enter_details_template_must_fill_out_all_required_fields: 'Ensure all required fields are filled out before proceeding.',
    manage_templates_wizard_finalize_help_panel_header: 'Finalize template',
    manage_templates_wizard_finalize_help_panel_content:
        "Change any last details about your template. Once you're satisfied, you can the template and receive a personalized onboarding link. " +
        'Using this link, create an onboarding ticket to contact the A2T team about next steps.',
    manage_templates_wizard_finalize_no_template_imported:
        'No template found. Go back to the Create Template page (under Manage Templates) and upload your template.',
    manage_templates_wizard_finalize_failed_to_upload: 'Failed to upload your template. Contact service administrators if this error persists.',
    manage_templates_wizard_finalize_successful_upload_text: 'Congratulations! We have received your template. ',
    manage_templates_wizard_finalize_successful_upload_linked_text: 'Proceed with the onboarding by making a ticket with this SIM template.',
    manage_templates_wizard_finalize_copy_template: 'Copy template',
    manage_templates_wizard_finalize_copy_template_success: 'Template copied',
    manage_templates_wizard_finalize_copy_template_fail: 'Failed to copy template',

    manage_templates_edit_error_loading_template: 'Error loading template',
    manage_templates_edit_toggle: 'Toggle edit',
    manage_templates_edit_template: 'Edit template',
    manage_templates_edit_metadata: 'Edit template metadata',
    manage_templates_edit_add_locale: 'Add language',
    manage_templates_edit_select_locale_placeholder: 'Select language to enable',
    manage_templates_edit_request_language: "If the language you're looking for isn't here, please file a feature request to the A2T team.",
    manage_templates_edit_modify_structure_button_label: 'Modify structure',
    manage_templates_edit_modify_structure_sections_heading: 'Sections',
    manage_templates_edit_modify_structure_add_section: 'Add section',
    manage_templates_edit_modify_structure_add_section_modal_content:
        'After you create a section, you can add new categories to it. Are you sure you want to add a new section?',
    manage_templates_edit_modify_structure_delete_section: 'Delete section',
    manage_templates_edit_modify_structure_delete_section_warning: 'Are you sure you want to delete this section? <b>This cannot be undone.</b>',
    manage_templates_edit_modify_structure_categories_heading: 'Categories',
    manage_templates_edit_modify_structure_add_category: 'Add category',
    manage_templates_edit_modify_structure_add_category_modal_content:
        'After you create a category, you can add new questions to it. Are you sure you want to add a new category?',
    manage_templates_edit_modify_structure_delete_category: 'Delete category',
    manage_templates_edit_modify_structure_delete_category_warning: 'Are you sure you want to delete this category? <b>This cannot be undone.</b>',
    manage_templates_edit_modify_structure_questions_heading: 'Questions',
    manage_templates_edit_modify_structure_change_question_type: 'Change question type',
    manage_templates_edit_modify_structure_add_question: 'Add question',
    manage_templates_edit_modify_structure_delete_question: 'Delete question',
    manage_templates_edit_modify_structure_delete_question_warning: 'Are you sure you want to delete this question? <b>This cannot be undone.</b>',
    manage_templates_edit_modify_structure_button_disabled_reasons_no_section: 'No section found',
    manage_templates_edit_modify_structure_button_disabled_reasons_delete_categories_first: 'To delete a section, delete all its categories first',
    manage_templates_edit_modify_structure_button_disabled_reasons_cannot_delete_last_section: 'Cannot delete last section',
    manage_templates_edit_modify_structure_button_disabled_reasons_no_category: 'No category found',
    manage_templates_edit_modify_structure_button_disabled_reasons_delete_questions_first: 'To delete a category, delete all its questions first',
    manage_templates_edit_modify_structure_button_disabled_reasons_no_question: 'No question found',
    manage_templates_edit_modify_structure_button_disabled_reasons_navigate_to_last_question:
        'To add a question, navigate to the last question in the category',

    manage_templates_editable_fields_supports_html: 'This field supports HTML',
    manage_templates_editable_fields_assessment_help_panel_category_context: 'Category context',
    manage_templates_editable_fields_assessment_help_panel_prompt_context: 'Prompt context',

    manage_templates_update_template_info_link_text: 'Update template info',
    manage_templates_update_template_info_template_name: 'Template name',
    manage_templates_update_template_info_delivery_guidance_link: 'Delivery guidance link',
    manage_templates_update_template_info_delivery_guidance_help_context: 'Delivery guidance help context',
    manage_templates_update_template_info_help_panel_header: 'Update template info',
    manage_templates_update_template_info_help_panel_content:
        "<h3>Template name</h3>The name of the assessment type. This is shown to the user when they're choosing the type of assessment to create.<br/>" +
        '<h3>Delivery guidance link</h3>Linked text that is shown with your assessment type. Example: ' +
        "<code>&lt;a href='https://more-info-link' target='_blank' rel='noopener noreferrer'&gt;Sample Assessment delivery guide&lt;/a&gt;</code>" +
        '<h3>Delivery guidance help context</h3>This is shown in the help panel, once the user selects your assessment type card.',

    manage_templates_configure_template_link_text: 'Configure template',
    manage_templates_configure_template_error_updating_configuration: 'Error updating configuration',
    manage_templates_configure_template_find_setting: 'Find setting',
    manage_templates_configure_template_name: 'Name',
    manage_templates_configure_template_description: 'Description',
    manage_templates_configure_template_value: 'Value',
    manage_templates_configure_template_help_panel_header: 'Configure template',
    manage_templates_configure_template_help_panel_content:
        'Use this page to configure your template. Changes will take effect after a draft is published.',

    manage_templates_configure_template_editable_defaults_enable_pre_event_name: 'Enable pre-event questionnaire',
    manage_templates_configure_template_editable_defaults_enable_pre_event_description: 'Allows sending questions to customers before the workshop',
    manage_templates_configure_template_editable_defaults_enable_live_polling_name: 'Enable live polling',
    manage_templates_configure_template_editable_defaults_enable_live_polling_description: 'Collect votes from customers during the workshop',
    manage_templates_configure_template_editable_defaults_rating_na_enabled_name: 'Enable NA rating',
    manage_templates_configure_template_editable_defaults_rating_na_enabled_description: 'Enables an NA option for rating questions',
    manage_templates_configure_template_editable_defaults_snapshots_disabled_name: 'Disable snapshots',
    manage_templates_configure_template_editable_defaults_snapshots_disabled_description: 'Disables the snapshot feature',
    manage_templates_configure_template_editable_defaults_radar_disabled_name: 'Disable radar charts',
    manage_templates_configure_template_editable_defaults_radar_disabled_description: 'Removes radar charts from the Review all page and reports ',
    manage_templates_configure_template_editable_defaults_heatmap_disabled_name: 'Disable heatmap',
    manage_templates_configure_template_editable_defaults_heatmap_disabled_description:
        'Removes the heatmap graph from the Review all page and reports',
    manage_templates_configure_template_editable_defaults_score_bar_chart_disabled_name: 'Disable bar charts',
    manage_templates_configure_template_editable_defaults_score_bar_chart_disabled_description:
        'Removes bar charts from the Review all page and reports',
    manage_templates_configure_template_editable_defaults_ppt_disabled_name: 'Disable PowerPoint report',
    manage_templates_configure_template_editable_defaults_ppt_disabled_description: 'Remove PowerPoint option from the Generate Report page',
    manage_templates_configure_template_editable_defaults_word_disabled_name: 'Disable Word report',
    manage_templates_configure_template_editable_defaults_word_disabled_description: 'Remove Word option from the Generate Report page',
    manage_templates_configure_template_editable_defaults_excel_disabled_name: 'Disable Excel report',
    manage_templates_configure_template_editable_defaults_excel_disabled_description: 'Remove Excel option from the Generate Report page',

    manage_templates_share_share_template: 'Share template',
    manage_templates_share_error_sharing_template: 'Error sharing template',
    manage_templates_share_owner: 'Owner',
    manage_templates_share_coowners: 'Co-owners',
    manage_templates_share_add_coowner: 'Add co-owner',
    manage_templates_share_a2t_product_team: 'A2T product team',
    manage_templates_share_locale_managers: 'Locale managers',
    manage_templates_share_add_locale_manager: 'Add locale manager',
    manage_templates_share_assessment_creators: 'Assessment creators',
    manage_templates_share_add_assessment_creator: 'Add assessment creator',
    manage_templates_share_all_partners: 'All partners',
    manage_templates_share_pending_save: 'Pending save',
    manage_templates_share_share_template_help_panel_header: 'Share template',
    manage_templates_share_share_template_help_panel_content:
        '<h3>Owner</h3>The owner of the template. If you need this changed, contact the A2T team.<br/>' +
        '<h3>Co-owners</h3>Template managers that have full permissions for the template. ' +
        "If you're publishing a new assessment type, share the template with the A2T product team and file a ticket to request a review.<br/>" +
        '<h3>Locale managers</h3>Locale managers can localize text for the designated locale.<br/>' +
        '<h3>Assessment creators</h3>Everyone who can create assessments from your assessment type.',
    manage_templates_template_publication_info_modal_header: 'Template publication',
    manage_templates_template_publication_info_modal_content:
        'To publish your assessment type for the first time, you must get it reviewed by the A2T product team. To do so:<br/>' +
        '<ol><li>Share the template with the A2T product team by clicking the checkbox and click "Save"</li>' +
        '<li>File a ticket (Feedback - Feature Request in the top menu)</li>' +
        '<li>Address any concerns raised by the product owner during review</li>' +
        '<li>The product owner will publish your assessment type</li></ol>' +
        'Future edits do not need to be reviewed by the A2T product team.',
};
