import { AssessmentFeature } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { ListAccountsCommand, ListAccountsCommandOutput } from '@amzn/customer-account-service-typescript-client';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { CustomerViewModel } from './CustomerViewModel';
import { getCustomerAccountServiceLambdaClient } from '../../api/cas/CustomerAccountServiceClient';
import UsernameOrId from '../common/UsernameOrId';
import { useAppSelector } from '../redux/hooks';

export type CustomerDetailsProps = {
    customerAccountId: string;
};

// This component provides a cache map to store customer account returned from CAS such that
// same customer account will only be queried once.
const listAccountResultsPromiseMap = new Map<string, Promise<ListAccountsCommandOutput>>();
export const clearListAccountsCache = () => {
    listAccountResultsPromiseMap.clear();
};

const loadCustomerViewModel = async (customerAccountId: string) => {
    let listAccountResultsPromise = listAccountResultsPromiseMap.get(customerAccountId);
    if (!listAccountResultsPromise) {
        // cache miss. Load from service
        const casClient = await getCustomerAccountServiceLambdaClient();
        listAccountResultsPromise = casClient.send(new ListAccountsCommand({ accountIdList: [customerAccountId] }));
        listAccountResultsPromiseMap.set(customerAccountId, listAccountResultsPromise);
    }
    if (listAccountResultsPromise) {
        const listAccountResults = await listAccountResultsPromise;
        const customerAccount = listAccountResults?.accounts[0];
        if (customerAccount) {
            return {
                id: customerAccount.accountId,
                organizationId: customerAccount.orgId,
                referenceId: customerAccount.externalRefId,
                accountName: customerAccount.accountName,
                ownerId: customerAccount.ownerId || customerAccount.accountOwnerName, // CAS so far does not return ownerId
                createdBy: customerAccount.createdBy,
                updatedBy: customerAccount.updatedBy,
            } as CustomerViewModel;
        }
    }
    return null;
};

export type CustomerNameProps = {
    defaultCustomerName?: string;
} & CustomerDetailsProps;
/**
 * This component queries CAS for customer account. If customer account is available, renders customer
 * account name. Otherwise render defaultCustomerName if provided or empty string otherwise.
 *
 * @param customerAccountId customer account ID
 * @returns customer account name if available, otherwise defaultCustomerName or empty string
 */
export const CustomerName: FunctionComponent<CustomerNameProps> = ({ customerAccountId, defaultCustomerName }): JSX.Element => {
    const authorizedFeatures = useAppSelector((state) => state.assessmentFeaturesState.authorizedFeatures);
    const cas2IsEnabled = authorizedFeatures.includes(AssessmentFeature.ADMIN_CAS2_ENABLED);
    const [customerName, setCustomerName] = useState(null);

    const findCustomerViewModel = useCallback(async () => {
        const customerViewModel = await loadCustomerViewModel(customerAccountId);
        if (customerViewModel) {
            setCustomerName(customerViewModel.accountName);
        } else {
            setCustomerName(defaultCustomerName || '');
        }
    }, [customerAccountId, defaultCustomerName]);

    useEffect(() => {
        if (cas2IsEnabled && customerAccountId) {
            findCustomerViewModel();
        }
    }, [cas2IsEnabled, customerAccountId, findCustomerViewModel]);

    return customerName || defaultCustomerName || '';
};

export type CustomerAccountOwnerProps = {
    defaultCustomerAccountOwner?: string;
} & CustomerDetailsProps;
/**
 * This component queries CAS for customer account. If customer account is available, renders customer
 * account owner. Otherwise render defaultCustomerAccountOwner if provided or empty string otherwise.
 *
 * @param customerAccountId customer account ID
 * @returns customer account owner if available, otherwise defaultCustomerAccountOwner or empty string
 */
export const CustomerAccountOwner: FunctionComponent<CustomerAccountOwnerProps> = ({
    customerAccountId,
    defaultCustomerAccountOwner,
}): JSX.Element => {
    const authorizedFeatures = useAppSelector((state) => state.assessmentFeaturesState.authorizedFeatures);
    const cas2IsEnabled = authorizedFeatures.includes(AssessmentFeature.ADMIN_CAS2_ENABLED);
    const [customerAccountOwner, setCustomerAccountOwner] = useState(null);

    const findCustomerViewModel = useCallback(async () => {
        const customerViewModel = await loadCustomerViewModel(customerAccountId);
        if (customerViewModel) {
            setCustomerAccountOwner(customerViewModel.ownerId);
        } else {
            setCustomerAccountOwner(defaultCustomerAccountOwner || '');
        }
    }, [customerAccountId, defaultCustomerAccountOwner]);

    useEffect(() => {
        if (cas2IsEnabled && customerAccountId) {
            findCustomerViewModel();
        }
    }, [cas2IsEnabled, customerAccountId, findCustomerViewModel]);

    return customerAccountOwner || defaultCustomerAccountOwner || '';
};

export type CustomerExternalReferenceIdProps = {
    defaultCustomerExternalReferenceId?: string;
} & CustomerDetailsProps;
/**
 * This component queries CAS for customer account. If customer account is available, renders customer
 * external reference ID. Otherwise render defaultCustomerExternalReferenceId if provided or empty string otherwise.
 *
 * @param customerAccountId customer account ID
 * @returns customer external reference ID if available, otherwise defaultCustomerExternalReferenceId or empty string
 */
export const CustomerExternalReferenceId: FunctionComponent<CustomerExternalReferenceIdProps> = ({
    customerAccountId,
    defaultCustomerExternalReferenceId,
}): JSX.Element => {
    const authorizedFeatures = useAppSelector((state) => state.assessmentFeaturesState.authorizedFeatures);
    const cas2IsEnabled = authorizedFeatures.includes(AssessmentFeature.ADMIN_CAS2_ENABLED);
    const [customerExternalReferenceId, setCustomerExternalReferenceId] = useState(null);

    const findCustomerViewModel = useCallback(async () => {
        const customerViewModel = await loadCustomerViewModel(customerAccountId);
        if (customerViewModel) {
            setCustomerExternalReferenceId(customerViewModel.referenceId);
        } else {
            setCustomerExternalReferenceId(defaultCustomerExternalReferenceId || '');
        }
    }, [customerAccountId, defaultCustomerExternalReferenceId]);

    useEffect(() => {
        if (cas2IsEnabled && customerAccountId) {
            findCustomerViewModel();
        }
    }, [cas2IsEnabled, customerAccountId, findCustomerViewModel]);

    return customerExternalReferenceId || defaultCustomerExternalReferenceId || '';
};

export type CustomerCreatedByProps = {
    defaultCreatedBy?: string;
} & CustomerDetailsProps;
/**
 * This component queries CAS for customer account. If customer account and createdBy is available, renders customer
 * createdBy email or userId. Otherwise render defaultCreatedBy's email or id or empty string.
 *
 * @param customerAccountId customer account ID
 * @returns customer createdBy's email/userId if available, otherwise defaultCreatedBy's email/userId or empty string.
 */
export const CustomerCreatedBy: FunctionComponent<CustomerCreatedByProps> = ({ customerAccountId, defaultCreatedBy }): JSX.Element => {
    const authorizedFeatures = useAppSelector((state) => state.assessmentFeaturesState.authorizedFeatures);
    const cas2IsEnabled = authorizedFeatures.includes(AssessmentFeature.ADMIN_CAS2_ENABLED);
    const [createdBy, setCreatedBy] = useState(null);

    const findCustomerViewModel = useCallback(async () => {
        const customerViewModel = await loadCustomerViewModel(customerAccountId);
        if (customerViewModel) {
            setCreatedBy(customerViewModel.createdBy);
        } else {
            setCreatedBy(defaultCreatedBy || '');
        }
    }, [customerAccountId, defaultCreatedBy]);

    useEffect(() => {
        if (cas2IsEnabled && customerAccountId) {
            findCustomerViewModel();
        }
    }, [cas2IsEnabled, customerAccountId, findCustomerViewModel]);

    return <UsernameOrId userId={createdBy || defaultCreatedBy} />;
};
