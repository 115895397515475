import { UserInfo } from '@amzn/awscat-portal-authentication-library';
import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';

export type UsernameOrIdProps = {
    userId: string;
} & AuthContextInterface;

const userProfilePromiseMap = new Map<string, Promise<UserInfo[]>>();
export const clearUserProfilesCache = () => {
    userProfilePromiseMap.clear();
};

/**
 * This component queries CAT portal for user profile. If user profile is available, renders
 * {full name + (organization name)} of the user. Otherwise simply renders provider userId.
 *
 * This component provides a cache map to store user's profile returned from CAT portal such that
 * same user will only be queried once.
 *
 * @param userId CAT portal userId
 * @returns user's full name + org if available, otherwise userId
 */
const UsernameOrId: FunctionComponent<UsernameOrIdProps> = ({ userId, auth }): JSX.Element => {
    const [usernameOrId, setUsernameOrId] = useState(null);
    const userProfilePromise = userProfilePromiseMap.get(userId);

    const loadUserProfile = useCallback(async () => {
        let newUserProfilePromise = userProfilePromiseMap.get(userId);
        if (!newUserProfilePromise) {
            // cache miss. Load from service
            newUserProfilePromise = auth.getUserInfoList([userId]);
            if (newUserProfilePromise) {
                userProfilePromiseMap.set(userId, newUserProfilePromise);
            }
        }
        if (newUserProfilePromise) {
            const userProfile = await newUserProfilePromise;
            if (userProfile?.length > 0) {
                const user = userProfile[0];
                const name = user?.name || user?.displayName;
                const orgName = user?.organizationName || user?.organizationId;
                const userName = `${name} (${orgName})`;
                setUsernameOrId(userName || userId);
            } else {
                setUsernameOrId(userId);
            }
        }
    }, [auth, userId]);

    useEffect(() => {
        if (userId && (!usernameOrId || !userProfilePromise)) {
            // load user profile again if we have not set initial user full name or
            // user ID may have changed and there's no record in cache map
            loadUserProfile();
        }
    }, [loadUserProfile, usernameOrId, userId, userProfilePromise]);

    return <div>{usernameOrId || userId || ''}</div>;
};

export default withAuthContext(UsernameOrId);
